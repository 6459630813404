import React, { Component } from "react";
import firebase from "../../firebase";
import {
  Grid,
  Row,
  Col,
  // Table,
  ModalBody,
  Modal,
  ModalHeader,
  Form,
  FormGroup,
  ModalFooter,
} from "react-bootstrap";
// import { Card } from '../../components/Card/Card.jsx';
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
// import { Switch } from '@material-ui/core';
import SwitchToggle from "components/SwitchToogle/SwitchToogle";
import CustomButton from "components/CustomButton/CustomButton.jsx";
import { Button } from "@material-ui/core";
import FormInputs from "components/FormInputs/FormInputs";
import { toast } from "react-toastify";
// import { auth } from 'firebase';
// const createSwitchEditor = (onUpdate, props) => (<SwitchToggle handleToogle={ onUpdate } {...props}/>);

export default class List_Com extends Component {
  constructor(props) {
    super(props);

    this.state = {
      commercants: [],
      users: [],
      value: false,
      isOn: false,
      show: false,
      item: {},
      load: false,
      boutiques: [],
    };
    this.cellFormat = this.cellFormat.bind(this);
  }

  componentDidMount() {
    this.fetchCommercants();
  }

  fetchCommercants = async () => {
    try {
      let db = firebase.firestore();
      let users = [];
      let boutiques = [];
      let docs = await db.collection("users").get();
      docs.forEach((doc) => users.push({ ...doc.data(), id: doc.id }));
      let docs2 = await db.collection("boutiques").get();
      docs2.forEach((doc) => {
        boutiques.push({ ...doc.data(), id: doc.id });
      });

      console.log(boutiques);

      boutiques = boutiques.map((u) => {
        u.user = {};
        for (let b of users) {
          if (b.id === u.id) {
            // console.log(b)
            u.user = b;
            console.log(u.user);
            break;
          }
          // u.boutique=b
        }
        return u;
      });
      console.log(boutiques);
      this.setState({ boutiques });
      let commercants = boutiques.filter(
        (data) => Object.keys(data.user).length !== 0
      );
      console.log(commercants);
      console.log("users==>", users);
      this.setState({ commercants, users });
    } catch (error) {
      console.log(error.message);
    }
  };

  notify = () => {
    toast.success("operation avec success", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
    setTimeout(() => {
      this.setState((prevState) => {
        return { show: !prevState.show };
      });
      this.fetchCommercants();
    }, 3000);
  };

  onUpdate = async (e) => {
    e.preventDefault();
    this.setState({ load: true });
    let item = this.state.item;
    try {
      const db = firebase.firestore();
      await db
        .collection("users")
        .doc(item.id)
        .set({ ...item })
        .then((docref) => {
          console.log("success");
          this.setState({ load: !this.state.load });
          this.notify();
        })
        .catch((er) => {
          console.log(er.message);
          this.setState({ load: !this.state.load });
        });
    } catch (error) {
      console.log(error.message);
      this.setState({ load: !this.state.load });
      toast.error("Erreur lors de l'envoi,Veuillez reessayer!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  cellFormat = (cell, row, enumObject, index) => {
    // console.log(`The row index: ${row.commercant}`);
    return (
      <>
        {row.commercant === false ? <div>Client</div> : <div>Commercant</div>}
      </>
    );
  };

  onSwitch = (row) => {
    this.setState((prevState) => {
      return { show: !prevState.show };
    });
    if (row) {
      console.log("a modifier==>", row);

      if (this.state.show === false) {
        this.setState({ item: {} });
      }
      // let item = {
      //     id: row.id,
      //     commercant: row.commercant,
      //     nom: row.nom,
      //     phone: row.phone,
      //     is_bloq: row.is_bloq,
      // }
      // console.log(item);
      this.setState({ item: row });
    }
  };

  actionFormat = (cell, row, enumObject, index) => {
    // return(<SwitchToggle isOn={isOn} onColor="rgba(39, 201, 17, 0.788)" handleToogle={(e)=>isOn=!isOn} />)
    // return (<Button variant="contained" color="secondary" onClick={(e) => this.onSwitch(row)}>{!row?.is_bloq || row?.is_bloq === undefined ? "Desactiver" : "Activer"}</Button>)
    return (
      <>
        {!row?.is_bloq || row?.is_bloq === undefined ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => this.onSwitch(row)}
          >
            Desactiver
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "rgba(39, 201, 17, 0.788)" }}
            onClick={(e) => this.onSwitch(row)}
          >
            Activer
          </Button>
        )}
      </>
    );
  };

  handleToogle = (e) => {
    e.preventDefault();
    let { item } = this.state;
    console.log("selected", e.target.value);
    console.log("selected name", e.target.name);
    item[e.target.name] = e.target.value;
    this.setState({ item: item }, console.log(this.state.item));
  };

  render() {
    const { users, show, item, load } = this.state;
    const renderModal = (
      <Modal show={show} onHide={this.onSwitch} backdrop={false}>
        <ModalHeader closeButton>
          <h5>Changer Etat Compte</h5>
        </ModalHeader>
        <Form>
          <ModalBody>
            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              properties={[
                {
                  label: "nom",
                  readOnly: true,
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Nom",
                  name: "nom",
                  value: item?.nom || "",
                },
                {
                  label: "phone",
                  readOnly: true,
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "phone",
                  name: "phone",
                  value: item?.phone || "",
                },
              ]}
            />
            <FormGroup>
              <SwitchToggle
                name="commercant"
                isOn={item?.is_bloq}
                onColor="rgba(39, 201, 17, 0.788)"
                handleToogle={(e) => {
                  console.log(e.target.checked);
                  // let { item } = this.state;
                  let checked = e.target.checked;
                  if (checked === false) {
                    item.is_bloq = checked;
                  } else {
                    item.is_bloq = checked;
                  }
                  this.setState({ item: item }, console.log(this.state.item));
                }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <div className="row">
              <Col md={12}>
                {!load ? (
                  <CustomButton onClick={this.onUpdate} fill style={style.btn}>
                    Enregistrer
                  </CustomButton>
                ) : (
                  <CustomButton fill style={style.btn}>
                    Chargement...
                    <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>
                  </CustomButton>
                )}
                <CustomButton
                  fill
                  style={style.btn}
                  onClick={(e) => this.setState({ show: !show })}
                >
                  Annuler
                </CustomButton>
              </Col>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    );

    return (
      <React.Fragment>
        <div className="content">
          {renderModal}
          <Grid fluid>
            <Row>
              <Col md={12}>
                <div className="card-plain">
                  <div className="header">
                    <h4 className="title">Liste des comptes</h4>
                  </div>
                  <div className="content">
                    <BootstrapTable
                      srtiped
                      data={users}
                      pagination
                      keyField={"id"}
                    >
                      <TableHeaderColumn
                        dataField="nom"
                        filter={{ type: "TextFilter", delay: 1000 }}
                        dataSort
                      >
                        Nom
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="phone"
                        filter={{ type: "TextFilter", delay: 1000 }}
                        dataSort
                      >
                        Phone
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="email"
                        filter={{ type: "TextFilter", delay: 1000 }}
                        dataSort
                      >
                        Email
                      </TableHeaderColumn>
                      <TableHeaderColumn dataFormat={this.cellFormat}>
                        Compte
                      </TableHeaderColumn>
                      <TableHeaderColumn dataFormat={this.actionFormat}>
                        Action
                      </TableHeaderColumn>
                      {/* <TableHeaderColumn dataField='phone' dataSort>Telephone</TableHeaderColumn> */}
                    </BootstrapTable>
                  </div>
                </div>
                {/* <Card
                                    title="Liste des Commercants"
                                    ctTableFullWidth
                                    ctTableResponsive
                                    content={
                                        
                                    }                                
                                />  */}
              </Col>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
const style = {
  color: "white",
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    float: "right",
    marginRight: "0.8em",
    borderColor: "#e4393f",
  },
};
