import { combineReducers } from 'redux'
import comandReducer from './commande/comandReducer';
import boutiqueReducer from './boutique/boutiqueReducer'
import productReducer from './products/productReducer';
import notificationReducer from './notifications/notificationReducer';
export default combineReducers({
    commandes: comandReducer,
    boutique: boutiqueReducer,
    products: productReducer,
    notifications: notificationReducer
})
