// // // If you want your app to work offline and load faster, you can change
// // // unregister() to register() below. Note this comes with some pitfalls.
// // // Learn more about service workers: https://bit.ly/CRA-PWA
// // serviceWorker.unregister();

// /*!

// =========================================================
// * Light Bootstrap Dashboard React - v1.3.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// */
// import React from "react";
// import ReactDOM from "react-dom";

// import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "./assets/css/animate.min.css";
// import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
// import "./assets/css/demo.css";
// import "./assets/css/pe-icon-7-stroke.css";

// import AdminLayout from "./layouts/Admin.jsx";
// // import { AuthProvider } from "./Auth";
// // import PrivateRoute from "./PrivateRoute";
// import LoginView from "views/Login/LoginView";

// import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
// import './index.css'
// import SignUpCom from "views/viewsCommercant/Inscription/SignUpCom";
// import { Provider } from 'react-redux'
// import store from "./reduxStore/store";
// import AuthUser from "views/viewsCommercant/Authentification/AuthUser";

// ReactDOM.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <Switch>
//         <Route path="/admin" render={props => <AdminLayout {...props} />} />
//         <Route exact path="/admin/dashboard" render={props => <AdminLayout {...props} />} />
//         {/* <PrivateRoute path="/admin" component={AdminLayout} />       */}
//         <Route path="/login" component={LoginView} />
//         {/* <Route path="/admin/monprofil" component={UserProfile} /> */}
//         <Route path="/signup" component={SignUpCom} />
//         <Route exact path="/" render={() => <Redirect to="/signup" />} />
//         <Route exact path="/signin" render={props => <AuthUser {...props} />} />
//       </Switch>
//     </BrowserRouter>
//   </Provider>
//   , document.getElementById("root")
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";

import AdminLayout from "./layouts/Admin.jsx";
// import { AuthProvider } from "./Auth";
// import PrivateRoute from "./PrivateRoute";
// import LoginView from "views/Login/LoginView";

import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "./index.css";
// import SignUpCom from "views/viewsCommercant/Inscription/SignUpCom";
import { Provider } from "react-redux";
import store from "./reduxStore/store";
import AuthUser from "views/viewsCommercant/Authentification/AuthUser";
// import App from "App";
import firebase from "firebase";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import HomeSpinner from "views/HomeSpinner";

const Index = () => {
  const [userLoaded, setUserLoaded] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [redirect, setRedirect] = React.useState("");

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let uid = user["uid"];
        setUser(user);
        sessionStorage.setItem("id_user", JSON.stringify(user["uid"]));
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .get()
          .then((doc) => {
            if (doc.data().is_admin === true) {
              sessionStorage.setItem("is_admin", JSON.stringify(true));
              setRedirect("/admin/dashboard");
            } else {
              setRedirect("/admin/mydashboard");
            }
            setUserLoaded(true);
          })
          .catch((error) => {
            setUserLoaded(true);
            console.log(error.code);
          });
      } else {
        setUserLoaded(true);
      }
    });
  }, []);

  if (!userLoaded) {
    return <HomeSpinner />;
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route
            exact
            path="/admin/dashboard"
            render={(props) => <AdminLayout {...props} />}
          />
          <Route path="/signin" component={AuthUser} />
          <Route
            path="/"
            render={() => <Redirect to={user ? redirect : "/signin"} />}
          />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "http://9e4efe741f694b5ebc179f7d3bb870ef@159.89.32.58:9000/4",
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<Index />, document.getElementById("root"));
