import React, { Component } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

export default class CardStatsMonth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataMonth: [],
    }
  }

  componentDidMount() {
    const timer = setTimeout(() => {
      this.setState({ dataMonth: this.props.consultationsMonth })
    }, 1000)
    // Cancel the timer while unmounting
    return () => clearTimeout(timer)
  }

  /* componentDidUpdate(prevProps) {
    if (
      this.props.consultationsMonth.length !==
      prevProps.consultationsMonth.length
    ) {
      this.setState({ dataMonth: this.props.consultationsMonth })
    }
    //console.log('props', prevProps, this.props)
  } */

  render() {
    return (
      <div className="card card-stats" style={{backgroundColor: "whitesmoke"}}>
        <div className="content">
          <Row>
            <Col xs={4}>
              <div className="icon-big text-center icon-warning">
                <i className="fas fa-chart-bar" style={{color: "tomato"}}></i>
              </div>
            </Col>
            <Col xs={8}>
              <div className="numbers"  >
                {this.props.consultationsMonth.length}
                <h5>Consultations ce mois ci</h5>
              </div>
            </Col>
          </Row>
          {/*  <div className="footer">
            <hr />
            <div className="stats">footer</div>
          </div> */}
        </div>
      </div>
    )
  }
}
