/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
// import { Tasks } from "components/Tasks/Tasks.jsx";
import firebase from "../firebase.js";
// import { fetchBoutiques } from "firebaseRequest.js";
import moment from "moment";
// import { getMonthByDate, getDayByDate } from "utils.js";
import { Line } from "react-chartjs-2";
// import { Line } from "chartist";
// import { getMonthByDate } from "utils.js";
// import Chart from "views/Chart/Chart";
class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      boutik: [],
      abonnements: [],
      data_abon_0: [],
      data_abon_30: [],
      // boutik_data: [],
      userDay: [],
      jour: [],
      // chartData: {},
    };
  }

  componentDidMount() {
    this.fetchUser();
    this.fetchBoutiques();
    // this.fetchAbonnement();
  }

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  _formule = (data) => {
    const formule = (a) => parseInt(a / 1000 / 60 / 60 / 24 / 7, 10);
    var currentDate = formule(moment().toDate().getTime());
    var day = [];
    // let filter = data.filter((f) => f.created_at);
    // console.log(filter);
    // data=filter;
    // let { jour } = this.state;
    // console.log("jour before=>", jour);
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (formule(element.created_at) <= currentDate) {
        let j = moment(element.created_at).format("DD");
        let index = day.findIndex((f) => f.day === j);
        if (index === -1) {
          let obj = {
            count: 1,
            day: j,
          };
          // console.log("object", obj);
          // jour.push(obj);
          day.push(obj);
          // console.log("day", day);
          // console.log("jour before=>", jour);
          // this.setState({ jour: jour.push(obj) });
        } else {
          day[index].count++;

          // day[index].count++;
          // console.log("jour before=>", jour);
        }
      }
    }
    // this.setState({ jour: jour });
    // this.setState({ jour: jour });
    // console.log("jour after=>", this.state.jour);

    return day;
  };

  fetchUser = async () => {
    try {
      let db = firebase.firestore();
      let data = [];
      let abonnements = [];
      let docs = await db.collection("users").get();
      docs.forEach((doc) => data.push({ ...doc.data(), id: doc.id }));
      console.log("users=>", data);
      let nb_users = data.length;
      this.setState({ users: data, nb_users });
      const formule = (date) => parseInt(date / 1000 / 60 / 60 / 24 / 7, 10);
      var currentDate = formule(moment().toDate().getTime());
      var userDay = [];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        // let test = moment(element.created_at).format("DD/MM/YYYY");
        // let aff_date = moment().weekday();
        // console.log(`week`, aff_date);

        // let currDate = moment();
        // var weekStart = currDate.clone().startOf("week");
        // console.log("object")
        // // let weekEnd = currentDate.clone().endOf('week');
        // var days = [];
        // for (i = 0; i < 7; i++) {
        //   days.push(moment(weekStart).add(i, "days").format("DD/MM/YYYY") + 1);
        // }
        // console.log("daysss==>", days);
        // console.log("results==>", moment("01/19/2016").format("DD/MM/YYYY"));
        // console.log(moment(element?.created_at).format("YYYY/MM/DD"));
        if (formule(element.created_at) <= currentDate) {
          let j = moment(element.created_at).format("DD");
          // console.log(j);
          let index = userDay.findIndex((f) => f.day === j);
          if (index === -1) {
            let obj = {
              count: 1,
              day: j,
            };
            userDay.push(obj);
          } else {
            userDay[index].count++;
          }
        }
      }
      // console.log("userDay=>", userDay);
      userDay = userDay.sort((a, b) => a.day - b.day);
      this.setState({ userDay: userDay });

      let docs2 = await db.collection("abonnements").get();
      docs2.forEach((doc) => abonnements.push({ ...doc.data(), id: doc.id }));
      this.setState({ abonnements: abonnements });

      // const nb_commercant = this.state.users.filter(
      //   (data) => data.commercant === true
      // ).length;

      let abonner_30 = this.state.users.filter(
        (f) => f?.abonnement === this.state.abonnements[0]?.id
      );
      let abonner_0 = this.state.users.filter(
        (f) => f?.abonnement === this.state.abonnements[1]?.id
      );
      let results_ab30 = this._formule(abonner_30);
      let results_ab0 = this._formule(abonner_0);

      this.setState({
        data_abon_0: results_ab0.sort((a, b) => a.day - b.day),
        data_abon_30: results_ab30.sort((a, b) => a.day - b.day),
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  fetchBoutiques = async () => {
    const formule = (date) => parseInt(date / 1000 / 60 / 60 / 24 / 7, 10);
    var currentDate = formule(moment().toDate().getTime());
    try {
      let db = firebase.firestore();
      let data = [];
      let docs = await db.collection("boutiques").get();
      docs.forEach((doc) => data.push({ ...doc.data(), id: doc.id }));
      // console.log("boutique data=>", data);
      let nb_boutik = data.length;
      // console.log(`data boutik here=>`, data);
      this.setState({ boutik: data, nb_boutik });
      var jour = [];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        // console.log(moment(element?.created_at).format("YYYY/MM/DD"));
        if (formule(element.created_at) <= currentDate) {
          // console.log("current Date", formule(moment().toDate().getTime()));
          let j = moment(element.created_at).format("DD");
          // console.log("create date", formule(element.created_at));
          // console.log("daily=>", j);
          let index = jour.findIndex((f) => f.day === j);
          if (index === -1) {
            let obj = {
              count: 1,
              day: j,
            };
            jour.push(obj);
          } else {
            jour[index].count++;
          }
        } else {
          // console.log("i'm here");
          // let obj = {
          //   count: 0,
          //   day: moment().format("DD"),
          // };
          // jour.push(obj);
        }
      }
      // console.log("jour=>", jour);
      jour = jour.sort((a, b) => a.day - b.day);
      this.setState({ jour: jour });
    } catch (error) {
      console.log(error.message);
    }
  };

  fetchAbonnement = async () => {
    try {
      let db = firebase.firestore();
      let abonnements = [];
      let docs = await db.collection("abonnements").get();
      docs.forEach((doc) => abonnements.push({ ...doc.data(), id: doc.id }));
      // console.log("abonnements =>", abonnements);
      this.setState({ abonnements: abonnements });
    } catch (error) {
      console.log(error.message);
    }
  };

  render() {
    // const nb_commercant = this.state.users.filter(
    //   (data) => data.commercant === true
    // ).length;
    let { users, boutik } = this.state;
    boutik.forEach((b) => {
      b.user = {};
      users.forEach((u) => {
        if (u.id === b.id && u.abonnement !== undefined) {
          b.user = u;
        }
      });
    });
    boutik = boutik.filter((b) => Object.keys(b.user).length !== 0);
    console.log("boutik_test==>", boutik);

    let abonner_30 = boutik.filter(
      (f) => f?.user?.abonnement === this.state.abonnements[0]?.id
    )?.length;
    // console.log("abonnement=>", this.state.abonnements);
    let abonner_0 = boutik.filter(
      (f) => f?.user?.abonnement === this.state.abonnements[1]?.id
    )?.length;

    const comptCom = boutik.filter((b) => b?.user?.commercant !== undefined);
    console.log("compte commercaant=>", comptCom);

    let testTab = this.state.jour.map((f) => f.day);
    // console.log("testTab", testTab);
    let tab0 = this.state.data_abon_0.map((f) => f.day);
    let tab30 = this.state.data_abon_30.map((f) => f.day);
    let array3 = testTab.concat(tab0);
    array3 = array3.filter((item, index) => {
      return array3.indexOf(item) === index;
    });
    // console.log("array 3", array3);
    let tabFinal = array3.concat(tab30);
    tabFinal = tabFinal.filter((item, index) => {
      return tabFinal.indexOf(item) === index;
    });
    // console.log(
    //   "final tab",
    //   tabFinal.sort((a, b) => a - b).map((f) => f)
    // );

    let data = {
      labels: this.state?.jour.sort((a, b) => a.day - b.day).map((f) => f.day),
      // labels: tabFinal.sort((a, b) => a - b).map((f) => f),
      datasets: [
        {
          type: "line",
          fill: false,
          label: "Nombre de boutique par jour",
          data: this.state?.jour.map((d) => parseInt(d.count)),
          backgroundColor: "#e2292ee8",
          hoverBackgroundColor: "#e2292ee8",
          hoverBorderColor: "#e2292ee8",
          borderColor: "#e2292ee8",
          pointBorderColor: "#e2292ee8",
          pointBackgroundColor: "#e2292ee8",
          // pointRadius: 1,
          // pointBorderWidth: 3,
          // pointHoverRadius: 5,
          yAxisID: "y-axis-1",
          // borderWidth: 0,
        },
        {
          type: "line",
          fill: false,
          label: "Nombre d'utilisateur par jour",
          data: this.state?.userDay.map((d) => parseInt(d.count)),
          backgroundColor: "rgba(7, 168, 222)",
          hoverBackgroundColor: "rgba(7, 168, 222)",
          hoverBorderColor: "rgba(7, 168, 222)",
          borderColor: "rgba(7, 168, 222)",
          pointBorderColor: "rgba(7, 168, 222)",
          pointBackgroundColor: "rgba(7, 168, 222)",
          yAxisID: "y-axis-1",
          // borderWidth: 0,
        },
        {
          type: "line",
          fill: false,
          label: "Nombre d'abonnement 0€",
          data: this.state?.data_abon_0.map((d) => parseInt(d.count)),
          backgroundColor: "#80391e",
          hoverBackgroundColor: "#80391e",
          hoverBorderColor: "#80391e",
          borderColor: "#80391e",
          pointBorderColor: "#80391e",
          pointBackgroundColor: "#80391e",
          yAxisID: "y-axis-1",
          // borderWidth: 0,
        },
        {
          type: "line",
          fill: false,
          label: "Nombre d'abonnement 30€",
          data: this.state?.data_abon_30.map((d) => parseInt(d.count)),
          backgroundColor: "rgb(108, 186, 130)",
          hoverBackgroundColor: "rgb(108, 186, 130)",
          hoverBorderColor: "rgb(108, 186, 130)",
          borderColor: "rgb(108, 186, 130)",
          pointBorderColor: "rgb(108, 186, 130)",
          pointBackgroundColor: "rgb(108, 186, 130)",
          yAxisID: "y-axis-1",
          // borderWidth: 0,
        },
      ],
    };

    const legend = {
      display: true,
      position: "bottom",
      labels: {
        boxWidth: 50,
        fontSize: 14,
        fontColor: "rgb(24, 33, 36)",
      },
    };

    let options = {
      responsive: true,
      labels: this.state?.jour.map((f) => f.day),
      tooltips: {
        mode: "label",
      },
      elements: {
        line: {
          fill: false,
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false,
            },
            labels: this.state?.jour.map((f) => f.day),
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            gridLines: {
              display: false,
            },
            labels: {
              show: true,
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              beginAtZero: true,
            },
          },
          {
            type: "linear",
            display: false,
            position: "right",
            id: "y-axis-2",
            gridLines: {
              display: false,
            },
            labels: {
              show: true,
            },
          },
        ],
      },
    };
    let plugins = [
      {
        afterDraw: (chartInstance, easing) => {},
      },
    ];
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-user" />}
                statsText={"Utilisateur"}
                statsValue={this.state?.nb_users}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Updated now"
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-users" />}
                statsText={"Compte commercant"}
                statsValue={comptCom?.length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Last day"
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-store fa-1x" />}
                statsText="Boutique"
                statsValue={boutik?.length}
                statsIcon={<i className="fa fa-clock-o" />}
                statsIconText="In the last hour"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} md={6}>
              <StatsCard
                bigIcon={<i className="fa fa-credit-card" aria-hidden="true" />}
                statsText={"Abonnement 0€"}
                statsValue={abonner_0}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Updated now"
              />
            </Col>
            <Col lg={6} sm={6} md={6}>
              <StatsCard
                bigIcon={<i className="fa fa-credit-card" aria-hidden="true" />}
                statsText={"Abonnement 30€"}
                statsValue={abonner_30}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Updated now"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12} xs={12} lg={12}>
              <Card
                // ctTableFullWidth
                // ctTableResponsive
                statsIcon="fa fa-history"
                title="OBOUDUCLIC"
                category="Statistique"
                content={
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <Line
                        data={data}
                        options={options}
                        plugins={plugins}
                        legend={legend}
                      ></Line>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
