import React, { Component } from "react";
import "./products.css";
import firebase from "../../firebase.js";
import { Grid, Row, Col } from "react-bootstrap";
// import { Card } from "../../components/Card/Card.jsx";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";

export default class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      boutiques: [],
    };
  }

  componentDidMount() {
    this.fetchProducts();
  }

  fetchProducts = async () => {
    try {
      let db = firebase.firestore();
      let data = [];
      let boutiques = [];
      let docs = await db.collection("products").get();
      docs.forEach((doc) => data.push({ ...doc.data(), id: doc.id }));
      let docs_u = await db.collection("boutiques").get();
      docs_u.forEach((doc) => boutiques.push({ ...doc.data(), id: doc.id }));

      data.map((pdt) => {
        pdt.boutique = {};
        for (const b of boutiques) {
          if (pdt.boutique_id === b.id) {
            pdt.boutique = b?.nom;
            break;
          }
        }
        return pdt;
      });

      console.log("produits==>", data);
      this.setState({ data: data, boutiques: boutiques });
      // console.log(this.state.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        btnglyphicon={<i className="pe-7s-search" />}
        style={{
          color: "#e0272d",
          borderColor: "#e0272d",
          border: "solid 0.2em",
        }}
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  descriptionFormat = (row, cell) => {
    return (
      <>
        <p style={{ whiteSpace: "normal" }}>{`${cell.description}`}</p>
      </>
    );
  };
  typeFormat = (row, cell) => {
    return (
      <>
        <p style={{ whiteSpace: "normal" }}>{cell.type}</p>
      </>
    );
  };

  cellBoutique = (cell, row) => {
    console.log("enum test", row);
    // return (
    //   <>
    //     <p style={{ whiteSpace: "normal" }}>{row?.boutique?.nom}</p>
    //   </>
    // );
    return row.boutique.nom;
  };
  nameBoutikFormat = (cell, row) => {
    console.log("enum test", row.boutique);
    return row.boutique;
  };
  cellProduit = (cell, row) => {
    console.log("tesstt===>", row?.nom);
    // return <p style={{ whiteSpace: "normal" }}>{row.nom}</p>;
    return row.nom;
  };

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
    };

    const { data } = this.state;
    // const nom_boutik={};
    // data.map((pdt) => {
    //   boutique = {};
    //   for (const b of boutiques) {
    //     if (pdt.boutique_id === b.id) {
    //       pdt.boutique = b;
    //       break;
    //     }
    //   }
    //   return pdt;
    // });
    // let dataObj = [];
    // let newObj = {};

    let newObject = {};
    for (let item of data) {
      var tempObj = {
        ...tempObj,
        [item?.nom]: {
          boutique: item?.boutique?.nom,
          produits: item?.nom,
        },
      };
      newObject = tempObj;
    }
    // console.log("test obj results", newObject);
    let obj = {};
    for (let key in newObject) {
      let value = newObject[key];
      // console.log(key, value);
      obj = {
        ...obj,
        [key]: value.produits,
      };
    }
    // console.log(data);
    // console.log(obj);
    // eslint-disable-next-line array-callback-return
    // data.map((pdt) => {
    //   for (const b of this.state.boutiques) {
    //     if (pdt.boutique_id === b.id) {
    //       var tempObj = {
    //         ...tempObj,
    //         [pdt?.boutique?.nom]: {
    //           boutique: pdt?.boutique?.nom,
    //           produits: pdt?.nom,
    //         },
    //       };
    //       newObj = tempObj;
    //       let index = dataObj.findIndex(
    //         (f) => f.boutique === pdt?.boutique?.nom
    //       );
    //       if (index === -1) {
    //         // var tempObj = {
    //         //   ...tempObj,
    //         //   [pdt?.boutique?.nom]: {
    //         //     boutique: pdt?.boutique?.nom,
    //         //     produits: pdt?.nom,
    //         //   },
    //         // };
    //         // newObj = tempObj;
    //         let obj = {
    //           boutique: pdt?.boutique?.nom,
    //           produits: pdt?.nom,
    //         };
    //         // jour.push(obj);
    //         dataObj = [...dataObj, obj];
    //       }
    //       // dataObj = [...dataObj, pdt?.boutique?.nom];
    //     }
    //   }
    // });
    // console.log("data objet==>", dataObj);
    // console.log("data new objet==>", newObj);
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              {/* <div className="card-plain"> */}
              {/* <div className="header">
                  <h4 className="title">Liste des Produits</h4>
                </div> */}
              <div className="content">
                <BootstrapTable
                  ref="table"
                  pagination
                  data={data}
                  keyField={"id"}
                  // striped
                  options={options}
                  search={true}
                  version={"4"}
                  multiColumnSort={2}
                  condensed={true}
                >
                  {/* <TableHeaderColumn width='260' isKey dataField='boutique_id'>ID Product</TableHeaderColumn> */}
                  <TableHeaderColumn width="150" dataField="categorie" dataSort>
                    Categorie
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="170"
                    dataField="description"
                    tdStyle={{ whiteSpace: "normal" }}
                    // dataFormat={this.descriptionFormat}
                    dataSort
                  >
                    Description
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="170"
                    dataField="boutique"
                    tdStyle={{ whiteSpace: "normal" }}
                    filter={{ type: "TextFilter", delay: 1000 }}
                    dataFormat={this.nameBoutikFormat}
                    filterValue={this.nameBoutikFormat}
                    filterFormatted
                    dataSort
                  >
                    Nom Boutique
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="150"
                    dataField="nom"
                    tdStyle={{ whiteSpace: "normal" }}
                    dataSort
                  >
                    Nom Produit
                  </TableHeaderColumn>
                  <TableHeaderColumn width="150" dataField="prix" dataSort>
                    Prix Produit
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="170"
                    dataField="type"
                    tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    // dataFormat={this.typeFormat}
                    // dataSort
                  >
                    Type
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
              {/* </div> */}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
