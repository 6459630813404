import { GET_COMMANDES, GET_CORDER_BY_ID, GET_ORDERS_PRODUCTS, GET_REALTIME_COMMANDES } from './actions/type'

const initialState = {
    items: [],
    commands: {},
    _getCmds: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COMMANDES:
            // console.log('get commande action', action.payload)
            return {
                ...state,
                items: action.payload
            }
        case GET_CORDER_BY_ID:
            return {
                ...state,
                item: action.payload
            }
        case GET_ORDERS_PRODUCTS:
            // console.log('get commande action', action.payload)
            return {
                ...state,
                commands: action.payload
            }
        case GET_REALTIME_COMMANDES:
            return {
                ...state,
                _getCmds: action.payload
            }
        default:
            return state
    }
}