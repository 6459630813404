import Card from 'components/Card/Card'
import Button from 'components/CustomButton/CustomButton.jsx'
import React, { Component } from 'react';
import { Col, Form, FormGroup, Grid } from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import firebase from '../../firebase.js'
import { toast } from 'react-toastify';
export default class Contrat extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: '',
            load: false,
        }
    }

    componentDidMount() {
        this._getContrats()
    }


    _getContrats = async () => {
        try {
            const db = firebase.firestore();
            await db.collection('ucg').doc('ucg').get()
                .then((docRef) => {
                    // console.log(docRef.data().description)
                    let data = docRef.data().description;
                    this.setState({ data: data })
                })
        } catch (error) {
            console.log(error.message)
        }
    }

    notify = () => {
        toast.success('Ajoutee avec success',
            { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }
        )
    }

    notifyErreur = () => {
        toast.error("Oupps erreur lors de l'envoi, Veuillez reessayer!",
            { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }
        )
    }

    addContrat = async (e) => {
        e.preventDefault();
        this.setState({ load: true })
        // console.log("contrat save==>", this.state.data)
        const data = {
            description: this.state.data,
        }
        console.log(data);
        try {
            const db = firebase.firestore();
            let store = db.collection('ucg').doc('ucg');
            const doc = await store.get();
            if (doc.exists) {
                store.update(data);
                this.setState({ load: !this.state.load })
                this.notify()
            }
            else {
                store.set(data);
                this.setState({ load: !this.state.load })
                this.notify()
            }
        } catch (error) {
            console.log(error.message);
            this.setState({ load: !this.state.load })
            this.notifyErreur()
        }
    }

    render() {
        let { load } = this.state
        return (
            <div className="content">
                <Grid fluid>
                    {/* <Row> */}
                    <Col md={12}>
                        {/* {this.state.success ? <Alert color="success" isopen={this.state.visible.toString()}>{this.state.message}</Alert> : null} */}
                        <Card
                            title="Mentions légales, CGV "
                            content={
                                <Form>
                                    <FormGroup>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={this.state?.data}
                                            onChange={(e, editor) => {
                                                const data = editor.getData()
                                                // console.log({e,editor,data})
                                                this.setState({ data: data })
                                            }}
                                        />
                                    </FormGroup>
                                    <div className="row">
                                        <Col md={12}>
                                            {!load ?
                                                <Button style={style.btn} fill onClick={this.addContrat} >Enregistrer</Button> :
                                                <Button style={style.btn} fill  >Loading...<i className="fa fa-spin fa-spinner fa-lg" aria-hidden="true"></i></Button>
                                            }
                                        </Col>
                                    </div>
                                </Form>
                            } />
                    </Col>
                </Grid>
            </div>
        )
    }
}

const style = {
    btn: {
        backgroundColor: 'rgba(226, 41, 47, 0.92)',
        float: 'right',
        marginRight: '0.8em',
        borderColor:'#e4393f'
    }
}