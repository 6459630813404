import moment from "moment";
import "moment/locale/fr";
/* import { isEmported } from "../data/data"; */

export function getMonth(date = undefined) {
  return moment(date).format("MMMM");
}
export function getYear(date = undefined) {
  return moment(date).format("YYYY");
}
export function getWeek(date = undefined) {
  return moment(date).format("ww");
}
export function getDay() {
  return moment().format("ddd");
}
export function isSupTHan2(dateTime) {
  return moment(dateTime).subtract(1, "days");
}
export function getDayFromDate(date) {
  return moment(date).format("ddd");
}
export function getTimeNumber(date) {
  return moment(date).toDate().getTime();
}

export function getDayFromAllDate(date) {
  return moment(date).format("dddd");
}

export function getDayFromAllDateYear(date) {
  return moment(date).format("LL");
}

export function getDayNumberFromDate(date) {
  return moment(date).format("D");
}
export function addDay(number, date = null) {
  if (date) return moment(date).add(number, "d");

  return moment().add(number, "d");
}

export function getMonthNumber() {
  return moment().format("M");
}
export function toDay() {
  return moment();
}
export function toDayFromDate(date) {
  return moment(date);
}
export function toDayDateTime() {
  return moment().toDate().getTime();
}

export function getDayNumber(date = null) {
  if (date === null) {
    date = moment().toDate().getTime();
  }
  return moment(date).format("e");
}
export function fullFormat(date) {
  return moment(date).format("D MMM YYYY à kk:mm:ss");
}
export function formatTime(time) {
  return moment(time).format("LT");
}
export function getCurrentHourNumber() {
  const [h, m] = moment()
    .format("LT")
    .split(":")
    .map((f) => parseInt(f));
  return h + m / 60;
}
export function formatDate(date = null) {
  if (date === null) date = toDayDateTime();

  return moment(date).format("L");
}

export function isClosed(midi = {}, soir = {}) {
  const { debut, fin } = midi;
  const { debut: debut1, fin: fin1 } = soir;
  let time = moment();
  return !(isOpen(debut, fin, time) || isOpen(debut1, fin1, time));
}
export function getH(date) {
  return parseInt(moment(date).format("k"), 10);
}
export function getM(date) {
  return parseInt(moment(date).format("m"), 10);
}
function isOpen(d1, d2, currentDate) {
  const H1 = getH(d1);
  const M1 = getM(d1);
  const H2 = getH(d2);
  const M2 = getM(d2);
  const H = getH(currentDate);
  const M = getM(currentDate);

  const debut = M1 + H1 * 60;
  const fin = M2 + H2 * 60;
  const currentTime = M + H * 60;

  return currentTime >= debut && currentTime <= fin;
}

export const getDisplayFormat = (hour) => {
  const h = parseInt(hour);
  const m = parseInt((hour - h) * 60, 10);
  const s = parseInt(((hour - h) * 60 - m) * 60, 10);

  return moment(new Date(2020, 20, 3, h, m, s)).format("LT");
};

export function isEmported(products) {
  if (!products) return false;
  return (
    products.filter((p) => {
      return p.product_type === EMPORTED;
    }).length === products.length
  );
}

export function hasStock(stock) {
  if (stock === undefined || stock === "") return false;

  return !!String(stock).trim();
}

export const EMPORTED = "À emporter";

export function getProductTypes() {
  return [EMPORTED, "Prestation sur rendez-vous"];
}


/* export const getFormattedDateTime = (
  item,
  forNotification = false,
  emported = false
) => {
  let i = isEmported(item.produits) || emported;
  if (i) {
    const intervalFrom = item.interval;
    return `${formatDate(
      item[forNotification ? "dateOrder" : "date"]
    )} | ${getDisplayFormat(i ? intervalFrom?.fin : intervalFrom?.debut)}`;
  }
  if (item.interval) {
    const interval = item.interval;
    return `${formatDate(
      item[forNotification ? "dateOrder" : "date"]
    )} | ${getDisplayFormat(interval?.debut)} => ${getDisplayFormat(
      interval?.fin
    )}`;
  }
  return `${formatDate(item[forNotification ? "dateOrder" : "date"])}`;
};

export function getRush(boutique) {
  const rush = boutique?.rush;
  if (rush) {
    return {
      interval: rush.interval / 60 || 0.25,
      number: rush.number || 1,
    };
  }
  return {
    interval: 1,
    number: 1,
  };
} */
