import React, { Component } from "react";
// import PropTypes from 'prop-types';
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Modal,
  ModalBody,
  ModalHeader,
} from "react-bootstrap";
import FormInputs from "components/FormInputs/FormInputs";
import Card from "components/Card/Card";
import firebase from "../../firebase.js";
import CheckBoxGroup from "components/CheckBoxGroup/CheckBoxGroup.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { toast } from "react-toastify";

// const RenderPaiement = ({ modePaiement }) => {
//     return (
//         <React.Fragment>
//             {modePaiement?.map((item, index) =>
//                 (<Label key={index}>{item}</Label>)
//             )}
//         </React.Fragment>
//     )
// }

class EditBoutique extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        categorie: "",
      },
      item_edit: this.props.item,
      pay_modes: [
        { id: 1, value: "Chéques", isChecked: false },
        { id: 2, value: "Ticket resto", isChecked: false },
        { id: 3, value: "Carte Bancaire", isChecked: false },
        { id: 4, value: "Espéces", isChecked: false },
      ],
      categorys: [],
      load: false,
      modePaiement: [],
    };
  }

  componentDidMount() {
    this._getCategorys();
    console.log(this.state?.item_edit);
  }

  _getCategorys = async () => {
    let data = [];
    try {
      let db = firebase.firestore();
      let docs = await db.collection("categories").get();
      docs.forEach((doc) => data.push({ ...doc.data(), id: doc.id }));
      console.log(data);
      this.setState({ categorys: data });
    } catch (error) {
      console.log(error.message);
    }
  };

  handleAllChecked = (event) => {
    let pay_modes = this.state.pay_modes;
    pay_modes.forEach((mode_p) => (mode_p.isChecked = event.target.checked));
    this.setState({ pay_modes: pay_modes }, () => console.log(pay_modes));
  };

  handleCheckChieldElement = (event) => {
    let pay_modes = this.state.pay_modes;
    pay_modes.forEach((mode_p) => {
      if (mode_p.value === event.target.value) {
        mode_p.isChecked = event.target.checked;
      }
      this.setState({ pay_modes }, () => console.log(mode_p));
    });
  };

  notify = () => {
    toast.success("Boutique modifiee avec success", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
    setTimeout(() => {
      if (!!this.props.onEdit) {
        this.props.onEdit();
      }
    }, 3000);
  };

  onUpdate = async (e) => {
    e.preventDefault();
    this.setState({ load: true });
    // let { item_edit } = this.state;
    let item = this.props.item;
    let { modePaiement } = this.state;
    this.state.pay_modes.forEach((pm) => {
      if (pm.isChecked === true) {
        console.log(pm.value);
        modePaiement = [...modePaiement, pm.value];
        item.modePaiement = modePaiement;
      }
      this.setState({ item_edit: item });
    });
    let data = this.state.item_edit;
    console.log("objet to modify =>", data);
    if (Object.entries(data).length !== 0) {
      try {
        const db = firebase.firestore();
        await db
          .collection("boutiques")
          .doc(data.id)
          .set({ ...data })
          .then((docRef) => {
            console.log("success");
            this.setState({ load: !this.state.load });
            this.notify();
          });
      } catch (error) {
        console.log(error.message);
        this.setState({ load: !this.state.load });
        toast.error(
          "Oupps erreur lors de l'envoi, la requete n'a pas passee, Veuillez reessayer!",
          { position: toast.POSITION.TOP_CENTER, autoClose: 3000 }
        );
      }
    } else {
      this.setState({ load: !this.state.load });
      toast.warn("Entre champs a modifier", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  handleChange = (e) => {
    let item = this.props.item;
    item[e.target.name] = e.target.value;
    this.setState({ item_edit: item }, console.log(this.state.item_edit));
  };

  handleSelect = (e) => {
    let item = this.props.item;
    console.log("selected", e.target.value);
    item[e.target.name] = e.target.value;
    console.log(item);
    this.setState({ item_edit: item }, console.log(this.state.item_edit));
  };

  render() {
    let { editModal, onEdit, item } = this.props;
    let { categorys } = this.state;

    let render_option = categorys?.map((cat, i) => (
      <option key={cat.id} value={cat.nom} id={cat.id}>
        {cat.nom}
      </option>
    ));
    return (
      <React.Fragment>
        <Modal
          backdrop={false}
          show={editModal}
          onHide={onEdit}
          style={{ borderRadius: "1em" }}
        >
          <ModalHeader closeButton style={style.modal_header}>
            <h5>Modifier ma boutique</h5>
          </ModalHeader>
          <ModalBody>
            <Grid fluid>
              <Col md={12}>
                <Card
                  title="Modifier ma boutique"
                  content={
                    <Form>
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Nom de ma boutique",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Nom de ma boutique",
                            name: "nom",
                            value: item?.nom || "",
                            onChange: this.handleChange,
                          },
                          {
                            label: "Nom du dirigeant",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Nom du dirigeant",
                            name: "drigeant",
                            value: item?.drigeant || "",
                            onChange: this.handleChange,
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-sm-6", "col-sm-6"]}
                        properties={[
                          {
                            label: "Numero telephone",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Numero telephone",
                            name: "phone",
                            value: item?.phone || "",
                            onChange: this.handleChange,
                          },
                          {
                            label: "Numéro siret",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Numéro siret (RNA pour association)",
                            name: "numSiret",
                            value: item?.numSiret || "",
                            onChange: this.handleChange,
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-4", "col-md-4", "col-md-4"]}
                        properties={[
                          {
                            label: "Description courte",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Description courte (3 mots cles)",
                            name: "descriptionCourte",
                            value: item?.descriptionCourte || "",
                            onChange: this.handleChange,
                          },
                          {
                            label: "Adresse boutique",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Adresse de votre boutique",
                            name: "adresse",
                            value: item?.adresse || "",
                            onChange: this.handleChange,
                          },
                          {
                            label: "Adresse complétee",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Adresse complétee",
                            name: "complementAdresse",
                            value: item?.complementAdresse || "",
                            onChange: this.handleChange,
                          },
                        ]}
                      />
                      <FormGroup>
                        <ControlLabel>Description détaillée</ControlLabel>
                        <textarea
                          className="form-control"
                          name="description"
                          value={item?.description || ""}
                          type="text"
                          placeholder="Description détaillée"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                      <FormInputs
                        ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                        properties={[
                          {
                            label: "Ville",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Ville",
                            name: "ville",
                            value: item?.ville || "",
                            onChange: this.handleChange,
                          },
                          {
                            label: "Code Postal",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Code Postal",
                            name: "codePostal",
                            value: item?.codePostal || "",
                            onChange: this.handleChange,
                          },
                          {
                            label: "Latitude",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "Latitude",
                            name: "latitude",
                            value: item?.latitude || "",
                            onChange: this.handleChange,
                          },
                          {
                            label: "Longitude",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "Longitude",
                            name: "longitude",
                            value: item?.longitude || "",
                            onChange: this.handleChange,
                          },
                        ]}
                      />
                      <FormGroup>
                        <ControlLabel>Category</ControlLabel>
                        <FormControl
                          componentClass="select"
                          name="categorie"
                          placeholder="select"
                          onChange={this.handleChange}
                        >
                          <option value={item?.categorie}>
                            {item?.categorie}
                          </option>
                          {render_option}
                        </FormControl>
                      </FormGroup>
                      <FormGroup>
                        <input
                          type="checkbox"
                          onClick={this.handleAllChecked}
                          value="checkedall"
                        />{" "}
                        Tous cocher / Tous décocher
                        <ul style={{ listStyle: "none" }}>
                          {this.state.pay_modes.map((mode_p, i) => {
                            return (
                              <CheckBoxGroup
                                key={i}
                                handleCheckChieldElement={
                                  this.handleCheckChieldElement
                                }
                                {...mode_p}
                              />
                            );
                          })}
                        </ul>
                      </FormGroup>
                      <FormGroup>
                        {!this.state.load ? (
                          <Button round fill block onClick={this.onUpdate}>
                            Modifier
                          </Button>
                        ) : (
                          <Button round fill block>
                            Patientez...
                            <i
                              className="fa fa-spin fa-spinner"
                              aria-hidden="true"
                            ></i>
                          </Button>
                        )}
                      </FormGroup>
                    </Form>
                  }
                />
              </Col>
            </Grid>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
export default EditBoutique;
// EditBoutique.propTypes = {
//     editModal: PropTypes.bool,
//     item: PropTypes.object.isRequired,
//     onEdit: PropTypes.func.isRequired
// }

const style = {
  modal_header: {
    backgroundColor: "rgba(233, 43, 51,0.80)",
    borderRadius: "0.8em",
  },
};
