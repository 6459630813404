import React, { Component } from "react";
import { Col, Grid, Row, Card } from "react-bootstrap";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import IconBg from "../../../assets/img/iconBg.png";
import "./abonnement.css";
import CardAbonnement from "./CardAbonnement";
import firebase from "../../../firebase.js";
import LoadSpinner from "views/LoadSpinner";
import moment from "moment";
import Button from "components/CustomButton/CustomButton.jsx";
import { toast, cssTransition } from "react-toastify";
import "../test.css";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { getBoutique } from "../../../reducers/boutique/actions/boutiqueAction";
import Switch from "react-switch";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { PAYMENT_ACCOUNT_STATUS } from "../../../variables/constants";
import { getFormToken } from "http/api";
toast.configure();
function reset(date) {
  return moment(date)
    .set("minutes", 0)
    .set("seconds", 0)
    .set("milliseconds", 0)
    .set("hours", 0)
    .set("dates", 1);
}

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
});
class Abonnement extends Component {
  state = {
    abonnements: [],
    load: false,
    loading: false,
    selected: null,
    user: {},
    subscriptions: [],
    show: false,
    showComponent: false,
    showChoice: false,
    onlinePayment: false,
    onLoad: false,
  };

  async componentDidMount() {
    this.props.getBoutique();
    this._getUserAbon();
    this._getUserSubs();
  }

  // onShow = () => {
  //   this.props.history.push("/admin/createboutique");
  // };

  _getUserAbon = async () => {
    if (this.listner) return;
    // let{onlinePayment}=this.state
    try {
      let uid = JSON.parse(sessionStorage.getItem("id_user"));
      const db = firebase.firestore();
      this.listner = db
        .collection("users")
        .doc(uid)
        .onSnapshot((qs) => {
          let userData = {
            ...qs.data(),
            id: qs.id,
          };
          this.setState({
            user: userData,
            onlinePayment: userData?.onlinePayment,
          });
          this.fetchAbonnement();
        });
    } catch (error) {
      console.log("erreur", error.message);
    }
  };

  _getUserSubs = async () => {
    if (this.listner1) return;
    // let{onlinePayment}=this.state
    try {
      const db = firebase.firestore();
      this.listner1 = db
        .collection("subscriptions")
        .where("userId", "==", firebase.auth().currentUser.uid)
        .onSnapshot((qs) => {
          const data = [...this.state.subscriptions];
          qs.docChanges().forEach((change) => {
            if (change.type === "added") {
              data.push({
                ...change.doc.data(),
                id: change.doc.id,
              });
            }
          });
          const g = (a) => moment(a.month).toDate().getTime();
          data.sort((a, b) => g(b) - g(a));
          this.setState({ subscriptions: data });
        });
    } catch (error) {
      console.log("erreur", error.message);
    }
  };

  componentWillUnmount() {
    if (this.listner) {
      this.listner();
    }
    if (this.listner1) {
      this.listner1();
    }
  }
  componentWillUnmount() {
    if (this.listner) {
      this.listner();
    }
  }

  fetchAbonnement = async () => {
    try {
      const db = firebase.firestore();
      this.setState({ loading: true });
      let abonnements = [];
      await db
        .collection("abonnements")
        .get()
        .then((docs) => {
          docs.forEach((d) => {
            abonnements.push({ ...d.data(), id: d.id });
          });
          this.setState({ loading: !this.state.loading });
          abonnements.sort((a, b) => !a.is_basic);
          this.setState({
            abonnements,
            selected: this.state.user?.abonnement || abonnements[0]?.id,
          });
        });
    } catch (error) {
      console.log(`error=>${error.code}`);
      this.setState({ loading: !this.state.loading });
    }
  };

  onBack = (e) => {
    e.preventDefault();
    if (!!this.props.back) {
      this.props.back();
    }
  };

  notifySuccess = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
      transition: Zoom,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    console.log("onSubmit valider");
    this.setState({ onLoad: true });
    let { onlinePayment } = this.state;
    try {
      const db = firebase.firestore();
      let user = await db
        .collection("users")
        .doc(firebase.auth().currentUser.uid);
      let doc = await user.get();
      if (doc.exists) {
        user.update({ onlinePayment });
        this.notifySuccess("Opération enregister avec succes");
        this.setState({ onLoad: false });
        this._getUserAbon();
        if (Object.keys(this.props.userBoutiq).length === 0) {
          this.props.history.push("/admin/createboutique");
        }
      }
    } catch (error) {
      this.setState({ onLoad: false });
      console.log("error=>", error.message);
    }
  };

  send = async (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    const cb = (m = "Abonnement enregistré") => {
      this.notifySuccess(m);
      this._getUserAbon();
      if (this.state.selected === this.state.abonnements[1]?.id) {
        this.setState((prevState) => {
          return { showChoice: !prevState.showChoice };
        });
      } else {
        console.log("boutik on Abonnement=>", this.props.userBoutiq);
        if (Object.keys(this.props.userBoutiq).length === 0) {
          this.props.history.push("/admin/createboutique");
        }
      }
    };

    let m = "Abonnement enregistré";
    const { selected } = this.state;
    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .update({ abonnement: selected, appleOrGoogle: false, expired: false })
      .then((res) => {
        cb(m);
      });
    this.setState({ loading: false });
  };
  onChange = (value) => {
    this.setState({ onlinePayment: value });
  };
  render() {
    if (this.state.loading) {
      return (
        <div style={{ minHeight: "80vh" }}>
          <LoadSpinner />
        </div>
      );
    }
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col mdOffset={1}>
              <CardAbonnement
                title="Choix Abonnement"
                content={
                  <React.Fragment>
                    {this.props.pathname ? (
                      <ArrowBackSharpIcon
                        style={{ cursor: "pointer" }}
                        fontSize={"large"}
                        onClick={this.onBack}
                      />
                    ) : null}

                    <Row>
                      {/* <Col md={12} lg={12} sm={12}> */}
                      <img
                        alt=""
                        src={IconBg}
                        style={{ width: "90%", marginLeft: "1em" }}
                      />
                      {/* </Col> */}
                      {!this.state.loading ? <DisplayText /> : null}
                    </Row>
                    <React.Fragment>
                      {this.state.showChoice ? (
                        <CardActiveChoice
                          onSubmit={this.onSubmit}
                          onLoad={this.state.onLoad}
                          user={this.state.user}
                          select={this.state.onlinePayment}
                          onSwitch={() =>
                            this.setState({
                              onlinePayment: !this.state.onlinePayment,
                            })
                          }
                        />
                      ) : (
                        <React.Fragment>
                          <Row>
                            {this.state.abonnements.map((ab) => {
                              if (ab?.is_basic) {
                                return (
                                  <Item
                                    key={ab.id}
                                    amount={ab.amount}
                                    selected={this.state.selected === ab.id}
                                    onSelect={() =>
                                      this.setState({ selected: ab.id }, () =>
                                        console.log(
                                          "selected",
                                          this.state.selected
                                        )
                                      )
                                    }
                                    Visibilities={[
                                      "Visibilité",
                                      "Coordonnées",
                                      "Horaires",
                                      "Geolocalisation",
                                    ]}
                                  />
                                );
                              } else {
                                return (
                                  <Item
                                    key={ab.id}
                                    amount={ab.amount}
                                    promoDate={ab?.promo?.fin}
                                    promo={hasPromos(ab)}
                                    selected={this.state.selected === ab.id}
                                    onSelect={() =>
                                      this.setState(
                                        {
                                          selected: ab.id,
                                        },
                                        () =>
                                          console.log(
                                            "selected",
                                            this.state.selected
                                          )
                                      )
                                    }
                                    Visibilities={[
                                      "Visibilité",
                                      "Coordonnées",
                                      "Horaires",
                                      "Geolocalisation",
                                      "Commande en ligne",
                                      "Agenda",
                                      "Statistique",
                                      "Option de paiement en ligne à venir",
                                    ]}
                                  />
                                );
                              }
                            })}
                          </Row>
                          <Row>
                            <Col md={12} mdOffset={5} sm={12} smOffset={5}>
                              {Object.keys(this.props.userBoutiq).length ===
                              0 ? (
                                <Button
                                  style={style.btn}
                                  fill
                                  onClick={(e) => this.send(e)}
                                >
                                  Choisir et Continuer
                                </Button>
                              ) : (
                                <Button
                                  style={style.btn}
                                  fill
                                  onClick={(e) => this.send(e)}
                                >
                                  Je m'abonne
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  </React.Fragment>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export function hasPromos(abonnement) {
  return true;
}

function Item({ Visibilities, amount, promo, promoDate, selected, onSelect }) {
  // console.log("selected", selected);
  // console.log("amount selected", amount);
  return (
    <React.Fragment>
      <Col md={6} sm={12} xs={12} style={{ marginTop: "4em" }}>
        <div
          onClick={onSelect}
          className={`pricing-table wow fadeInUp pricing-tabs ${
            selected ? "active" : ""
          }`}
          data-wow-duration="1.5s"
        >
          <div className="pricing-header">
            <h3 className="pack-name">{amount}€</h3>
            {promo && (
              <div className="promo">
                <span
                  style={{
                    fontSize: "12px",
                    marginTop: "1em",
                    color: "#f2f4f9",
                  }}
                >
                  OFFERT
                  <p style={{ fontSize: "12px", marginTop: "-3rem" }}>
                    {" "}
                    Les 2
                    <span
                      style={{ fontSize: "12px" }}
                    >{`${` `}${`1er mois`}`}</span>
                  </p>
                </span>
              </div>
            )}
          </div>
          <p className="myPrice-pack">
            <span className="price-ammount">
              {amount}€/mois
              <span style={{ color: "black" }}> HT</span>
            </span>
          </p>
          <ul className="pack-features">
            {Visibilities.map((v, idx) => {
              const lastItem = idx === Visibilities.length - 1 && amount > 0;
              if (!lastItem) {
                return <CardVisibilite key={idx} listItem={v} />;
              } else {
                return (
                  <li
                    key={idx}
                    style={{
                      fontStyle: "italic",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {v}
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </Col>
    </React.Fragment>
  );
}

const CardVisibilite = ({ listItem }) => {
  return <li className="last-offre">{listItem}</li>;
};

const CardActiveChoice = ({ select, onSwitch, user, onSubmit, onLoad }) => {
  const getStatut = (user) => {
    let statut = "";
    if (user.payment_account_status === PAYMENT_ACCOUNT_STATUS.DEFAULT) {
      statut = "NC";
    }
    if (user.payment_account_status === PAYMENT_ACCOUNT_STATUS.EN_COURS) {
      statut = "En cours";
    }
    if (user.payment_account_status === PAYMENT_ACCOUNT_STATUS.EN_COURS) {
      statut = "Active";
    }
    return statut;
  };
  const displayPara = () => (
    <div style={{ paddingTop: "78px" }}>
      <Row>
        <Col md={6} mdOffset={4} smOffset={4} xs={12} xsOffset={4}>
          <p style={style.para}>
            <CreditCardIcon style={{ fontSize: "32px" }} /> Paiement intégré à
            l'application?
          </p>
          <Row>
            <Col md={6}>
              <p style={style.para}>Activation</p>
            </Col>
            <Col md={3} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Switch checked={select} onChange={onSwitch} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <p style={style.para}>Statut</p>
            </Col>
            <Col md={6}>
              <div
                style={style.aroundDiv}
                className={`${
                  user.payment_account_status === PAYMENT_ACCOUNT_STATUS.DEFAULT
                    ? "bg_white"
                    : user.payment_account_status ===
                      PAYMENT_ACCOUNT_STATUS.EN_COURS
                    ? "bg_jaune"
                    : "bg_green"
                }`}
              >
                <label style={style.label}>{getStatut(user)}</label>
              </div>
            </Col>
          </Row>
          <br />
        </Col>
        <Col md={6} mdOffset={4} smOffset={4} xs={6} xsOffset={4}>
          <p style={style.para}>
            {" "}
            En activant le paiement intégré, l’utilisateur pourra payer de
            manière sécurisé directement sur ÔbouduCLIC. Pour vous, une garantie
            supplémentaire d’une commande confirmée et payée. Des frais (y
            compris frais bancaires) de 6% seront prélevés sur chaque
            transaction payée sur Ôbouduclic. Le règlement de la commande vous
            sera versé sous 3j maximum
          </p>
        </Col>
      </Row>
    </div>
  );
  return (
    <React.Fragment>
      {displayPara()}
      <Row>
        <Col md={12} mdOffset={5} sm={12} smOffset={5}>
          {!onLoad ? (
            <Button style={style.btn} fill onClick={onSubmit}>
              Valider mon choix
            </Button>
          ) : (
            <Button style={style.btn} fill>
              Chargement...
              <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>
            </Button>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

const DisplayText = () => {
  return (
    <>
      <Row>
        <Col md={6} mdOffset={4} smOffset={4} xs={12} xsOffset={4}>
          <p style={{ color: " #f83f4b" }}>Gagnez en visibilité!</p>
          <br />
        </Col>
        <Col md={6} mdOffset={4} smOffset={4} xs={6} xsOffset={4}>
          <p style={style.para}>
            {" "}
            Une application locale pour renforcer les liens avec les habitants
            de la commune.
          </p>
        </Col>
      </Row>
    </>
  );
};

const style = {
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    // float: "right",
    color: "#ffffff",
    marginRight: "0.8em",
    borderColor: "#e4393f",
    margin: "1em 0em auto -3em",
  },
  aroundDiv: {
    borderColor: "#007bff",
    borderRadius: "35px",
    border: "2px solid rgba(18, 19, 19, 0.75)",
    width: "125px",
    height: "30px",
  },
  label: {
    marginTop: "6px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "900",
    color: "black",
  },
  para: {
    color: "#000000",
    textAlign: "justify",
    fontSize: "large",
  },
};

const mapStateToProps = (state) => ({
  userBoutiq: state.boutique.boutiq || {},
});
export default connect(mapStateToProps, { getBoutique })(Abonnement);
