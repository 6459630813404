import { GET_PRODUCTS, GET_PRODUCTS_BOUTIQUE, GET_PRODUCT_BY_ID } from './actions/type'

const initialState = {
    items: [],
    productsItems: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                items: action.payload
            }
        case GET_PRODUCT_BY_ID:
            return {
                ...state,
                item: action.payload
            }
        case GET_PRODUCTS_BOUTIQUE:
            console.log("case here", action.payload)
            return {
                ...state,
                productsItems: action.payload
            }
        default:
            return state
    }
}