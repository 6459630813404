import Card from "components/Card/Card";
import React, { Component } from "react";
import "../Boutiques/boutik_style.css";
import Button from "components/CustomButton/CustomButton.jsx";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import firebase from "firebase";
import HomeSpinner from "views/HomeSpinner";
import { get_seller } from "http/api";
import { get_documents } from "http/api";
import { Input } from "./addSeller";
import {
  Grid,
  Col,
  Modal,
  FormGroup,
  Form,
  ModalHeader,
  ModalBody,
} from "react-bootstrap";
import CustomButton from "components/CustomButton/CustomButton";
import { add_documents } from "http/api";
toast.configure();
const sellerFields = [
  {
    name: "status",
    label: "statut de la demande d'enrôlement",
  },
  {
    name: "description",
    label: "Référence technique de l’enrôlement",
  },

  {
    name: "name",
    label: "Nom du vendeur",
  },
  {
    name: "trading_name",
    label: "Nom commercial",
  },
  {
    name: "nature",
    label: "Raison sociale",
  },
  {
    name: "iban",
    label: "IBAN de paiement",
  },
  {
    name: "vat_number",
    label:
      "Numéro de TVA. Lorsque le marchand n’est pas soumis à la TVA, indiquer le numéro de TVA de la Marketplace.",
  },
  {
    name: "turnover",
    label: "Chiffre d’affaire estimé (en centimes)",
  },
  {
    name: "address.zipcode",
    label: "Code postal",
  },
  {
    name: "address.street_number",
    label: "Numéro de voie",
  },
  {
    name: "address.country",
    label: "Code pays sur 2 lettres",
  },
  {
    name: "address.street",
    label: "Type de voie",
  },
  {
    name: "address.street",
    label: "Type de voie",
  },
  {
    name: "address.city",
    label: "Ville",
  },
];

const boradFields = [
  {
    name: "first_name",
    label: "Prénom",
  },
  {
    name: "last_name",
    label: "Nom",
  },
  {
    name: "birth_date",
    label: "Date de naissance",
  },
  {
    name: "title",
    label: "Civilité",
  },
  {
    name: "birth_city",
    label: "Ville de naissance",
  },
  {
    name: "nationality",
    label: "Nationalité",
  },
  {
    name: "phone_number",
    label: "Numéro de téléphone",
    type: "tel",
  },
  {
    name: "email",
    label: "Email",
    type: "email",
  },
  {
    name: "address.zipcode",
    label: "Code postal",
  },
  {
    name: "address.street_number",
    label: "Numéro de voie",
  },
  {
    name: "address.country",
    label: "Code pays sur 2 lettres",
  },
  {
    name: "address.street",
    label: "Type de voie",
  },
  {
    name: "address.street",
    label: "Type de voie",
  },
  {
    name: "address.city",
    label: "Ville",
  },
];

const activitiesField = [
  {
    name: "name",
    label: "Enseigne de la boutique",
  },
  {
    name: "url",
    label: "Adresse Internet de la boutique",
    type: "url",
  },
  {
    name: "registration_date",
    label: "Date d’immatriculation ",
    type: "date",
  },
  {
    name: "siret",
    label: "Identifiant SIRET",
  },
  {
    name: "mcc",
    type: "number",
    label: "Code MCC Exemple : 7997 pour les clubs de loisirs",
  },
  {
    name: "naf",
    label: "Code NAF",
  },
  {
    name: "legal_identifier",
    label: "Identifiant légal",
  },
];
export default class AddSeller extends Component {
  constructor(props) {
    const id = props.match.params.id;
    super(props);
    this.state = {
      errors: {},
      boutique: {},
      documents: [],
      seller: {},
      addDocuments: false,
      load: true,
      id,
      data: {},
    };
  }

  componentDidMount() {
    firebase
      .firestore()
      .collection("boutiques")
      .doc(this.state.id)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          const seller = doc.data().seller;
          console.log("seller", seller);
          const result = await get_seller(seller);
          if (!result.error) {
            const resultDocuments = await get_documents(seller);
            console.log("documents", resultDocuments);
            this.setState({
              boutique: doc.data(),
              seller: result.results,
              documents: resultDocuments.results,
              load: false,
            });
          } else {
            this.props.history.replace("/admin/on-boarding");
          }
        } else {
          this.props.history.replace("/admin/on-boarding");
        }
      });
  }

  onBack = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    this.props.history.goBack();
  };

  notify = () => {
    toast.success("Vendeur ajouté avec success", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };
  notifyError = () => {
    toast.error("Vendeur non ajouté", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };

  render() {
    const { load, boutique, seller, documents, addDocuments } = this.state;

    if (load) {
      return <HomeSpinner />;
    }
    const board = seller.board ? seller.board[0] : {};
    return (
      <React.Fragment>
        <AddDocumentsModal
          onDone={async () => {
            const resultDocuments = await get_documents(seller.uuid);
            this.setState({
              documents: resultDocuments.results,
            });
          }}
          board_member={board.uuid}
          sellerId={seller.uuid}
          visible={addDocuments}
          onHide={() => this.setState({ addDocuments: false })}
        />
        <div className="content">
          <Grid fluid>
            <Col md={12}>
              <Card
                title={boutique.nom}
                content={
                  <React.Fragment>
                    <ArrowBackSharpIcon
                      style={{ cursor: "pointer" }}
                      fontSize={"large"}
                      onClick={this.onBack}
                    />
                    <Card
                      title={"documents KYC (télé-versement)"}
                      content={
                        <div>
                          <Button
                            onClick={() =>
                              this.setState({ addDocuments: true })
                            }
                            type="submit"
                            fill
                          >
                            Ajouter des documents KYC
                          </Button>
                          <TableDocuments documents={documents} />
                        </div>
                      }
                    />
                    <Card
                      title={"Information du vendeur"}
                      content={
                        <TableSeller data={seller} fields={sellerFields} />
                      }
                    />
                    <Card
                      title={"Représentant légal"}
                      content={
                        <TableSeller data={board} fields={boradFields} />
                      }
                    />
                    <Card
                      title={"activités du vendeur"}
                      content={
                        <TableSeller
                          data={seller.activities[0]}
                          fields={activitiesField}
                        />
                      }
                    />
                  </React.Fragment>
                }
              />
            </Col>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

function TableSeller({ data, fields }) {
  return (
    <table className="table table-striped">
      <tbody>
        {fields.map((field, index) => {
          return (
            <tr key={index}>
              <th>{field.label}</th>
              <td>{_.get(data, field.name)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function TableDocuments({ documents }) {
  if (documents.length === 0) return null;
  return (
    <table className="table table-striped">
      <thead>
        <th>Description</th>
        <th>Type</th>
        <th>Statut</th>
      </thead>
      <tbody>
        {documents.map((item, index) => {
          return (
            <tr key={index}>
              <td>{item.description}</td>
              <td>{item.type}</td>
              <td>{item.status}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const documentForm = [
  {
    name: "file",
    label: "Le fichier à télé-verser (image ou pdf)",
    type: "file",
  },
  {
    name: "description",

    label: "Description du document",
  },
  {
    name: "type",
    type: "select",
    label: "Type de document KYC",
    items: [
      { label: "IDENTITY_CARD", value: "IDENTITY_CARD" },
      { label: "PASSPORT", value: "PASSPORT" },
      { label: "RESIDENCE_PERMIT", value: "RESIDENCE_PERMIT" },
      { label: "IBAN", value: "IBAN" },
      { label: "JDD", value: "JDD" },
      { label: "KBIS", value: "KBIS" },
    ],
  },
];

function AddDocumentsModal({
  visible,
  onHide,
  sellerId,
  board_member,
  onDone,
}) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({
    file: "",
    description: "",
    type: "",
    reference: new Date().getTime(),
    board_member: board_member,
  });
  const [errors, setErrors] = React.useState({});

  const getError = React.useCallback(
    (path) => {
      return _.get(errors, path);
    },
    [errors]
  );
  const getErrors = React.useCallback((errors) => {
    if (errors.detail) {
      return {
        file: errors.detail,
      };
    }
    const keys = Object.keys(errors);
    for (let k of keys) {
      if (Array.isArray(errors[k])) {
        errors[k] = getErrors(errors[k][0]);
      }
    }
    return errors;
  });
  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      const errors = {};
      Object.keys(data).map((d) => {
        if (!data[d]) {
          errors[d] = "this field is required";
        }
      });
      if (Object.keys(errors).length === 0) {
        setLoading(true);
        try {
          const form = new FormData();
          Object.keys(data).map((k) => {
            form.append(k, data[k]);
          });
          const { data: results } = await add_documents(sellerId, form);
          console.log("added results", results);
          if (results?.error === false) {
            setData({
              file: "",
              description: "",
              type: "",
              reference: new Date().getTime(),
              board_member: board_member,
            });
            onDone();
            onHide();
          } else {
            setErrors({
              file: "verifier les données",
            });
          }
        } catch (ex) {
          console.log("Error Response", ex.response);
          console.log("Error", ex);
        }
        setLoading(false);
      } else {
        setErrors(errors);
      }
    },
    [data]
  );
  return (
    <Modal show={visible} onHide={onHide} backdrop={false}>
      <ModalHeader closeButton>
        <h5>Ajouter des documents KYC</h5>
      </ModalHeader>
      <ModalBody>
        <Form
          id="form"
          method="post"
          target="_blank"
          action={`https://us-central1-obouduclic-298615.cloudfunctions.net/widgets?sellerId=${sellerId}`}
          encType="multipart/form-data"
        >
          <input type="hidden" name="reference" value={data.reference} />
          <input type="hidden" name="board_member" value={data.board_member} />
          {documentForm.map((f) => (
            <Input
              getValue={(name) => {
                return _.get(data, name);
              }}
              onChange={(name, e) => {
                const newData = { ...data };
                if (name === "file") {
                  newData[name] = e.target.files[0];
                } else {
                  newData[name] = e.target.value;
                }
                setData(newData);
              }}
              getError={getError}
              form={f}
            />
          ))}
          <FormGroup>
            {!loading ? (
              <CustomButton
                onClick={onSubmit}
                // onClick={function (e) {
                //   e.preventDefault();
                //   setLoading(true);
                //   setTimeout(() => {
                //     onHide();
                //     onDone();
                //     setLoading(false);
                //   }, 3000);
                //   document
                //     .getElementById("form")
                //     .dispatchEvent(new Event("submit"));
                // }}
                type="submit"
                round
                fill
                style={{ backgroundColor: "rgba(226, 41, 47, 0.92)" }}
              >
                Enregistrer
              </CustomButton>
            ) : (
              <CustomButton
                round
                fill
                style={{ backgroundColor: "rgba(226, 41, 47, 0.92)" }}
              >
                Chargement...
                <i className="fa fa-spin fa-spinner" aria-hidden="true" />
              </CustomButton>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
}
