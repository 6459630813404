import React, { Component } from "react";
import CGVDialog from "./CGVDialog.jsx";
import "./styleAuth.css";
import firebase from "../../../firebase";
import { displayMessage } from "utils";
import { Button, FormGroup } from "react-bootstrap";
import { toast } from "react-toastify";
toast.configure();
export default class AuthUser extends Component {
  state = {
    class: "",
    fields: {
      email: "",
      password: "",
    },
    load: false,
    open: false,
    cgv: "",
    champs: {
      username: "",
      password: "",
      passwordConfirmation: "",
      email: "",
    },
    errors: {},
    is_loading: false,
  };

  componentDidMount() {
    this._getCgv();
  }

  _getCgv = async () => {
    try {
      const db = firebase.firestore();
      await db
        .collection("ucg")
        .doc("ucg")
        .get()
        .then((docRef) => {
          let data = docRef.data().description;
          this.setState({ cgv: data });
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  openModal = () => {
    this.setState((prevState) => {
      return { open: !prevState.open };
    });
  };

  hasErrorFor = (field) => {
    return !!this.state.errors[field];
  };

  renderErrorFor = (field) => {
    if (this.hasErrorFor(field)) {
      return (
        <strong style={{ color: "#f60404ba", fontWeight: "normal" }}>
          <i className="fas fa-exclamation-circle" /> {this.state.errors[field]}
        </strong>
      );
    }
  };

  createAccount = (
    email,
    password,
    displayName,
    photoUrl = null,
    commercant
  ) => {
    this.setState({ is_loading: true });
    let data = {
      email: email,
      password: password,
      displayName: displayName,
      photoUrl: photoUrl,
      commercant: commercant,
    };
    console.log(`data sended=>`, data);
    const db = firebase.firestore();
    try {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async ({ user }) => {
          if (user) {
            const uid = user?.uid;
            await firebase
              .auth()
              .currentUser.updateProfile({ displayName, photoUrl })
              .then(async () => {
                let doc = db.collection("users").doc(uid);
                let userCollection = await doc.get();
                if (userCollection.exists) {
                  this.setState({ is_loading: false });
                  toast.warn("Ce compte existe deja", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                  });
                } else {
                  doc.set({
                    commercant,
                    nom: displayName,
                    photo: photoUrl,
                    email: email,
                  });
                  toast.success("Compte ajoute avec success", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                  });
                  this.setState({
                    is_loading: false,
                    champs: {
                      username: "",
                      email: "",
                      password: "",
                      passwordConfirmation: "",
                    },
                  });
                }
              })
              .catch((error) => {
                this.setState({ is_loading: false });
                console.log(error.code);
                displayMessage(error.code);
              });
          }
        });
    } catch (error) {
      console.log(error.code);
      this.setState({ is_loading: false });
      displayMessage(error.code);
    }
  };

  onSignUp = (e) => {
    e.preventDefault();
    let errors = {};
    let {
      state: {
        champs: { password, passwordConfirmation, email, username },
      },
      createAccount,
    } = this;
    if (password !== passwordConfirmation) {
      errors["password"] = "Les mots de passe ne sont pas identiques";
      errors["passwordConfirmation"] =
        "Les mots de passe ne sont pas identiques";
      this.setState({ errors });
    }
    if (
      password.trim().length === 0 &&
      passwordConfirmation.trim().length === 0
    ) {
      errors["password"] = "Le champ mot de passe est obligatoire";
      errors["passwordConfirmation"] = "Le champ mot de passe est obligatoire";
      this.setState({ errors });
    }
    if (username.trim().length === 0) {
      errors["username"] = "Le champ nom est obligatoire";
      this.setState({ errors });
    }
    if (email.trim().length === 0) {
      errors["email"] = "Le champ email est obligatoire";
      this.setState({ errors });
    } else {
      let commercant = true;
      createAccount(email, password, username, null, commercant);
    }
  };

  renderSignUp = () => {
    const {
      state: { champs, is_loading },
      hasErrorFor,
      renderErrorFor,
      onSignUp,
      signInWithGoogle,
    } = this;

    let onChange = (e) => {
      e.preventDefault();
      let { name, value } = e.target;
      champs[name] = value;
      this.setState({ champs }, () =>
        console.log("sign up=>", this.state.champs.email)
      );
    };

    return (
      <div className="form-container sign-up-container">
        <form>
          <h2>Créer un compte</h2>
          <div className="social-container">
            <Button className="socialBtn">
              <i className="fab fa-facebook-f"></i>
            </Button>
            <Button className="socialBtn" onClick={signInWithGoogle}>
              <i className="fab fa-google-plus-g"></i>
            </Button>
            <Button className="socialBtn">
              <i className="fab fa-twitter"></i>
            </Button>
          </div>
          {/* <span>or use your email for registration</span> */}
          <FormGroup style={style}>
            <input
              type="text"
              placeholder="Nom"
              name="username"
              onChange={onChange}
              value={champs.username}
            />
            {renderErrorFor("username")}
          </FormGroup>
          <FormGroup style={style}>
            <input
              type="email"
              placeholder="Email"
              name="email"
              onChange={onChange}
              value={champs.email}
            />
            {renderErrorFor("email")}
          </FormGroup>
          <FormGroup style={style}>
            <input
              type="password"
              placeholder="Mot de passe"
              className={`${hasErrorFor("password") ? `is-invalid` : ""}`}
              name="password"
              onChange={onChange}
              value={champs.password}
            />
            {renderErrorFor("password")}
          </FormGroup>
          <FormGroup style={style}>
            <input
              type="password"
              placeholder="Confirmation mot de passe"
              className={`${
                hasErrorFor("passwordConfirmation") ? "is-invalid" : ""
              }`}
              name="passwordConfirmation"
              onChange={onChange}
              value={champs.passwordConfirmation}
            />
            {renderErrorFor("passwordConfirmation")}
          </FormGroup>
          {!is_loading ? (
            <button className="button_style" onClick={onSignUp}>
              S'inscrire
            </button>
          ) : (
            <button className="button_style">
              Loading...
              <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>
            </button>
          )}
        </form>
      </div>
    );
  };

  handleChange = (e) => {
    e.preventDefault();
    const { fields } = this.state;
    const { name, value } = e.target;
    fields[name] = value;
    this.setState(
      { fields }
      // ,() =>console.log(this.state.fields)
    );
  };

  accountLogin = async (resp) => {
    let uid = resp.user.uid;
    console.log("user=>uid", resp.user, uid);
    try {
      const db = firebase.firestore();
      let user = await db.collection("users").doc(uid).get();
      if (user) {
        let doc = user.data();
        if (doc?.is_admin) {
          this.setState({ load: false });
          sessionStorage.setItem("id_user", JSON.stringify(uid));
          sessionStorage.setItem("is_admin", JSON.stringify(doc?.is_admin));
          window.location.pathname = "/admin/dashboard";
        } else {
          sessionStorage.setItem("id_user", JSON.stringify(uid));
          this.setState({ load: false });
          window.location.pathname = "/admin/mydashboard";
        }
      }
    } catch (error) {
      console.log(error.code);
      this.setState({ load: false });
      displayMessage(error.code);
    }
  };

  onLogin = async (e) => {
    e.preventDefault();
    this.setState({ load: true });
    const { fields } = this.state;
    const { accountLogin } = this;
    if (
      fields.email.trim().length === 0 ||
      fields.password.trim().length === 0
    ) {
      this.setState({ load: false });
      displayMessage(null);
    }
    try {
      let req = await firebase
        .auth()
        .signInWithEmailAndPassword(fields.email, fields.password);
      console.log("auth user", req);
      if (req) {
        await accountLogin(req);
      }
    } catch (error) {
      console.log(error.code);
      this.setState({ load: false });
      displayMessage(error.code);
    }
  };

  upDateUserAuth = async (auth) => {
    const { uid, displayName, email } = auth.user;
    // console.log("==>", uid, displayName, email);
    try {
      const db = firebase.firestore();
      let exitsUser = db.collection("users").doc(uid);
      const doc = await exitsUser.get();
      if (!doc.exists) {
        console.log("set user");
        exitsUser.set({
          commercant: true,
          nom: displayName,
          photo: null,
          email: email,
        });
        toast.success("Authentification avec Success", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        sessionStorage.setItem("id_user", JSON.stringify(uid));
        setTimeout(() => {
          window.location.pathname = "/admin/mydashboard";
        }, 4000);
      } else {
        console.log("update user");
        exitsUser.update({
          commercant: true,
          nom: displayName,
          photo: null,
          email: email,
        });
        toast.success("Authentification avec Success", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        sessionStorage.setItem("id_user", JSON.stringify(uid));
        setTimeout(() => {
          window.location.pathname = "/admin/mydashboard";
        }, 4000);
      }
    } catch (error) {
      console.log(error.code);
      displayMessage(error.code);
    }
  };

  signInWithGoogle = async (e) => {
    console.log("ici=>");
    e.preventDefault();
    const { upDateUserAuth } = this;
    try {
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      const auth = await firebase.auth().signInWithPopup(googleProvider);
      // let req=auth.user
      console.log("auth user=>", auth.user);
      if (auth) {
        await upDateUserAuth(auth);
      }
    } catch (error) {
      console.log(error);
      if (error.code === "auth/popup-closed-by-user") {
        return;
      }
      displayMessage(error.code);
    }
  };

  signInWithGFacebook = async (e) => {
    e.preventDefault();
    try {
      const facebookProvider = new firebase.auth.FacebookAuthProvider();
      const auth = await firebase.auth().signInWithPopup(facebookProvider);
      console.log("auth user=>", auth.user);
    } catch (error) {
      console.log(error.code);
    }
  };

  renderSignIn = () => {
    let {
      state: {
        fields: { email, password },
        load,
      },
      handleChange,
      onLogin,
      signInWithGoogle,
    } = this;
    return (
      <div className="form-container sign-in-container">
        <form>
          <h2>Connexion</h2>
          <div className="social-container">
            <Button className="socialBtn" onClick={this.signInWithGFacebook}>
              <i className="fab fa-facebook-f"></i>
            </Button>
            <Button className="socialBtn" onClick={signInWithGoogle}>
              <i className="fab fa-google-plus-g"></i>
            </Button>
            <Button className="socialBtn">
              <i className="fab fa-twitter"></i>
            </Button>
          </div>
          <span>ou entrée votre email et mot de passe </span>
          <input
            type="email"
            value={email}
            name="email"
            onChange={handleChange}
            placeholder="Email"
          />
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => handleChange(e)}
            placeholder="Password"
          />
          {/* <a href="!#">Forgot your password?</a> */}
          {!load ? (
            <button className="button_style" onClick={onLogin}>
              Se connecter
            </button>
          ) : (
            <button className="button_style">
              {" "}
              Loading...
              <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>
            </button>
          )}
        </form>
      </div>
    );
  };

  render() {
    return (
      <div style={{ boxSizing: "border-box" }}>
        <CGVDialog
          open={this.state.open}
          openModal={this.openModal}
          cgv={this.state.cgv}
        />
        <div className="body">
          <div
            className={`_container col-md-8 col-xs-12 col-sm-8 ${
              this.state.class.length !== 0 ? this.state.class : ""
            }`}
            id="container"
          >
            {this.renderSignUp()}
            {this.renderSignIn()}
            <div className="_overlay-container">
              <div className="_overlay">
                <div className="overlay-panel overlay-left">
                  <h2>Bienvenue sur ÔbouduCLIC</h2>
                  <p>Se connecter avec son email et mot de passe</p>
                  <button
                    className="ghost"
                    id="signIn"
                    onClick={(e) => {
                      e.preventDefault();
                      //   console.log("click");
                      this.setState({ class: "" });
                    }}
                  >
                    Se connecter
                  </button>
                  <p>
                    En vous inscrivant vous acceptez{" "}
                    <strong
                      onClick={this.openModal}
                      style={{
                        color: "rgb(20, 20, 20)",
                        cursor: "pointer",
                      }}
                    >
                      la politique de confidentialité et d'utilisation
                    </strong>
                  </p>
                </div>
                <div className="overlay-panel overlay-right">
                  <h2>Bienvenue sur ÔbouduCLIC</h2>
                  <p>
                    Créer votre compte en renseignant vos informations
                    personnelles
                  </p>
                  <button
                    className="ghost"
                    id="signUp"
                    onClick={(e) => {
                      e.preventDefault();
                      //   console.log("click");
                      this.setState({ class: "right-panel-active" });
                    }}
                  >
                    S'inscrire
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const style = {
  width: "100%",
  marginBottom: "1px",
};
