import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Card from "components/Card/Card";
// import SwitchToggle from "components/SwitchToogle/SwitchToogle";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/el";
import "react-datepicker/dist/react-datepicker.css";
import "../../components/CardHoraire/cardCss.css";
import Button from "components/CustomButton/CustomButton.jsx";
import Switch from "react-switch";
import { getH } from "utils";
import { getM } from "utils";
import { Form } from "react-bootstrap/lib/Navbar";
import firebase from "../../firebase.js";
import { toast } from "react-toastify";
toast.configure();
registerLocale("fr", fr);
const INTERVALES = [15, 30, 60];
export default class Parametres extends Component {
  state = {
    interval: INTERVALES[0],
    number: 1,
    suspensionCommendes: {
      midi: { status: false, debut: 0, fin: 0 },
      soir: { status: false, debut: 0, fin: 0 },
    },
    load: false,
    showPicker: {
      midi: { debut: false, soir: false },
      soir: { debut: false, soir: false },
    },
  };

  componentDidMount() {
    this._getRush();
  }

  _getRush = async () => {
    let { interval, suspensionCommendes, number } = this.state;
    try {
      let db = firebase.firestore();
      let boutik = {};
      let uid = JSON.parse(sessionStorage.getItem("id_user"));
      let doc = await db.collection("boutiques").doc(uid).get();
      console.log("doc", doc.data());
      // this.setState({ spinner: false });
      boutik = doc.data()?.rush;
      if (boutik) {
        console.log("interval=>", boutik?.interval);
        interval = boutik?.interval;
        number = boutik?.number;
        suspensionCommendes = boutik?.suspensionCommendes;
      }
      this.setState({ interval, number, suspensionCommendes });
    } catch (error) {
      console.log(error.code);
    }
  };

  increment = () => {
    this.setState({
      number: this.state.number + 1,
    });
  };

  decrement = () => {
    if (this.state.number !== 0) {
      this.setState({
        number: this.state.number - 1,
      });
    }
  };

  renderDatePicker = (type, dur) => {
    console.log("type=>", dur);
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
      <button ref={ref} style={style.inputPicker} onClick={onClick}>
        {value}
      </button>
    ));
    const ref = React.createRef();
    // const testDate = new Date();
    // console.log("test date", testDate);

    const value =
      this.state.suspensionCommendes[type][dur] || new Date().getTime();
    return (
      <div style={style.datePicker}>
        <DatePicker
          showTimeSelect
          showTimeSelectOnly
          timeCaption="Time"
          timeFormat="HH:mm"
          dateFormat="HH:mm"
          locale="fr"
          mode="time"
          selected={value}
          timeIntervals={15}
          onChange={(selectedDate) => this.dateChange(type, dur, selectedDate)}
          customInput={<CustomInput ref={ref} />}
        />
      </div>
    );
  };

  dateChange = (type, dur, selectedDate) => {
    // console.log("date in onChange=>", selectedDate, type, dur);
    const time = selectedDate.getTime();
    if (selectedDate) {
      let suspensionCommendes = { ...this.state.suspensionCommendes };
      suspensionCommendes[type][dur] = time;
      let { debut, fin } = suspensionCommendes[type];
      //   console.log("debut=>fin", debut, fin);
      if (
        debut &&
        fin &&
        this.getTime(debut) > this.getTime(fin) &&
        dur === "fin"
      ) {
        // console.log("ici");
        suspensionCommendes[type]["fin"] = suspensionCommendes[type]["debut"];
        alert(
          "Heure debut doit étre inferieur à Heure fin",
          "Heure debut doit étre inferieur à Heure fin"
        );
        // this.setState({ suspensionCommendes });
      }
      this.setState({ suspensionCommendes });
    }
  };

  notify = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  };

  onSave = async (e) => {
    e.preventDefault();
    this.setState({ load: !this.state.load });
    let uid = JSON.parse(sessionStorage.getItem("id_user"));
    const { number, interval, suspensionCommendes } = this.state;
    let rush = {
      interval: interval,
      number: number,
      suspensionCommendes: suspensionCommendes,
    };
    console.log("data send=>", rush);
    try {
      const db = firebase.firestore();
      let boutik = await db.collection("boutiques").doc(uid);
      const doc = await boutik.get();
      if (doc.exists) {
        boutik.update({
          rush,
        });
        this.setState({ load: !this.state.load });
        this.notify("les données ont été ajoutées avec succès");
      }
    } catch (error) {
      this.setState({ load: !this.state.load });
      console.log(error.code);
    }
  };

  getTime(time) {
    return getH(time) * 60 + getM(time);
  }

  renderSwitchHour(type, dur) {
    console.log("pyte=>", type);
    return <React.Fragment>{this.renderDatePicker(type, dur)}</React.Fragment>;
  }

  renderNbProduits = () => {
    return (
      <div>
        <button style={style.increDecreBtn} onClick={this.decrement}>
          -
        </button>
        <span style={style.span}> {`${" "}${this.state.number}${" "}`}</span>
        <button style={style.increDecreBtn} onClick={this.increment}>
          +
        </button>
      </div>
    );
  };

  renderSwitch = (title, type) => {
    console.log("type=>", type);
    const item = this.state.suspensionCommendes[type];
    console.log("item=>", item);
    // const showPicker = this.state.showPicker[type];
    // console.log("showPicker=>", showPicker);
    const onChange = (value) => {
      console.log("value=>", value);
      const suspensionCommendes = { ...this.state["suspensionCommendes"] };
      suspensionCommendes[type].status = value;
      //   console.log("type=>", suspensionCommendes[type]);
      this.setState({ suspensionCommendes }, () =>
        console.log("suspension=>", this.state.suspensionCommendes)
      );
    };
    console.log("=>", item["status"]);
    return (
      <div
        style={{
          display: "flex",
          margin: "2em 0 0 0",
          justifyContent: "center",
        }}
      >
        <Switch
          onChange={(value) => onChange(value)}
          className="react-switch"
          checked={this.state["suspensionCommendes"][type].status}
        />
      </div>
    );
  };

  render() {
    console.log("intervale=>", this.state.interval);
    const renderSelect = () => {
      const styles = (theme) => ({
        formControl: {
          margin: theme.spacing(1),
          minWith: 220,
        },
        selectEmpty: {
          marginTop: theme.spacing(2),
        },
      });
      return (
        <div>
          <FormControl
            style={{ width: "120px" }}
            className={styles.formControl}
          >
            <InputLabel id="demo-simple-select-label">MINUTES</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.interval}
              name="interval"
              onChange={(e) => {
                const { name, value } = e.target;
                this.setState({ [name]: value }, () =>
                  console.log("name,value=>", this.state.interval)
                );
              }}
            >
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={60}>60</MenuItem>
            </Select>
          </FormControl>
        </div>
      );
    };

    const { suspensionCommendes } = this.state;

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={8} mdOffset={2}>
              <Card
                title="Parametres"
                content={
                  <Form>
                    <React.Fragment>
                      <Row>
                        <Col md={12}>
                          <p>
                            NOMBRE DE PRODUITS / PRESTATIONS POUVANT ÉTRE
                            RÉALISÉS PAR
                          </p>
                        </Col>
                        <Col md={12}>
                          <Row>
                            <br />
                            <Col md={6}>{renderSelect()}</Col>
                            <Col md={6}>{this.renderNbProduits()}</Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <br />
                        <Col md={12}>
                          <p>
                            SUSPENSION DES PRISES DE COMMANDES DURANT LE RUSH
                          </p>
                        </Col>
                        <Col md={12} style={{ margin: "1em 0em 0.2em" }}>
                          <Row>
                            <Col md={2}>
                              <span
                                style={{ paddingTop: "2em", display: "flex" }}
                              >
                                Matin/Midi
                              </span>
                            </Col>
                            <Col md={10}>
                              <Row>
                                <Col md={4}>
                                  {this.renderSwitch("Matin/midi", "midi")}
                                </Col>
                                <Col md={8}>
                                  <Row>
                                    {suspensionCommendes["midi"].status ? (
                                      <React.Fragment>
                                        <Col md={6}>
                                          {this.renderSwitchHour(
                                            "midi",
                                            "debut"
                                          )}
                                        </Col>
                                        <Col md={6}>
                                          {this.renderSwitchHour("midi", "fin")}
                                        </Col>
                                      </React.Fragment>
                                    ) : null}
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={2}>
                              <span
                                style={{ paddingTop: "2em", display: "flex" }}
                              >
                                Après-midi/Soir
                              </span>
                            </Col>
                            <Col md={10}>
                              <Row>
                                <Col md={4}>
                                  {this.renderSwitch(
                                    "Après-midi/soir ",
                                    "soir"
                                  )}
                                </Col>
                                <Col md={8}>
                                  <Row>
                                    {suspensionCommendes["soir"].status ? (
                                      <React.Fragment>
                                        <Col md={6}>
                                          {this.renderSwitchHour(
                                            "soir",
                                            "debut"
                                          )}
                                        </Col>
                                        <Col md={6}>
                                          {this.renderSwitchHour("soir", "fin")}
                                        </Col>
                                      </React.Fragment>
                                    ) : null}
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{ display: "flex", marginTop: "3em" }}>
                        <Col md={12}>
                          {!this.state.load ? (
                            <Button
                              style={style.btn}
                              pullRight
                              fill
                              onClick={this.onSave}
                            >
                              Enregistrer
                            </Button>
                          ) : (
                            <Button style={style.btn} pullRight fill>
                              Chargement...
                              <i
                                className="fa fa-spin fa-spinner"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          )}
                        </Col>
                        <div className="clearfix"></div>
                      </Row>
                    </React.Fragment>
                  </Form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const style = {
  increDecreBtn: {
    borderRadius: "50%",
    height: "42px",
    width: "42px",
    color: "#f33942",
    backgroundColor: "white",
    border: "1px solid",
  },
  span: {
    fontSize: "x-large",
    fontWeight: "600",
    color: "#f33942",
  },
  datePicker: {
    backgroundColor: "#FFFFFF",
    boxShadow: " 0px 5px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "100px",
    fontFamily: "Poppins sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "19px",
    lineHeight: "21px",
    letterSpacing: " 0.4px",
    color: "#252733",
    padding: "0em 3rem 0.5rem 1.2rem",
    border: "2px solid #f3f3f3",
    width: "110px",
    appearance: "none",
    marginTop: "1.1em",
  },
  inputPicker: {
    cursor: "pointer",
    // padding: "8px 10px",
    height: "20px",
    border: 0,
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    fontSize: "inherit",
    color: "black",
    width: "81px",
    display: "flex",
    marginTop: "0.4em",
    marginLeft: "0em",
  },
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    float: "left",
    marginRight: "0.8em",
    borderColor: "#e4393f",
    width: "25%",
  },
};
