// import Card from "components/Card/Card";
import React, { Component } from "react";
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "react-bootstrap";
import FormInputs from "components/FormInputs/FormInputs";
import { CATEGORIES } from "constants.js";
import { TYPE } from "constants.js";
import { TYPE_PRODUIT } from "constants.js";
import Button from "components/CustomButton/CustomButton.jsx";
import { displayMessage } from "utils";
import firebase from "../../../firebase.js";
import { toast } from "react-toastify";
import { DURATION } from "constants.js";
import defaultImg from "assets/img/default-image.jpg";
const storage = firebase.storage();

toast.configure();
export default class EditProduit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: CATEGORIES,
      type: TYPE,
      type_produit: TYPE_PRODUIT,
      uid: JSON.parse(sessionStorage.getItem("id_user")),
      spinner: false,
      load: false,
      produit: this.props.prod,
      data: this.props.data,

      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "Plat",
      all_catg: [],
      show: false,
      url: "",
      file: null,
    };
  }

  componentDidMount() {
    // this.setState({ produit: this.props.produit });
    this._getCategorie();
  }

  _getCategorie = async () => {
    try {
      let db = firebase.firestore();
      let all_catg = [];
      let docs = await db.collection("categories").get();
      docs.forEach((doc) => all_catg.push({ ...doc.data() }));
      // console.log("all categ=>", all_catg);
      let testTab = [];
      all_catg.forEach((element) => {
        testTab.push(element.nom);
      });
      // console.log("test tab=>", testTab);
      this.setState({ all_catg: testTab });
    } catch (error) {
      console.log(error.code);
    }
  };

  componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps.prod !== prevProps.prod) {
      console.log("i'm in=>");
      this.setState({ produit: nextProps?.prod }, () => {
        if (nextProps?.prod?.type === "temps préparation non standard") {
          this.setState({ show: true });
        } else {
          this.setState({ show: false });
        }
        // console.log("result=>", this.state.produit);
      });
    }
  }

  onChangeSuggestion = (e) => {
    let suggestions = this.state.all_catg;
    // console.log("suggestions=>", suggestions);
    // const userInput = e.currentTarget.value;
    let { produit } = this.state;
    produit.categorie = e.currentTarget.value;
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(produit.categorie.toLowerCase()) > -1
    );

    this.setState(
      {
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true,
        userInput: e.currentTarget.value,
        produit,
      },
      () => console.log("sug=>", produit.categorie)
    );
  };

  onClick = (e) => {
    let { produit } = this.state;
    produit.categorie = e.currentTarget.innerText;
    this.setState(
      {
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: e.currentTarget.innerText,
        produit,
      },
      () =>
        console.log("click choice categorie=>", this.state.produit.categorie)
    );
  };

  onKeyDown = (e) => {
    console.log("keyDown=>", e.keyCode);
    const { activeSuggestion, filteredSuggestions, produit } = this.state;

    if (e.keyCode === 13) {
      produit.categorie = filteredSuggestions[activeSuggestion];
      this.setState(
        {
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: filteredSuggestions[activeSuggestion],
          produit,
        },
        () => console.log("categorie=>", this.state.produit.categorie)
      );
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  renderInputSuggestion = () => {
    const {
      onChangeSuggestion,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        produit,
      },
    } = this;
    let suggestionsListComponent;
    if (showSuggestions && produit?.categorie) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }
              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      }
    }
    return (
      <React.Fragment>
        <input
          type="text"
          className="form-control"
          onChange={onChangeSuggestion}
          onKeyDown={onKeyDown}
          value={produit?.categorie}
        />
        {suggestionsListComponent}
      </React.Fragment>
    );
  };

  onChange = (e) => {
    e.preventDefault();
    let { produit } = this.state;
    const { name, value } = e.target;
    produit[name] = value;
    this.setState({ produit }, () => {
      console.log("==>", produit);
      if (produit?.type === "temps préparation non standard") {
        this.setState({ show: true });
      } else {
        produit.durration = "";
        this.setState({ show: false, produit });
      }
    });
  };

  notify = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  };

  updateData = (item) => {
    let { data } = this.state;
    for (let i = 0; i < data.length; i++) {
      let element = data[i];
      if (element.id === item.id) {
        console.log("ici");
        data[i] = item;
        return;
      }
    }
    this.props.callBackFromParent(data);
  };

  updateProduit = async (data) => {
    const { produit, file } = this.state;
    console.log("prodId=>", produit?.id);
    const db = firebase.firestore();
    try {
      let prod = await db.collection("products").doc(produit?.id);
      let doc = await prod.get();
      if (doc.exists) {
        // console.log("exist");
        if (file !== null) {
          await this.uploadImage(data);
        }
        prod.update(data);
        this.setState({ load: false });
        this.notify("Produit modifié avec sucess");
        data.id = produit.id;
        await this.updateData(data);
        setTimeout(async () => {
          if (!!this.props.onOpen) {
            this.props.onOpen();
          }
        }, 1000);
      } else {
        this.setState({ load: false });
        console.log("not exist");
      }
    } catch (error) {
      this.setState({ load: false });
      console.log(error.code);
    }
  };

  addNewCategorie = async (data) => {
    let { categorie } = data;
    // console.log("data=>", data);
    try {
      const db = firebase.firestore();
      let category = await db.collection("categories").doc();
      let doc = await category.get();
      if (!doc.exists) {
        console.log("exist pas");
        await category.set({ nom: categorie }).then(async () => {
          await this.updateProduit(data);
        });
      }
    } catch (error) {
      this.setState({ load: false });
      console.log(error.code);
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ load: true });
    const { produit } = this.state;

    let data = {
      nom: produit?.nom,
      description: produit?.description,
      stock: produit?.stock,
      prix: produit?.prix,
      categorie: produit?.categorie,
      product_type: produit?.product_type,
      durration: produit?.durration || false,
      type: produit?.type,
      boutique_id: produit?.boutique_id,
    };
    // console.log("=>", data);
    try {
      let test = this.state.all_catg.includes(data.categorie);
      if (test) {
        await this.updateProduit(data);
      } else {
        await this.addNewCategorie(data);
      }
    } catch (error) {
      console.log(error);
      this.setState({ load: false });
      displayMessage(error.code);
    }
  };

  onFileChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    console.log(file.name);
    console.log("test");
    this.setState({ file: file });
  };

  async uploadImage(data) {
    let { file } = this.state;
    if (file !== null) {
      const storageRef = storage.ref(`boutiques/`);
      const fileRef = storageRef.child(file?.name);
      await fileRef.put(file);
      this.setState({ url: await fileRef.getDownloadURL() });
      data.photo = this.state.url;
    }
    return data;
  }

  render() {
    const { show, onOpen } = this.props;
    let { type, type_produit, load, produit } = this.state;
    console.log("props produit=>", produit);

    // console.log("props edit modal", show);
    // const renderCategorie = this.state.categories.map((item, i) => {
    //   return (
    //     <option placeholder="categorie" value={item} key={i}>
    //       {item}
    //     </option>
    //   );
    // });
    const renderType = type.map((item, i) => {
      return (
        <option
          placeholder="Temps de preparation du produit"
          value={item}
          key={i}
        >
          {item}
        </option>
      );
    });
    const renderTypeProduit = type_produit.map((item, i) => {
      return (
        <option
          placeholder="Temps de preparation du produit"
          value={item}
          key={i}
        >
          {item}
        </option>
      );
    });

    const renderDuration = DURATION.map((item, i) => {
      const selected = produit?.durration === item ? produit?.durration : item;
      // console.log("selec=>", selected);
      return (
        <option selected={selected} placeholder="durée" value={item} key={i}>
          {item}
        </option>
      );
    });

    const renderBody = (item) => {
      return (
        <ModalBody>
          <FormGroup>
            <img
              src={item?.photo || defaultImg}
              width="200"
              height="230"
              alt="description"
            />
            <input
              type="file"
              onChange={this.onFileChange}
              name="image"
              label="Image"
            />
          </FormGroup>
          <Form>
            <FormInputs
              ncols={["col-md-12"]}
              properties={[
                {
                  label: "Nom du produit",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Nom du produit",
                  name: "nom",
                  value: item?.nom || "",
                  onChange: this.onChange,
                },
              ]}
            />
            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>Description détaillée</ControlLabel>
                  <textarea
                    className="form-control"
                    name="description"
                    type="text"
                    value={item?.description || ""}
                    placeholder="Un peu plus de details sur ce produit"
                    onChange={this.onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              properties={[
                {
                  label: "Quantite stock",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Quantite stock",
                  name: "stock",
                  value: item?.stock || "",
                  onChange: this.onChange,
                },
                {
                  label: "Prix en €",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Prix en €",
                  name: "prix",
                  value: item?.prix || "",
                  onChange: this.onChange,
                },
              ]}
            />
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Categorie</ControlLabel>
                  {this.renderInputSuggestion()}
                  {/* <FormControl
                    name="categorie"
                    value={produit?.categorie}
                    componentClass="select"
                    placeholder="Categorie"
                    onChange={this.onChange}
                  >
                    {renderCategorie}
                  </FormControl> */}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Temps de preparation</ControlLabel>
                  <FormControl
                    componentClass="select"
                    value={produit?.type}
                    name="type"
                    placeholder="Temps de preparation"
                    onChange={this.onChange}
                  >
                    {renderType}
                  </FormControl>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Type de Produit</ControlLabel>
                  <FormControl
                    componentClass="select"
                    name="product_type"
                    placeholder="Type produit"
                    onChange={this.onChange}
                  >
                    {renderTypeProduit}
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
            {this.state.show ? (
              <Row>
                <Col md={4} style={{ float: "left" }}>
                  <FormGroup>
                    <ControlLabel>Durée</ControlLabel>
                    <FormControl
                      componentClass="select"
                      name="durration"
                      value={produit?.durration}
                      placeholder="Durée"
                      onChange={this.onChange}
                    >
                      <option defaultValue="">Choisir la durée</option>
                      {renderDuration}
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
            {!load ? (
              <Button style={style.btn} fill pullRight onClick={this.onSubmit}>
                Enregistrer
              </Button>
            ) : (
              <Button style={style.btn} fill pullRight>
                Loading...
                <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>
              </Button>
            )}
            <div className="clearfix"></div>
          </Form>
        </ModalBody>
      );
    };
    return (
      <Modal show={show} onHide={onOpen}>
        <ModalHeader closeButton>{produit.nom}</ModalHeader>
        {renderBody(produit)}
      </Modal>
    );
  }
}
const style = {
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    float: "left",
    marginRight: "0.8em",
    borderColor: "#e4393f",
    width: "25%",
  },
};
