/* eslint-disable array-callback-return */
import { GET_COMMANDES, GET_CORDER_BY_ID, GET_ORDERS_PRODUCTS, GET_REALTIME_COMMANDES } from '../actions/type'
import firebase from '../../../firebase.js'
import { cmd_status } from 'variables/constants'
import moment from 'moment'
import _ from "lodash";


export const _getAllCommandes = () => async (dispatch) => {
  // console.log('fetching')
  let uid = JSON.parse(sessionStorage.getItem('id_user'))
  try {
    let data = []
    let db = firebase.firestore()
    let docs = await db
      .collection('commandes')
      .where('boutique_id', '==', uid)
      .get()
    const documents = await docs
    documents.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id })
    })
    dispatch({
      type: GET_COMMANDES,
      payload: data,
    })
  } catch (error) {
    console.log(error.code)
  }
}

export const getOrderById = (id) => {
  return async (dispatch) => {
    let db = firebase.firestore()
    console.log('id', id)
    let doc = await db.collection('commandes').doc(id).get()
    // console.log('commande', {...doc.data(), id: doc.id})
    dispatch({
      type: GET_CORDER_BY_ID,
      payload: { ...doc.data(), id: doc.id },
    })
  }
}

export const getOrdersProducts = () => async (dispatch) => {
  let uid = JSON.parse(sessionStorage.getItem('id_user'))
  let produits = [];
  let users_id = [];
  try {
    const db = firebase.firestore();
    let data = [];
    const docs = await db.collection("products").get();
    docs.forEach((doc) => produits.push({ ...doc.data(), id: doc.id }))

    const docs_cmd = await db.collection("commandes")
      .where("boutique_id", "==", uid)
    docs_cmd.onSnapshot(async (snshot) => {
      snshot.docChanges().forEach(async (change) => {
        // console.log("doc change=>", change.doc.data())
        if (change.type === 'added') {
          data.push({ ...change.doc.data(), id: change.doc.id })
          let content = change.doc.data();
          // console.log("added")
          if (!users_id.includes(content?.user_id)) {
            users_id.push(content.user_id);
          }
        }
        if (change.type === 'modified') {
          console.log("modified")
          // data.push({ ...change.doc.data(), id: change.doc.id })
          data = await refresh()
          console.log("refresh=>", data)
          let content = change.doc.data();
          if (!users_id.includes(content?.user_id)) {
            users_id.push(content.user_id);
          }
        }
        if (change.type === 'removed') {
          data.push({ ...change.doc.data(), id: change.doc.id })
          console.log("removed")
          let content = change.doc.data();
          if (!users_id.includes(content?.user_id)) {
            users_id.push(content.user_id);
          }
        }
        // console.log("content data=>", data)      
      })
      const docs_users = await firebase.firestore().collection("users").get();
      let users = [];
      docs_users.docs.forEach((f) => {
        users.push({ ...f.data(), id: f.id });
      });

      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        element.user = {};
        users.forEach((u) => {
          if (element.user_id === u.id) {
            element.user = u;
            return;
          }
        })
      }
      // console.log("type of data=>", data)
      data.forEach((c) => {
        c.photo = null;
        produits.map((prd) => {
          c?.produits.map((p) => {
            if (prd.id === p.id) {
              c.nom = prd.nom
              c.photo = prd.photo || null
            }
          })
        })
      })


      const en_attentes_livraison = data.filter(
        (c) => c.status === cmd_status.EN_ATTENTES_LIVRAISON
      );
      let attenteLiv = count(
        en_attentes_livraison,
        cmd_status.EN_ATTENTES_LIVRAISON
      );

      const en_cours = data.filter(
        (c) => c.status === cmd_status.EN_COURS
      );
      let enCours = count(en_cours, cmd_status.EN_COURS);

      const terminees = data.filter(
        (c) => c.status === cmd_status.TERMINEES
      );
      let finish = count(terminees, cmd_status.TERMINEES);

      const canceled = data.filter(
        (c) => c.status === cmd_status.CANCELED
      );
      let cancel = count(canceled, cmd_status.CANCELED);

      const en_attentes = data.filter(
        (c) => c.status === cmd_status.EN_ATTENTES
      );
      let enAttentes = count(en_attentes, cmd_status.EN_ATTENTES)

      dispatch({
        type: GET_ORDERS_PRODUCTS,
        payload: {
          in_progress_orders: { enCours },
          pending_orders: { attenteLiv },
          finish_orders: { finish },
          cancel_orders: { cancel },
          arrival_orders: { en_attentes, enAttentes },
        },
      })
    });
  } catch (error) {
    console.log(error.code)
  }

}

const refresh = async () => {
  let uid = JSON.parse(sessionStorage.getItem('id_user'));
  let data = [];
  try {
    const db = firebase.firestore();
    const docs_cmd = await db.collection("commandes")
      .where("boutique_id", "==", uid).get();
    docs_cmd.docs.forEach((c) => {
      data.push({ ...c.data(), id: c.id })
    })
  } catch (error) {
    console.log(error.code)
  }
  return data;
}

export const fetchRealTimeCmds = () => async (dispatch) => {
  let selectedDate = new Date()
  let db = firebase.firestore();
  let values = [];
  let commandes = [];
  let uid = JSON.parse(sessionStorage.getItem("id_user"));
  try {
    let docs = await db.collection("commandes");
    docs.onSnapshot(snapShot => {
      snapShot.docChanges().forEach(change => {
        if (change.type === 'added') {
          values.push({ ...change.doc.data(), id: change.doc.id })
        }
        for (let i = 0; i < values.filter((cons) => cons.boutique_id === uid).length; i++) {
          const element = values.filter((cons) => cons.boutique_id === uid)[i];
          if (
            moment(element.date).isSame(selectedDate, "year") &&
            moment(element.date).isSame(selectedDate, "month") &&
            moment(element.date).isSame(selectedDate, "day")
          ) {
            console.log("oui==>", moment(element.date).isSame(selectedDate, "day"))
            commandes.push(element);
          }
        }
        // if(change.type==='modified'){
        //   let index=
        // }
      })
      dispatch({
        type: GET_REALTIME_COMMANDES,
        payload: _.orderBy(commandes.map((item) => {
          item.date2 = item.interval?.debut;
          return item;
        }),
          ["date2", "asc"])
      })
    })
  } catch (error) {
    console.log(error.code)
  }
}

const getPrices = (products) => {
  let prices = 0.0;
  products.map((p) => {
    if (p.quantity && p.quantity > 0) {
      prices += p.quantity * p.prix;
    }
  });

  return prices.toFixed(2);
}

const count = (array, status) => {
  const items = array.filter((f) => f.status === status);
  let prix = 0;
  items.map((c) => (prix += parseFloat(getPrices(c.produits))));
  return {
    count: items.length,
    total: prix.toFixed(2),
    items,
  };
}
