import React, { Component } from "react";
import "./OrderItem.css";
import { Tag } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import { cmd_status } from "variables/constants";
import defaultImg from "assets/img/default-image.jpg";

export default class OrderItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      isloading: false,
    };
  }

  render() {
    const { item, order } = this.props;
    return (
      <tr>
        <td style={{ width: "100px", overflow: "hidden" }}>
          <div className="col-sm-4">
            <div className="item">
              <a href="!#">
                <span className="notify-badge">{item.quantity}</span>
                <img
                  src={item?.photo || defaultImg}
                  alt={item?.photo || "default photo"}
                  className="rounded-circle w-100"
                  style={{
                    minWidth: "80px",
                    height: "100px",
                    width: "100px",
                    borderRadius: "50%",
                  }}
                />
              </a>
            </div>
          </div>
        </td>
        <td style={{ minWidth: "200px" }} className="w-50 align-middle">
          <h5 className="text-capitalize text-danger">
            <strong>{item.nom}</strong>&nbsp;
            <strong className="text-danger">({item.prix} €)</strong>
          </h5>
          <h6 className="text-secondary"> {item.description} </h6>
        </td>
        <td className="align-middle" style={{ minWidth: "150px" }}>
          {cmd_status.EN_COURS === order?.status && item?.status === true ? (
            <Tag color="yellow">En cours </Tag>
          ) : cmd_status.EN_ATTENTES === order?.status &&
            item?.status !== true ? (
            <Tag color="red">Arrivée </Tag>
          ) : cmd_status.EN_ATTENTES_LIVRAISON === order?.status ? (
            <Tag color="orange">En attente</Tag>
          ) : cmd_status.TERMINEES === order?.status ? (
            <Tag color="green">terminée </Tag>
          ) : cmd_status.CANCELED === order?.status ? (
            <Tag color="red">annulée </Tag>
          ) : null}
        </td>
        <td
          className="align-middle"
          style={{ minWidth: "50px", cursor: "pointer" }}
        >
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input position-static"
              type="checkbox"
              id="blankCheckbox"
              name="status"
              value={item.id}
              defaultChecked={item.status === true}
              disabled={
                (order?.status !== cmd_status.EN_ATTENTES &&
                  item?.status === true) ||
                cmd_status.CANCELED === order?.status ||
                order?.status === cmd_status.TERMINEES
              }
              aria-label="..."
              onChange={this.props.handleUpdate}
            />
          </div>
        </td>
      </tr>
    );
  }
}
