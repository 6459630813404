import { GET_BOUTIQUE_BY_ID } from '../actions/type';
import firebase from '../../../firebase.js'
export const getBoutique = () => async (dispatch) => {
    console.log('fetching boutique data')
    let uid = JSON.parse(sessionStorage.getItem('id_user'));
    try {
        let db = firebase.firestore();
        await db.collection("boutiques").doc(uid).get()
            .then((doc) => {
                // console.log('doc', doc.data());
                return dispatch({
                    type: GET_BOUTIQUE_BY_ID,
                    payload: doc.data()
                })
            })
    } catch (error) {
        console.log(error.code)
    }
}

