import React, { Component } from "react";
import firebase from "../../firebase.js";
import {
  Grid,
  Row,
  Col,
  Alert,
  Modal,
  FormGroup,
  Form,
  ModalHeader,
  ModalBody,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import "../Boutiques/boutik_style.css";
import CustomButton from "components/CustomButton/CustomButton.jsx";
import {
  BootstrapTable,
  ClearSearchButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import { Button } from "@material-ui/core";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import { notifyErreur } from "utils.js";
import { toast } from "react-toastify";
import { boutique_status } from "variables/constants.jsx";
import { PAYMENT_ACCOUNT_STATUS } from "variables/constants.jsx";
import Card from "components/Card/Card.jsx";
import HomeSpinner from "views/HomeSpinner.jsx";
import { get_registrations } from "http/api.js";

export default class Boutiques extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      showComponent: false,
      show: false,
      editModal: false,
      details: {},
      item: {},
      modePaiement: [],
      showDismis: false,
      load: false,
      show_modal: false,
      inProgress: false,
      statutPayModal: false,
      abonnements: [],
      boutique_status: [
        { id: boutique_status.online, status: "active" },
        { id: boutique_status.offline, status: "desactiver" },
        { id: boutique_status.pending, status: "en cours" },
      ],
    };
  }

  componentDidMount() {
    this.fetchBoutiques();
  }

  fetchBoutiques = async () => {
    this.setState({ loading: true });
    try {
      let db = firebase.firestore();
      let data = [];
      let users = [];
      let docs = await db.collection("boutiques").get();
      docs.forEach((doc) => data.push({ ...doc.data(), id: doc.id }));
      let docs_users = await db.collection("users").get();
      docs_users.forEach((doc) => users.push({ ...doc.data(), id: doc.id }));
      data = data.map((b) => {
        b.user = {};
        for (const btq of users) {
          if (btq?.id === b?.id) {
            b.user = btq;
            break;
          }
        }
        return b;
      });

      let docs_ab = await db.collection("abonnements").get();
      let abonnements = [];
      docs_ab.forEach((doc) => abonnements.push({ ...doc.data(), id: doc.id }));

      data = data.map((b) => {
        b.abonnement = 0;
        for (const abon of abonnements) {
          if (abon?.id === b?.user?.abonnement) {
            b.abonnement = abon?.amount;
            break;
          }
        }
        return b;
      });
      const registrations = await get_registrations();
      console.log("registrations", registrations);
      data = data.map((b) => {
        if (b?.seller) {
          b.registration = registrations.find((r) => r.uuid === b.seller);
        }
        return b;
      });

      this.setState({
        data: data.filter((f) => f?.user?.onlinePayment !== undefined),
      });
    } catch (error) {
      console.log(error.message);
    }
    this.setState({ loading: false });
  };

  notify = () => {
    toast.success("Boutique supprimer avec success", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        style={{
          color: "#e0272d",
          borderColor: "#e0272d",
          border: "solid 0.2em",
        }}
        onClick={(e) => onClick()}
      />
    );
  };

  onOpen = (row, cell) => {
    this.setState((prevState) => {
      return { statutPayModal: !prevState.statutPayModal };
    });
    this.setState({ statutPayModal: !this.state.statutPayModal });
    if (cell) {
      if (this.state.statutPayModal === false) {
        this.setState({ item: {} });
      }
      this.setState({ item: cell });
    }
  };

  renderSeller = (row, cell) => {
    if (cell.seller) {
      return (
        <div
          style={style.aroundDiv}
          className={`bg_jaune`}
          onClick={(e) => this.props.history.push(`/admin/vendeur/${cell.id}`)}
        >
          <label style={style.displayLabel}>{"Détail"}</label>
        </div>
      );
    } else {
      return (
        <div
          style={style.aroundDiv}
          className={`bg_green`}
          onClick={(e) => this.props.history.push(`/admin/seller/${cell.id}`)}
        >
          <label style={style.displayLabel}>{"Ajouter"}</label>
        </div>
      );
    }
  };

  payment_statutFormatter = (row, cell) => {
    const getStatut = (cell) => {
      let statut = "";
      if (cell.user.payment_account_status == PAYMENT_ACCOUNT_STATUS.DEFAULT) {
        statut = "NC";
      }
      if (
        cell.user.payment_account_status ==
        String(PAYMENT_ACCOUNT_STATUS.EN_COURS)
      ) {
        statut = "En cours";
      }
      if (
        cell.user.payment_account_status ==
        String(PAYMENT_ACCOUNT_STATUS.ACTIVE)
      ) {
        statut = "Active";
      }
      return statut;
    };

    if (cell.user.onlinePayment === undefined && cell.abonnement > 0) {
      return <p style={{ whiteSpace: "normal" }}>{"Paiement non intégré"}</p>;
    } else if (cell.abonnement <= 0) {
      return (
        <p style={{ whiteSpace: "normal" }}>
          {"Abonnement 0 €, pas de paiement"}
        </p>
      );
    } else {
      return (
        <div
          style={style.aroundDiv}
          className={`${
            cell.user.payment_account_status ==
            PAYMENT_ACCOUNT_STATUS.DEFAULT ? (
              "bg_white"
            ) : cell.user.payment_account_status ==
              String(PAYMENT_ACCOUNT_STATUS.EN_COURS) ? (
              "bg_jaune"
            ) : cell.user.payment_account_status ==
              String(PAYMENT_ACCOUNT_STATUS.ACTIVE) ? (
              "bg_green"
            ) : !cell.user.onlinePayment &&
              cell.user.payment_account_status ==
                PAYMENT_ACCOUNT_STATUS.DEFAULT ? (
              <p>{"non supporte"}</p>
            ) : null
          }`}
          onClick={(e) => this.onOpen(row, cell)}
        >
          <label style={style.displayLabel}>{getStatut(cell)}</label>
        </div>
      );
    }
  };

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
    };

    const { data, loading } = this.state;
    if (loading) return <HomeSpinner />;

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12} sm={12}>
              <Card
                title={"boutiques"}
                ctTableFullWidth
                ctTableResponsive
                content={
                  <BootstrapTable
                    data={data.filter(
                      (f) =>
                        f?.abonnement > 0 &&
                        f?.user?.onlinePayment !== undefined
                    )}
                    pagination
                    keyField={"id"}
                    search={true}
                    version={"4"}
                    multiColumnSort={2}
                    condensed={true}
                    striped
                    options={options}
                  >
                    <TableHeaderColumn width="180" dataField="nom">
                      Nom Boutique
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      width="150"
                      dataFormat={(cell, row) => (
                        <p style={{ whiteSpace: "normal" }}>{row?.adresse}</p>
                      )}
                    >
                      Adresse Boutique
                    </TableHeaderColumn>
                    <TableHeaderColumn width="150" dataField="phone">
                      Phone
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      width="110"
                      dataFormat={(cell, row) => (
                        <p style={{ whiteSpace: "normal" }}>{`${
                          row?.abonnement + " €"
                        }`}</p>
                      )}
                    >
                      Abonnement
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      width="120"
                      dataFormat={this.payment_statutFormatter}
                    >
                      Paiement
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      width="120"
                      dataFormat={(cell, row) => {
                        if (row?.registration) {
                          return (
                            <p style={{ textAlign: "center" }}>
                              {row.registration.status}
                            </p>
                          );
                        }
                        return <p style={{ textAlign: "center" }}>-</p>;
                      }}
                    >
                      statut de la demande d'enrôlement
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      width="120"
                      dataFormat={this.renderSeller}
                    >
                      Compte vendeur
                    </TableHeaderColumn>
                  </BootstrapTable>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const style = {
  btn: { marginLeft: "1em" },
  circle_icon: {
    display: " inline-block",
    borderRadius: "60px",
    boxShadow: "0px 0px 2px currentColor",
    margin: "0em -1em",
    color: "rgb(233, 43, 51)",
    border: "solid 0.1rem",
    background: "rgb(247, 247, 248) none repeat scroll 0% 0%",
    fontSize: "1.7em",
  },
  title: {
    borderBottom: "0.2rem solid",
  },
  label: {
    fontSize: "1.5rem",
  },
  displayLabel: {
    marginTop: "6px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "900",
    color: "black",
    cursor: "pointer",
  },
  aroundDiv: {
    borderColor: "#007bff",
    borderRadius: "35px",
    border: "2px solid rgba(18, 19, 19, 0.75)",
    width: "111px",
    height: "35px",
  },
};
