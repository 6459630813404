import React, { Component } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import FormInputs from "components/FormInputs/FormInputs";
import Card from "components/Card/Card";
import firebase from "../../firebase.js";
import defaultImg from "../../assets/img/default-image.jpg";
import Switch from "react-switch";
import { getBoutique } from "../../reducers/boutique/actions/boutiqueAction";
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Row,
} from "react-bootstrap";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { boutique_status } from "variables/constants";
import CheckBoxGroup from "components/CheckBoxGroup/CheckBoxGroup";
import MaBoutique from "./MaBoutique.jsx";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  // geocodeByPlaceId,
} from "react-places-autocomplete";
import { toDayDateTime } from "utils.js";
import { connect } from "react-redux";

toast.configure();
const storage = firebase.storage();
// export const SERVICE = "Services & Loisirs";
export const SERVICE = "Commerces - Services - Loisirs";
// export const PF = "P’tite Faim";
export const PF = "P’tite faim - Restauration";
export const BE = "Bien être";
export const LS = "Santé";

class EditBoutique extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        categorie: "Services & Loisirs",
        adresse: "",
        ville: "",
        descriptionCourte: "",
        nom: "",
        phone: "",
        drigeant: "",
        numSiret: "",
        description: "",
        complementAdresse: "",
        codePostal: "",
      },
      isRedirect: false,
      data: [],
      pay_modes: [
        { id: 1, value: "chèques", isChecked: false },
        { id: 2, value: "Ticket resto", isChecked: false },
        { id: 3, value: "Carte Bancaire", isChecked: false },
        { id: 4, value: "Especes", isChecked: false },
      ],
      load: false,
      modePaiement: [],
      categorys: [
        { id: 1, nom: SERVICE },
        { id: 2, nom: PF },
        { id: 3, nom: BE },
        { id: 4, nom: LS },
      ],
      boutique_status: [
        { id: 1, status: boutique_status.online },
        { id: 2, status: boutique_status.offline },
        { id: 3, status: boutique_status.pending },
      ],
      file: null,
      url: "",
      image: "",
      selected: [],
      placeAdress: "",
      errors: {},
    };
    this.renderLocationInput = this.renderLocationInput.bind(this);
  }

  componentWillMount() {
    // this.props.getBoutique();
    // console.log("props=>", this.props.userBoutiq);
  }

  UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      if (nextProps?.userBoutiq) {
        console.log("will receive", nextProps?.userBoutiq);
        let { fields } = this.state;
        fields = nextProps?.userBoutiq;
        this.setState({
          fields,
          selected: nextProps?.userBoutiq?.modePaiement,
        });
      }
    }
  }

  componentDidMount() {
    this.props.getBoutique();
  }

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields: fields });
  };

  handleAllChecked = (event) => {
    let { pay_modes, selected } = this.state;
    pay_modes.forEach((mode_p) => {
      mode_p.isChecked = event.target.checked;
      let index = selected.findIndex((f) => f === mode_p?.value);
      if (index === -1) {
        selected.push(mode_p.value);
      } else return;
    });
    this.setState({ selected }, () => console.log(selected));
  };

  handleCheckChieldElement = (event, value) => {
    let { selected } = this.state;
    if (event.target.checked) {
      selected.push(value);
    } else {
      selected = selected.filter((f) => f !== value);
    }
    this.setState({ selected });
  };
  notify = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
    setTimeout(() => {
      this.setState({ isRedirect: true });
    }, 4000);
  };

  onFileChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    console.log(file.name);
    console.log("test");
    this.setState({ file: file });
  };

  async uploadImage(data) {
    let { file } = this.state;
    if (file !== null) {
      const storageRef = storage.ref(`boutiques/`);
      const fileRef = storageRef.child(file?.name);
      await fileRef.put(file);
      this.setState({ url: await fileRef.getDownloadURL() });
      data.photo = this.state.url;
    }
    return data;
  }

  onEditBoutik = async (e) => {
    e.preventDefault();
    this.setState({ load: true });
    let errors = {};
    let { fields, file } = this.state;
    let { selected } = this.state;
    fields.modePaiement = selected;
    fields.created_at = toDayDateTime();
    fields.presentationDomicile = fields?.presentationDomicile
      ? fields?.presentationDomicile
      : false;
    let data = fields;
    console.log("send data", data);
    let uid = JSON.parse(sessionStorage.getItem("id_user"));
    if (fields?.descriptionCourte.trim().length === 0) {
      errors["descriptionCourte"] = "champs description courte est obligatoire";
      // this.setState({ errors: errors });
    }
    if (fields?.nom.trim().length === 0) {
      errors["nom"] = "le champs nom est obligatoire";
      // this.setState({ errors: errors }, () =>
      //   console.log("nom=>", this.state.errors)
      // );
    }
    if (fields?.phone.trim().length === 0) {
      errors["phone"] = "le champs phone est obligatoire";
      // this.setState({ errors: errors });
    }
    if (fields?.drigeant.trim().length === 0) {
      errors["drigeant"] = "le champs dirigeant est obligatoire";
      // this.setState({ errors: errors });
    }
    if (fields?.description.trim().length === 0) {
      errors["description"] = "le champs description est obligatoire";
      // this.setState({ errors: errors });
    } else if (fields?.adresse.trim().length === 0) {
      errors["adresse"] = "le champs adresse est obligatoire";
      // this.setState({ errors: errors });
    }
    if (fields?.numSiret.trim().length === 0) {
      errors["numSiret"] = "le champs numéro siret est obligatoire";
      // this.setState({ errors: errors });
    }
    if (fields?.codePostal.trim().length === 0) {
      errors["codePostal"] = "le champs code postal est obligatoire";
      // this.setState({ errors: errors });
    }
    if (this.state.selected.length === 0) {
      errors["modePaiement"] = "le mode de paiement est obligatoire";
      // this.setState({ errors: errors });
    }
    if (Object.keys(errors).length !== 0) {
      this.setState({ errors: errors, load: false });
      return;
    }

    //  else {
    try {
      const db = firebase.firestore();
      let boutik = await db.collection("boutiques").doc(uid);
      const doc = await boutik.get();
      if (doc.exists) {
        if (file !== null) {
          await this.uploadImage(data);
        }
        boutik.update(data);
        this.setState({ load: !this.state.load });
        this.notify("Modification avec success");
        setTimeout(() => {
          this.props.history.push("/admin/maboutique");
        }, 2000);
        console.log("update");
      } else {
        if (file !== null) {
          await this.uploadImage(data);
        }
        boutik.set(data);
        this.setState({ load: !this.state.load });
        this.notify("Boutique ajoutee avec success");
        console.log("set");
      }
    } catch (error) {
      this.setState({ load: !this.state.load });
      console.log(error.message);
      toast.error("Erreur lors de l'ajout, pas de connexion internet", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    }
    // }
    // this.setState({ load: false }, () =>
    //   console.log("erreuurs=>", this.state.errors)
    // );
  };

  onBack = (e) => {
    e.preventDefault();
    console.log("ici");
    this.props.history.push(`/admin/maboutique`);
  };

  onPlaceChange = (placeAdress) => {
    let fields = this.state.fields;
    fields.adresse = placeAdress;
    this.setState({ fields }, () =>
      console.log("fields", this.state.fields.adresse)
    );
  };

  handleSelect = (address, palceId, suggestion) => {
    console.log("handle select=>", suggestion);
    let fields = this.state.fields;
    console.log("test addresse", address);

    // geocodeByPlaceId(palceId).then((res) => console.log("handle select", res));
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        console.log("Success", latLng);
        if (suggestion) {
          fields.latitude = latLng["lat"];
          fields.longitude = latLng["lng"];
          fields.adresse = suggestion?.description;
          let str = String(suggestion.description).split(",");
          fields.ville = str?.length === 3 ? str[1] : str[0];
          this.setState(
            { placeAdress: suggestion?.formattedSuggestion?.mainText, fields },
            () => console.log(this.state.fields)
          );
        }
        this.setState({ fields });
      })
      .catch((error) => console.error("Error", error));
  };

  renderLocationInput() {
    const onError = (status, clearSuggestions) => {
      console.log("Google Maps API returned error with status: ", status);
      clearSuggestions();
    };
    return (
      <PlacesAutocomplete
        value={this.state.fields.adresse || ""}
        onError={onError}
        onChange={this.onPlaceChange}
        onSelect={(address, palceId, suggestion) =>
          this.handleSelect(address, palceId, suggestion)
        }
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Adresse",
                className: "form-control",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                console.log("suggestion=>", suggestion);
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                    };
                return (
                  <div
                    key={index}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <strong style={{ color: "#f60404ba", fontWeight: "normal" }}>
          <i className="fas fa-exclamation-circle" /> {this.state.errors[field]}
        </strong>
      );
    }
  }

  render() {
    // console.log("OKK=>", this.props.userBoutiq);
    if (this.state.isRedirect) {
      return <MaBoutique />;
    }

    let { load, categorys, fields } = this.state;
    console.log("categorie", fields?.categorie);

    let renderCategorys = categorys?.map((item, i) => {
      const selected = fields?.categorie;
      console.log("selected", selected);
      return (
        <option
          // defaultValue={selected}
          placeholder="categorie"
          value={item.nom}
          key={item.id}
          id={item.id}
        >
          {item.nom}
        </option>
      );
    });
    return (
      <React.Fragment>
        <div className="content">
          <Grid fluid>
            <Col md={12}>
              <Card
                title="Nouvelle Boutique"
                content={
                  <React.Fragment>
                    <ArrowBackSharpIcon
                      style={{ cursor: "pointer" }}
                      fontSize={"large"}
                      onClick={this.onBack}
                    />
                    <FormGroup>
                      <img
                        src={fields?.photo ? fields?.photo : defaultImg}
                        width="200"
                        height="230"
                        alt="description"
                      />
                      <input
                        type="file"
                        onChange={this.onFileChange}
                        name="image"
                        label="Image"
                      />
                    </FormGroup>
                    <Form>
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Nom de ma boutique",
                            type: "text",
                            bsClass: `form-control ${
                              this.hasErrorFor("nom") ? "is-invalid" : ""
                            }`,
                            placeholder: "Nom de ma boutique",
                            name: "nom",
                            value: fields?.nom || "",
                            onChange: this.handleChange,
                            error: this.renderErrorFor("nom"),
                          },
                          {
                            label: "Nom du dirigeant",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Nom du dirigeant",
                            name: "drigeant",
                            value: fields?.drigeant || "",
                            onChange: this.handleChange,
                            error: this.renderErrorFor("drigeant"),
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Numero telephone",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Numero telephone",
                            name: "phone",
                            value: fields?.phone || "",
                            onChange: this.handleChange,
                            error: this.renderErrorFor("phone"),
                          },
                          {
                            label: "Numéro siret",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Numéro siret (RNA pour association)",
                            name: "numSiret",
                            value: fields?.numSiret || "",
                            onChange: this.handleChange,
                            error: this.renderErrorFor("numSiret"),
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Description courte",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Description courte (3 mots cles)",
                            name: "descriptionCourte",
                            value: fields?.descriptionCourte || "",
                            onChange: this.handleChange,
                            error: this.renderErrorFor("descriptionCourte"),
                          },
                        ]}
                      />
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <ControlLabel>Description détaillée</ControlLabel>
                            <textarea
                              className="form-control"
                              name="description"
                              type="text"
                              value={fields?.description || ""}
                              placeholder="Description détaillée"
                              onChange={this.handleChange}
                            />
                            {this.renderErrorFor("description")}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <ControlLabel>Adresse</ControlLabel>
                            {this.renderLocationInput()}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <ControlLabel>Complément adresse</ControlLabel>
                            <input
                              className="form-control"
                              name="complementAdresse"
                              value={fields?.complementAdresse || ""}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormInputs
                        ncols={["col-md-4", "col-md-4", "col-md-4"]}
                        properties={[
                          {
                            label: "Code Postal",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Code Postal",
                            name: "codePostal",
                            value: fields?.codePostal || "",
                            onChange: this.handleChange,
                            error: this.renderErrorFor("codePostal"),
                          },
                          {
                            label: "Latitude",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "Latitude",
                            name: "latitude",
                            value: fields?.latitude || 0,
                            onChange: this.handleChange,
                          },
                          {
                            label: "Longitude",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "Longitude",
                            name: "longitude",
                            value: fields?.longitude || 0,
                            onChange: this.handleChange,
                          },
                        ]}
                      />
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <ControlLabel>Ville</ControlLabel>
                            <input
                              className="form-control"
                              name="complementAdresse"
                              value={fields?.ville || ""}
                              disabled={true}
                              // onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col md={6}>
                                <ControlLabel>Prestaion domicile</ControlLabel>
                              </Col>
                              <Col
                                md={6}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <FormGroup>
                                  <Switch
                                    onChange={(value) => {
                                      console.log(value);
                                      let { fields } = this.state;
                                      if (value === false) {
                                        fields.presentationDomicile = value;
                                      } else {
                                        fields.presentationDomicile = value;
                                      }
                                      this.setState({ fields }, () =>
                                        console.log(
                                          "=>prestationDom",
                                          fields?.presentationDomicile
                                        )
                                      );
                                    }}
                                    checked={
                                      fields?.presentationDomicile || false
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>Category</ControlLabel>
                        <FormControl
                          componentClass="select"
                          placeholder="select"
                          value={fields?.categorie}
                          onChange={(e) => {
                            let { fields } = this.state;
                            fields.categorie = e.target.value;
                            this.setState({ fields }, console.log(fields));
                          }}
                        >
                          {renderCategorys}
                        </FormControl>
                      </FormGroup>
                      <FormGroup>
                        <input
                          type="checkbox"
                          onClick={this.handleAllChecked}
                          value="checkedall"
                        />{" "}
                        Tous cocher / Tous décocher
                        <ul style={{ listStyle: "none" }}>
                          {this.state.pay_modes.map((mode_p, i) => {
                            let check = this.state.selected?.includes(
                              mode_p.value
                            );
                            // console.log("selectde=>", this.state.selected);
                            return (
                              <CheckBoxGroup
                                check={check}
                                key={i}
                                handleCheckChieldElement={
                                  this.handleCheckChieldElement
                                }
                                {...mode_p}
                              />
                            );
                          })}
                        </ul>
                        {this.renderErrorFor("modePaiement")}
                      </FormGroup>
                      <div className="row">
                        <Col md={12}>
                          {!load ? (
                            <Button
                              style={style.btn}
                              fill
                              onClick={this.onEditBoutik}
                            >
                              Ajouter
                            </Button>
                          ) : (
                            <Button style={style.btn} fill>
                              Chargement...
                              <i
                                className="fa fa-spin fa-spinner"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          )}{" "}
                          <Button
                            onClick={this.onBack}
                            style={style.cancel}
                            fill
                          >
                            Annuler
                          </Button>
                        </Col>
                      </div>
                    </Form>
                  </React.Fragment>
                }
              />
            </Col>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userBoutiq: state.boutique.boutiq,
});

export default connect(mapStateToProps, { getBoutique })(EditBoutique);

const style = {
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    float: "right",
    marginRight: "0.8em",
    borderColor: "#e4393f",
    width: "25%",
  },
  cancel: {
    backgroundColor: "rgb(205, 205, 205)",
    float: "right",
    marginRight: "0.8em",
    borderColor: "rgb(205, 205, 205)",
    width: "25%",
  },
};
