import SignUp from "views/AccountAdmin/SignUp.jsx";
import Bordereau from "views/Bordereau/Bordereau.jsx";
// import ItemBordereau from "views/Bordereau/ItemBordereau.jsx";
import List_Com from "views/Commercants/List_Com.jsx";
import Contrat from "views/Contrat/Contrat.jsx";

/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import Messages from "views/Messages/Messages.jsx";

import Products from "views/Products/Products.jsx";
import Sondage from "views/Sondage/Sondage.jsx";

import Boutiques from "./views/Boutiques/Boutiques.jsx";

import OnBoarding from "./views/onboarding/onboarding";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/comptes",
    name: "Comptes",
    icon: "pe-7s-users",
    component: List_Com,
    layout: "/admin",
  },
  {
    path: "/CGUS",
    name: "Condition Generale",
    icon: "fas fa-handshake",
    component: Contrat,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Produits",
    icon: "pe-7s-menu",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/bordereau-ville",
    name: "Bordereau",
    icon: "pe-7s-menu",
    component: Bordereau,
    layout: "/admin",
  },
  {
    path: "/boutiques",
    name: "Boutiques",
    icon: "fa fa-store",
    component: Boutiques,
    layout: "/admin",
  },
  {
    path: "/sondages",
    name: "Sondages",
    icon: "pe-7s-note2",
    component: Sondage,
    layout: "/admin",
  },
  {
    path: "/create-compte-admin",
    name: "Admin",
    icon: "pe-7s-add-user",
    component: SignUp,
    layout: "/admin",
  },
  {
    path: "/messages",
    name: "Messages",
    icon: "pe-7s-mail",
    component: Messages,
    layout: "/admin",
  },
];

const dashboardRoutes = [...routes];

export default dashboardRoutes;

export function getRoutes() {
  const isAdmin = sessionStorage.getItem("is_admin");
  if (isAdmin) {
    return [
      ...routes,
      {
        path: "/on-boarding",
        name: "API ON BOARDING",
        icon: "fa fa-credit-card fa-w-18 fa-1x",
        component: OnBoarding,
        layout: "/admin",
      },
    ];
  }

  return routes;
}
