import Card from "components/Card/Card";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import Button from "../../components/CustomButton/CustomButton.jsx";
import Maps from "views/Maps";
// import TestMap from "views/TestMap";
import defaultImg from "./../../assets/img/default-image.jpg";
import firebase from "../../firebase.js";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomRoundedIcon from "@material-ui/icons/RoomRounded";
import Brightness1RoundedIcon from "@material-ui/icons/Brightness1Rounded";
import WatchLaterRoundedIcon from "@material-ui/icons/WatchLaterRounded";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import "moment/locale/fr";
// import BoutiqueForm from "../Boutiques/BoutiqueForm.jsx";
import { getTime } from "../../utils";
import Abonnement from "./Abonnement/Abonnement.jsx";
import LoadSpinner from "views/LoadSpinner.jsx";
import { connect } from "react-redux";
import { getBoutique } from "reducers/boutique/actions/boutiqueAction";
// import CreateBoutique from "./CreateBoutique.jsx";
// import CardHoraire from "components/CardHoraire/CardHoraire.jsx";
class MaBoutique extends Component {
  state = {
    data: [],
    show: false,
    showComponent: false,
    loading: false,
    edit: false,
    showHours: false,
    item: {},
  };

  componentDidMount() {
    this._getMyBoutique();
    this._getInfo();
    this.props.getBoutique();
  }

  _getMyBoutique = async () => {
    this.setState({ loading: true });
    try {
      let db = firebase.firestore();
      let data = [];
      let docs = await db.collection("boutiques").get();
      docs.forEach((doc) => data.push({ ...doc.data(), id: doc.id }));
      let uid = JSON.parse(sessionStorage.getItem("id_user"));
      this.setState({
        data: data.filter((f) => f.id === uid),
        show: true,
        loading: false,
      });
    } catch (error) {
      this.setState({ loading: false });
      console.log(`error response ${error}`);
    }
  };

  _getInfo = async () => {
    try {
      let db = firebase.firestore();
      let item = {};
      let uid = JSON.parse(sessionStorage.getItem("id_user"));
      let doc = await db.collection("boutiques").doc(uid).get();
      window.localStorage.setItem("id_boutique", uid);
      console.log("doc", doc.data());
      this.setState({ item: item });
    } catch (error) {
      console.log(`error response ${error}`);
    }
  };
  onCreateOpen = () => {
    if (this.props.userBoutiq === undefined) {
      this.props.history.push("/admin/monabonnement");
    } else {
      this.props.history.push("/admin/createboutique");
    }
  };
  // _onButtonClick = () => {
  //   this.setState((prevState) => {
  //     return { showComponent: !prevState.showComponent };
  //   });
  //   this.fetchBoutiques();
  // };
  showEdit = () => {
    this.setState((prevState) => {
      return { edit: !prevState.edit };
    });
  };

  showHoraires = () => {
    this.props.history.push("/admin/maboutique/horaires");
  };

  showParametres = () => {
    this.props.history.push("/admin/maboutique/parametres");
  };

  showDetails = (btq) => {
    console.log("btq=>", btq);
    this.props.history.push(`/admin/edit/${btq.id}`);
  };

  render() {
    console.log("maboutique=>", this.state.data[0]);
    const { data } = this.state;
    console.log("data", data);
    var date = moment().format("dddd");
    date = date[0].toUpperCase();
    let horaires =
      data[0]?.horaires?.filter((f) => f.name[0].toUpperCase() === date) || [];
    console.log("horaires=>", horaires[0]?.isClosed);

    if (this.state.loading) {
      return (
        <div style={{ minHeight: "80vh" }}>
          <LoadSpinner />
        </div>
      );
    }
    const cardInfo = () => {
      return (
        <Col md={6}>
          <Card
            content={
              <>
                {data.map((b, index) => (
                  <React.Fragment key={index}>
                    <p>
                      <PhoneIcon /> {data[0]?.phone || ""}
                    </p>
                    <p>
                      <span>
                        <RoomRoundedIcon />{" "}
                      </span>
                      <span>{`${data[0]?.ville}`}</span>
                    </p>
                    <p>
                      <span>
                        {data[0]?.status === "pending" ? (
                          <>
                            <Brightness1RoundedIcon
                              style={{
                                color: "rgba(242, 118, 8, 0.86)",
                              }}
                            />
                            <span>En attente de diffusion</span>
                          </>
                        ) : data[0]?.status === "online" ? (
                          <>
                            <Brightness1RoundedIcon
                              style={{
                                color: "rgba(39, 201, 17, 0.788)",
                              }}
                            />
                            <span>En ligne</span>
                          </>
                        ) : (
                          <>
                            <Brightness1RoundedIcon
                              style={{ color: "#e52c32" }}
                            />
                            <span>Hors ligne</span>
                          </>
                        )}
                      </span>
                    </p>

                    <Row>
                      <Col md={1} sm={1}>
                        <WatchLaterRoundedIcon />
                      </Col>
                      {horaires?.length ? (
                        <Col md={4} sm={4}>
                          <p style={style.dayHoraire}>
                            {horaires[0]?.name}{" "}
                            {!horaires[0]?.isClosed ? (
                              <span>
                                {" "}
                                {getTime(horaires[0]?.midi?.debut)}
                                {" - "}
                                {getTime(horaires[0]?.midi?.fin)}
                              </span>
                            ) : (
                              <span>{"Fermé"}</span>
                            )}
                          </p>
                        </Col>
                      ) : (
                        <Col md={4} sm={4}>
                          <p>Pas d'horaires</p>
                        </Col>
                      )}
                    </Row>
                  </React.Fragment>
                ))}
              </>
            }
          />
        </Col>
      );
    };

    const noBoutiq = () => {
      return (
        <Card
          content={
            <>
              <h3 style={{ textAlign: "center" }}>
                Vous n'avez pas encore créé votre boutique
              </h3>
              <Col mdOffset={4} smOffset={4} xsOffset={4}>
                <Button style={style.btn} onClick={this.onCreateOpen}>
                  Créer ma boutique
                </Button>
              </Col>
            </>
          }
        />
      );
    };

    const CardBoutique = () => {
      return (
        <React.Fragment>
          {data.length === 0 ? (
            noBoutiq()
          ) : (
            <Card
              title={data[0]?.nom || ""}
              content={
                <>
                  <Row>
                    <Col md={6} sm={4} xs={3}>
                      <img
                        alt="image_boutique"
                        width="200"
                        height="230"
                        src={data[0].photo ? data[0].photo : defaultImg}
                      ></img>
                    </Col>
                    {cardInfo()}
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col md={4} sm={3} xs={3}>
                      <Button
                        title="Modifier"
                        fill
                        style={style.circleBtn}
                        onClick={(e) => this.showDetails(data[0])}
                      >
                        <EditIcon />
                      </Button>
                    </Col>
                    <Col md={4} sm={3} xs={3}>
                      <Button
                        title="Horaires"
                        fill
                        style={style.blackBtn}
                        onClick={this.showHoraires}
                      >
                        Horaires
                      </Button>
                    </Col>
                    <Col md={4} sm={3}>
                      <Button
                        title="Paramètres"
                        fill
                        style={style.blackBtn}
                        onClick={this.showParametres}
                      >
                        Paramètres
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      {this.state.show ? (
                        <Maps
                          latitude={data[0]?.latitude}
                          longitude={data[0]?.longitude}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </>
              }
            />
          )}
        </React.Fragment>
      );
    };

    return (
      <div className="content">
        <Grid fluid>
          {
            this.state.showComponent ? (
              <Abonnement
                back={this.onCreateOpen}
                pathname={this.props.location?.pathname}
              />
            ) : (
              <CardBoutique />
            )
            // : (
            //   <CreateBoutique
            //     showEdit={this.showEdit}
            //     item={this.state?.data[0]}
            //   />
            // )
          }
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userBoutiq: state.boutique.boutiq,
});

export default connect(mapStateToProps, { getBoutique })(MaBoutique);

const style = {
  dayHoraire: {
    color: "black",
    backgroundImage: "none",
    borderColor: "#007bff",
    borderRadius: "35px",
    border: "1px solid rgba(18, 19, 19, 0.75)",
    width: "155px",
    height: "30px",
    marginRight: "1rem",
  },
  circleBtn: {
    width: "56px",
    height: "56px",
    textAlign: "center",
    padding: "6px 0",
    fontSize: "12px",
    lineHeight: "1.428571429",
    borderRadius: "30px",
    backgroundColor: "#f83d48",
    borderColor: "#f83d48",
    // float: "right",
    marginBottom: "0.3em",
  },
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    borderColor: "#e4393f",
    color: "#f3f3f3",
  },
  blackBtn: {
    width: "auto",
    height: "56px",
    textAlign: "center",
    backgroundColor: "black",
    borderColor: "black",
    fontSize: "16px",
    lineHeight: "1.428571429",
    marginBottom: "0.3em",
  },
};
