import React from "react";
import { Grid, Row } from "react-bootstrap";
import RotateLoader from "react-spinners/RotateLoader";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
};

export default function LoadSpinner() {
  return (
    <div>
      <div className="wrapper">
        <Grid fluid>
          <Row>
            <div style={style} className="col-md-offset-1">
              <RotateLoader />
            </div>
          </Row>
        </Grid>
      </div>
    </div>
  );
}
