import React, { Component } from "react";
import { FormGroup, FormControl, Row } from "react-bootstrap";

function FieldGroup({ label, ...props }) {
  return (
    <FormGroup>
      {/* <ControlLabel>{label}</ControlLabel> */}
      <FormControl
        {...props}
        bsSize="large"
        componentClass="textarea"
        rows="7"
      />
    </FormGroup>
  );
}

export default class TextAreaInput extends Component {
  render() {
    var row = [];
    for (var i = 0; i < this.props.ncols.length; i++) {
      row.push(
        <div key={i} className={this.props.ncols[i]}>
          <FieldGroup {...this.props.properties[i]} />
        </div>
      );
    }
    return <Row>{row}</Row>;
  }
}
