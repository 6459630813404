import Card from "components/Card/Card.jsx";
import React, { Component } from "react";
import {
  Alert,
  Col,
  ControlLabel,
  FormGroup,
  Grid,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "react-bootstrap";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import LoadSpinner from "views/LoadSpinner.jsx";
import firebase from "../../../firebase.js";
import Button from "components/CustomButton/CustomButton.jsx";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditProduit from "./EditProduit.jsx";
import CardZero from "components/CardZero/CardZero.jsx";
import { toast } from "react-toastify";
import "./AutoComplete/_style.css";
import { connect } from "react-redux";
import { _getProductsByBoutique } from "reducers/products/actions/productAction.js";

// import "bootstrap/dist/css/bootstrap.min.css";

toast.configure();
class MesProduits extends Component {
  state = {
    data: [],
    spinner: false,
    showEdit: false,
    showModal: false,
    item: {},
    showDismis: false,
    load: false,
    userAb: {},
  };
  componentDidMount() {
    this._getInfo();
    // this._getMyboutik();
    this.props._getProductsByBoutique();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.item !== this.state.item) {
  //     console.log("prevState=>", prevState.item);
  //     console.log("item state=>", this.state.item);
  //     this._getInfo();
  //     return;
  //   }
  // }

  callBackFromParent = (data) => {
    this.setState({ data: data });
  };

  _getProduits = async (id_btq) => {
    console.log("get boutik");
    let { data } = this.state;
    try {
      let db = firebase.firestore();
      let doc = await db
        .collection("products")
        .where("boutique_id", "==", id_btq)
        .get();
      let store = await doc;
      store.forEach((doc) => {
        // console.log("store", doc.data(), doc.id);
        data.push({ ...doc.data(), id: doc.id });
      });
      this.setState({ data: data });
    } catch (error) {
      console.log(`error response ${error}`);
    }
  };

  _getMyboutik = async () => {
    // this.setState({ spinner: true });
    try {
      let db = firebase.firestore();
      let uid = JSON.parse(sessionStorage.getItem("id_user"));
      let doc = await db.collection("boutiques").doc(uid).get();
      // console.log("doc", doc.data());
      const id_btq = doc.id;
      // console.log("boutique_id", id_btq);
      if (id_btq !== undefined) {
        // console.log("boutique_id", id_btq);
        await this._getProduits(id_btq);
      }
    } catch (error) {
      // this.setState({ spinner: false });
      console.log(`error response ${error}`);
    }
  };

  _getInfo = async () => {
    try {
      let uid = JSON.parse(sessionStorage.getItem("id_user"));
      const db = firebase.firestore();
      await db
        .collection("users")
        .doc(uid)
        .get()
        .then((docRef) => {
          // this.setState({ user: docRef.data() });
          this._getUserAbonnement(docRef.data().abonnement);
        });
    } catch (error) {
      console.log(error.code);
    }
  };

  _getUserAbonnement = async (id_ab) => {
    this.setState({ spinner: true });
    try {
      const db = firebase.firestore();
      let abonnements = [];
      await db
        .collection("abonnements")
        .get()
        .then((docs) => {
          docs.forEach((d) => {
            abonnements.push({ ...d.data(), id: d.id });
          });
          this.setState({
            userAb: abonnements.filter((a) => a.id === id_ab)[0],
            spinner: false,
          });
        });
    } catch (error) {
      this.setState({ spinner: false });
      console.log("error", error.code);
    }
  };

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        style={{
          color: "#e0272d",
          borderColor: "#e0272d",
          border: "solid 0.2em",
        }}
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  onRedirect = () => {
    this.props.history.push("/admin/addproduit");
  };

  onDetails = (row, cell) => {
    // console.log("row=>", cell);
    this.setState((prevState) => {
      return { showModal: !prevState.showModal };
    });
    if (cell) {
      let item = {
        boutique_id: cell?.boutique_id,
        categorie: cell?.categorie,
        created_at: cell?.created_at,
        description: cell?.description,
        durration: cell?.duration,
        id: cell?.id,
        nom: cell?.nom,
        prix: cell?.prix,
        product_type: cell?.product_type,
        stock: cell?.stock,
        type: cell?.type,
      };
      console.log("item row=>", item);
      this.setState({ item });
    }
  };

  onEdit = (row, cell) => {
    console.log("row edit=>", cell);
    // this.setState({ showEdit: !this.state.showEdit });
    this.setState((prevState) => {
      return { showEdit: !prevState.showEdit };
    });
    if (cell) {
      let item = {
        boutique_id: cell?.boutique_id,
        categorie: cell?.categorie,
        created_at: cell?.created_at,
        description: cell?.description,
        durration: cell?.durration,
        id: cell?.id,
        nom: cell?.nom,
        prix: cell?.prix,
        product_type: cell?.product_type,
        stock: cell?.stock,
        type: cell?.type,
        photo: cell?.photo || null,
      };
      // console.log("item row=>", item);
      this.setState({ item });
    }
  };

  onDelete = (row, cell) => {
    // console.log("boutik a supprimer==>", cell);
    this.setState((prevState) => {
      return { showDismis: !prevState.showDismis };
    });
    if (cell) {
      this.setState({ item: cell }, () =>
        console.log("boutik a supprimer==>", this.state.item)
      );
    }
  };

  notify = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };

  deleteProduit = async (e) => {
    e.preventDefault();
    let { item } = this.state;
    this.setState({ load: true });
    // console.log("item to delete==>", item);
    let data = item;
    try {
      const db = firebase.firestore();
      await db
        .collection("products")
        .doc(data?.id)
        .delete()
        .then((docRef) => {
          // console.log("succees delete");
          this.notify("Produit supprimer avec success");
          this.setState({ load: false, showDismis: false });
          let index = this.state.data.findIndex((f) => f?.id === data?.id);
          // console.log("index=>", index);
          let produits = this.state.data;
          let newTabprods = [];
          for (let i = 0; i < produits.length; i++) {
            const element = produits[i];
            if (index === 1) {
              if (element?.id === data?.id) {
                newTabprods = produits.splice(element, 1);
                // console.log("new array=>", newTabprods);
              }
            }
          }
          if (newTabprods.length !== 0) {
            this.setState({ data: newTabprods });
          }
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error.code);
      this.setState({ load: false });
    }
  };

  // componentWillReceiveProps() {}

  formatAction = (row, cell) => {
    return (
      <React.Fragment>
        <Row style={{ paddingRight: "inherit" }}>
          <Col md={4} sm={4} xs={4}>
            <button
              style={style.fontIcon}
              onClick={(e) => this.onEdit(row, cell)}
            >
              <EditIcon
                fontSize="large"
                style={{ color: "rgba(65, 159, 71, 0.92)" }}
              />
            </button>
          </Col>
          <Col md={4} sm={4} xs={4}>
            {" "}
            <button
              style={style.fontIcon}
              onClick={(e) => this.onDetails(row, cell)}
            >
              <VisibilityIcon
                fontSize="large"
                style={{ color: "rgba(16, 132, 235, 0.92)" }}
              />
            </button>
          </Col>
          <Col md={4} sm={4} xs={4}>
            {" "}
            <button
              style={style.fontIcon}
              onClick={() => this.onDelete(row, cell)}
            >
              <DeleteForeverIcon
                fontSize="large"
                style={{ color: "rgba(226, 41, 47, 0.92)" }}
              />
            </button>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  render() {
    const { spinner, item, showDismis, load, userAb } = this.state;
    // console.log("alors", data);
    console.log("products from props=>", this.props.prdtsBoutiq);
    let { prdtsBoutiq } = this.props;
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      noDataText: "Aucun Produit ajouté",
      btnGroup: this.createCustomButtonGroup,
    };

    let renderTable = (
      <React.Fragment>
        <BootstrapTable
          data={prdtsBoutiq}
          ref="table"
          keyField={"boutique_id"}
          pagination
          options={options}
          // search={true}
        >
          <TableHeaderColumn width="150" dataField="nom" dataSort>
            Nom Produit
          </TableHeaderColumn>
          <TableHeaderColumn width="150" dataField="categorie" dataSort>
            Categorie
          </TableHeaderColumn>
          <TableHeaderColumn
            width="150"
            dataField="description"
            // dataFormat={(cell, row) => (
            //   <p style={{ whiteSpace: "normal" }}>{row?.description}</p>
            // )}
            dataSort
          >
            Description
          </TableHeaderColumn>
          <TableHeaderColumn width="150" dataField="stock" dataSort>
            Quantite
          </TableHeaderColumn>
          <TableHeaderColumn width="150" dataField="prix" dataSort>
            Prix
          </TableHeaderColumn>
          <TableHeaderColumn width="150" dataField="product_type" dataSort>
            Type de produit
          </TableHeaderColumn>
          <TableHeaderColumn
            width="150"
            dataFormat={(row, cell) => this.formatAction(row, cell)}
          ></TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    );

    const editProd = {
      prod: item,
      // refresh: this._getMyboutik.bind(this),
      refresh: prdtsBoutiq,
    };

    if (spinner) {
      return (
        <div style={{ minHeight: "80vh" }}>
          <LoadSpinner />
        </div>
      );
    }

    if (showDismis) {
      return (
        <Alert
          color="primary"
          onDismiss={(e) => this.setState({ showDismis: false })}
        >
          <h4>Suppression Boutique</h4>
          <p>Voulez vous vraiment supprimer ce produit</p>
          {!load ? (
            <Button className="btn btn-danger" onClick={this.deleteProduit}>
              Supprimer
            </Button>
          ) : (
            <Button color="primary" className="btn btn-primary">
              {" "}
              Loading...
              <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>
            </Button>
          )}
          <span> or </span>
          <Button onClick={(e) => this.setState({ showDismis: false })}>
            Annuler
          </Button>
        </Alert>
      );
    }

    return (
      <div className="content">
        <RenderModal
          open={this.state.showModal}
          close={this.onDetails}
          item={item}
          test={[item]}
        />
        <EditProduit
          show={this.state.showEdit}
          onOpen={this.onEdit}
          {...editProd}
          callBackFromParent={this.callBackFromParent}
          data={prdtsBoutiq}
        />
        {userAb?.amount > 0 ? (
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Button
                  fill
                  pullRight
                  style={style.btn}
                  onClick={this.onRedirect}
                >
                  Ajouter un produit
                  <span className="pe-7s-plus" style={style.icon}></span>
                </Button>

                <Card
                  title="Produits"
                  content={<React.Fragment>{renderTable}</React.Fragment>}
                />
              </Col>
            </Row>
          </Grid>
        ) : (
          <Grid
            style={{
              maxWidth: "500px",
              margin: "auto",
              width: "100%",
              height: "100%",
              padding: "10px",
              maxHeight: "500px",
            }}
          >
            <Row>
              <Col>
                <CardZero
                  text={"Ajout de produit non disponible avec cet abonnement."}
                />
              </Col>
            </Row>
          </Grid>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    _getProductsByBoutique: () => dispatch(_getProductsByBoutique()),
  };
};

const mapStateToProps = (state) => ({
  prdtsBoutiq: state.products.productsItems,
});

export default connect(mapStateToProps, mapDispatchToProps)(MesProduits);

const RenderModal = (props) => {
  // console.log("props=>", props);
  const Body = (item) => {
    // console.log("item test=>", item);
    return (
      <ModalBody>
        {item?.test.map((p, i) => {
          return (
            <React.Fragment key={i}>
              <FormGroup>
                <ControlLabel>Nom Produit: {p?.nom}</ControlLabel>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Categorie: {p?.categorie}</ControlLabel>
              </FormGroup>{" "}
              <FormGroup>
                <ControlLabel>Description: {p?.description}</ControlLabel>
              </FormGroup>{" "}
              <FormGroup>
                <ControlLabel>Quantité: {p?.stock}</ControlLabel>
              </FormGroup>{" "}
              <FormGroup>
                <ControlLabel>Prix: {p?.prix}€</ControlLabel>
              </FormGroup>{" "}
              <FormGroup>
                <ControlLabel>Type de produit: {p?.product_type}</ControlLabel>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Type: {p?.type}</ControlLabel>
              </FormGroup>
            </React.Fragment>
          );
        })}
      </ModalBody>
    );
  };
  return (
    <Modal show={props?.open} onHide={props.close}>
      <ModalHeader closeButton>
        <h5>{props?.item?.nom}</h5>
      </ModalHeader>
      <Body {...props} />
    </Modal>
  );
};

const style = {
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    // float: "left",
    marginRight: "2em",
    marginTop: "1em",
    borderColor: "#e4393f",
    // width: "25%",
  },
  icon: {
    fontSize: "1.1em",
  },
  circle_icon: {
    display: " inline-block",
    borderRadius: "60px",
    boxShadow: "0px 0px 2px currentColor",
    margin: "0em -1em",
    color: "rgb(233, 43, 51)",
    border: "solid 0.1rem",
    background: "rgb(247, 247, 248) none repeat scroll 0% 0%",
    fontSize: "1.7em",
  },
  fontIcon: {
    backgroundColor: "white",
    borderColor: "white",
    display: "flex",
    border: "none",
  },
};
