import { cmd_status } from "variables/constants.jsx";
import firebase from "../../../firebase.js";
import { GET_NOTIFICATION } from "./types.js";
import _ from "lodash";
export const _getAllNotifations = () => async (dispatch) => {
  let uid = JSON.parse(sessionStorage.getItem("id_user"));
  try {
    let data = [];
    let noRead = [];
    let read = [];
    let db = firebase.firestore();
    let docs = await db
      .collection("notifications")
      .where("target_id", "==", uid);
    docs.onSnapshot((qs) => {
      qs.docChanges().forEach((change) => {
        if (
          [cmd_status.NEW_ORDER, cmd_status.ORDER_CANCELED_BY_CLIENT].includes(
            change.doc.data().type
          )
        ) {
          if (change.type === "added") {
            data.push({ ...change.doc.data(), id: change.doc.id });
          }
          if (change.type === "modified") {
            data.push({ ...change.doc.data(), id: change.doc.id });
          }
          if (change.type === "removed") {
            data.push({ ...change.doc.data(), id: change.doc.id });
          }
        }
      });
      // console.log("notif=>", data);
      const newData = _.orderBy(data, ["date"], "desc");
      read = newData.filter((data) => data.read === true);
      noRead = newData.filter((data) => data.read === false);

      dispatch({
        type: GET_NOTIFICATION,
        payload: { read, noRead },
      });
    });
  } catch (error) {
    console.log(error.code);
  }
};
