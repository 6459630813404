export const CATEGORIES = [
    "entrée",
    "Plat",
    "Dessert",
    "Boisson",
    "Formule",
    "Prestation",
];

export const TYPE = [
    "temps de préparation standard",
    "sans temps de préparation",
    "temps préparation non standard",
]

export const TYPE_PRODUIT = [
    "À emporter", "Prestation sur rendez-vous"
]

export const DURATION = ['1h', '1h30', '2h', '2h30', '3h', '3h30', '4h']




