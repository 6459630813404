import { GET_PRODUCTS, GET_PRODUCT_BY_ID, GET_PRODUCTS_BOUTIQUE } from "./type";
import firebase from '../../../firebase.js'

export const _getAllProducts = () => async (dispatch) => {
  //console.log('fetching')
  let uid = JSON.parse(sessionStorage.getItem('id_user'))
  try {
    let data = []
    let db = firebase.firestore();
    let docs = await db
      .collection("products")
      .where("boutique_id", "==", uid)
      .get();

    const documents = await docs
    documents.forEach((doc) => {
      //console.log("action get all cmd=>", doc.data());
      data.push({ ...doc.data(), id: doc.id })
      dispatch({
        type: GET_PRODUCTS,
        payload: data,
      })
    })
  } catch (error) {
    console.log(error.code)
  }
}

export const getProductById = (id) => {
  return async (dispatch) => {
    let db = firebase.firestore()
    console.log('id', id)
    let doc = await db.collection('products').doc(id).get()
    console.log('produit', doc.data())
    dispatch({
      type: GET_PRODUCT_BY_ID,
      payload: doc.data(),
    })
  }
}

export const _getProductsByBoutique = () => async (dispatch) => {
  console.log("ici")
  let db = firebase.firestore();
  let uid = JSON.parse(sessionStorage.getItem('id_user'))
  let data = [];
  try {
    let documentsCmd = await db
      .collection("products")
      .where("boutique_id", "==", uid);
    documentsCmd.onSnapshot((snapShot) => {
      snapShot.docChanges().forEach(f => {
        if (f.type === 'added') {
          // console.log("added")
          data.push({ ...f.doc.data(), id: f.doc.id })
        }
        if (f.type === 'modified') {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.id === f.doc.id) {
              data[i].stock = f.doc.data().stock
            }
          }
          // let index = data.findIndex(p => p.id === f.doc.id);
          // if (index !== -1) {
          //   data[index].stock = f.doc.data().stock
          //   // console.log("after set", data[index].stock)
          //   // return;
          // }
        }
        console.log("redux prd=>", data)
      })
      dispatch({
        type: GET_PRODUCTS_BOUTIQUE,
        payload: [...data]
      })
    });
  } catch (error) {
    console.log(error.code)
  }
}

// const getProducts = async (id_btq) => {
//   let db = firebase.firestore();
//   let data = []
//   try {
//     let documentsCmd = await db
//       .collection("products")
//       .where("boutique_id", "==", id_btq);
//     documentsCmd.onSnapshot((snapShot) => {
//       snapShot.docChanges().forEach(f => {
//         if (f.type === 'added') {
//           data.push({ ...f.doc.data(), id: f.id })
//         }
//         if (f.type === 'modified') {
//           data.push({ ...f.doc.data(), id: f.id })
//         }
//       })

//     });
//   } catch (error) {
//     console.log(error.code)
//   }
//   return data;
// }