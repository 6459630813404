import Card from "components/Card/Card";
import Button from "components/CustomButton/CustomButton.jsx";
import FormInputs from "components/FormInputs/FormInputs";
// import { app } from "firebase";
import React, { Component } from "react";
import {
  Col,
  Form,
  FormGroup,
  Grid,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { notifyErreur } from "utils.js";
import { notify } from "utils.js";
import firebase from "../../firebase.js";

const storage = firebase.storage();
export default class ItemBordereau extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lien: "",
      image: "",
      infos: [
        // { contenu: '', odre: 0 }
      ],
      fields: {},
      show: false,
      item: {},
      show_edit: false,
      load: false,
      file: null,
      url: "",
    };
    this.onEdit = this.onEdit.bind(this);
  }

  async componentDidMount() {
    let ville_doc = this.props.match.params?.ville;
    try {
      const db = firebase.firestore();
      await db
        .collection("villes")
        .doc(ville_doc)
        .get()
        .then((docRef) => {
          let data = docRef.data();
          console.log(data);
          this.setState({
            infos: data.infos,
            lien: data.lien,
            image: data.image,
          });
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  onOpen = () => {
    this.setState((prevState) => {
      return { show: !prevState.show };
    });
  };

  onEdit(item) {
    console.log("click");
    this.setState((prevState) => {
      return { show_edit: !prevState.show_edit };
    });
    if (item !== null || undefined) {
      console.log(item);
      this.setState({ item: item });
    }
  }

  onChange = (e) => {
    let { fields } = this.state;
    fields[e.target.name] = e.target.value;
    console.log(fields);
    this.setState({ fields });
  };

  onChangeEdit = (e) => {
    let { item } = this.state;
    item[e.target.name] = e.target.value;
    this.setState({ item }, () => console.log(item));
  };

  addInfo = (e) => {
    e.preventDefault();
    let { fields, infos } = this.state;
    infos.push(fields);
    console.log(this.state.infos);
    this.setState({ infos, show: false, fields: {} });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  onFileChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    console.log(file.name);
    this.setState({ file: file });
    // const storageRef = storage.ref(`villes/`);
    // const fileRef = storageRef.child(file.name);
    // await fileRef.put(file);
    // let image = await fileRef.getDownloadURL();
    // this.setState({ url: await fileRef.getDownloadURL() });
    // console.log("upload image file=>", file?.name);
    // console.log("upload url =>", this.state.url);
  };

  editInfo = (e) => {
    e.preventDefault();
    let { item, infos } = this.state;
    console.log("item=>", item);
    // infos.push(fields);
    infos = [...this.state.infos, item];
    this.setState({ infos: [...this.state.infos], show_edit: false });
  };

  send = async (e) => {
    e.preventDefault();
    let { file } = this.state;
    let ville_doc = this.props.match.params?.ville;
    this.setState({ load: !this.state.load });
    const data = {
      lien: this.state.lien,
      image: this.state.image,
      infos: this.state.infos,
    };
    console.log(data);
    if (Object.entries(data).length !== 0) {
      try {
        if (file !== null) {
          const storageRef = storage.ref(`villes/`);
          const fileRef = storageRef.child(file.name);
          await fileRef.put(file);
          this.setState({ url: await fileRef.getDownloadURL() });
          data.image = this.state.url;
        }
        const db = firebase.firestore();
        const store = db.collection("villes").doc(ville_doc);
        const doc = await store.get();
        if (doc.exists) {
          store.update(data);
          this.setState({ load: !this.state.load });
          notify();
        } else {
          store.set(data);
          notify();
          this.setState({ load: !this.state.load });
        }
      } catch (error) {
        console.log(error.message);
        notifyErreur();
        this.setState({ load: !this.state.load });
      }
    } else {
      this.setState({ load: !this.state.load });
      toast.error("Veuillez remplir les champs svp!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    }
  };

  render() {
    // console.log("item bordereau component", this.props);
    // console.log(this.state.infos)
    let { show, infos, show_edit } = this.state;
    const renderModal = (
      <Modal show={show} onHide={this.onOpen} backdrop={false}>
        <ModalHeader closeButton>Ajouter infos contenu</ModalHeader>
        <ModalBody>
          <Form>
            <div className="row">
              <div className="col-md-8">
                <label htmlFor="contenu" className="control-label">
                  Contenu
                </label>
                <textarea
                  name="contenu"
                  onChange={this.onChange}
                  placeholder="Contenu"
                  className="form-control"
                  rows="3"
                />
              </div>
            </div>
            <FormInputs
              ncols={["col-md-8"]}
              properties={[
                {
                  label: "Ordre",
                  type: "number",
                  bsClass: "form-control",
                  placeholder: "Ordre",
                  name: "ordre",
                  min: 0,
                  // value: this.state.item?.ordre || 0,
                  onChange: this.onChange,
                },
              ]}
            />
            <div className="row">
              <Col md={12}>
                <Button style={style.btn} fill onClick={this.addInfo}>
                  Ajouter
                </Button>
                <Button
                  style={style.btn}
                  fill
                  onClick={(e) => this.setState({ show: !show })}
                >
                  Annuler
                </Button>
              </Col>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    );

    const renderEdit = (
      <Modal show={show_edit} onHide={this.onEdit} backdrop={false}>
        <ModalHeader closeButton color="red">
          Modifier infos contenu
        </ModalHeader>
        <ModalBody>
          <Form>
            <div className="row">
              <div className="col-md-8">
                <label htmlFor="contenu" className="control-label">
                  Contenu
                </label>
                <textarea
                  name="contenu"
                  value={this.state.item?.contenu || ""}
                  onChange={this.onChangeEdit}
                  placeholder="Contenu"
                  className="form-control"
                  rows="3"
                />
              </div>
            </div>
            <FormInputs
              ncols={["col-md-8"]}
              properties={[
                {
                  label: "Ordre",
                  type: "number",
                  bsClass: "form-control",
                  placeholder: "Ordre",
                  name: "ordre",
                  min: 0,
                  value: this.state.item?.ordre || 0,
                  onChange: this.onChangeEdit,
                },
              ]}
            />
            <div className="row">
              <Col md={12}>
                <Button onClick={this.editInfo} style={style.btn} fill>
                  Modifier
                </Button>
                <Button
                  onClick={(e) => this.setState({ show_edit: !show_edit })}
                  style={style.btn}
                  fill
                >
                  Annuler
                </Button>
              </Col>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    );

    return (
      <div className="content">
        {renderModal}
        {renderEdit}
        {/* <ModalEdit onEdit={this.onEdit} show_edit={this.state.show_edit}/> */}
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={this.props.match.params?.ville}
                content={
                  <>
                    <Form>
                      <FormInputs
                        ncols={["col-md-12 col-sm-12"]}
                        properties={[
                          {
                            label: "Lien Commune",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Lien Boutique",
                            name: "lien",
                            value: this.state?.lien || "",
                            onChange: this.handleChange,
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-6 col-sm-6"]}
                        properties={[
                          {
                            label: "Image ville",
                            type: "file",
                            bsClass: "form-control",
                            placeholder: "Image ville",
                            name: "image",
                            onChange: this.onFileChange,
                          },
                        ]}
                      />
                      <FormGroup>
                        {" "}
                        <img
                          src={this.state?.image}
                          className="img-rounded"
                          width="100"
                          height="100"
                          alt="description"
                        />
                      </FormGroup>
                      <Button style={style.btn_addInfo} onClick={this.onOpen}>
                        <i className="fa fa-plus-circle" />
                      </Button>
                      <Table>
                        <thead>
                          <tr>
                            <th>Contenu</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!infos.length !== 0 ? (
                            infos.map((inf, id) => {
                              return (
                                <tr key={id}>
                                  <td style={{ fontSize: "90%" }}>
                                    <p>{inf?.contenu}</p>
                                  </td>
                                  <td>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <Button
                                          style={style.btn_edit}
                                          onClick={(e) => this.onEdit(inf)}
                                        >
                                          <i className="pe-7s-note" />
                                          Editer
                                        </Button>{" "}
                                        {/* <Button><i className="pe-7s-trash" />Supprimer</Button> */}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td>AUCUNE DONNEE</td>
                              <td>AUCUNE DONNEE</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <div className="row">
                        <Col md={12}>
                          {!this.state.load ? (
                            <Button
                              fill
                              style={style.btn}
                              onClick={this.send}
                              color="primary"
                            >
                              Envoyer
                            </Button>
                          ) : (
                            <Button fill style={style.btn}>
                              Loading...
                              <i
                                className="fa fa-spin fa-spinner fa-lg"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          )}
                        </Col>
                      </div>
                    </Form>
                  </>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const style = {
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    float: "right",
    marginRight: "0.8em",
    borderColor: "#e4393f",
  },
  btn_edit: {
    backgroundColor: "rgb(232, 10, 18)",
    float: "inline-start",
    // marginRight: '0.8em',
    borderColor: "rgb(243, 7, 10)",
    color: "#f3f3f3",
  },
  btn_addInfo: {
    backgroundColor: "rgb(232, 10, 18)",
    float: "inline-end",
    // marginRight: '0.8em',
    borderColor: "rgb(243, 7, 10)",
    color: "#f3f3f3",
  },
};
