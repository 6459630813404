import React from "react";
import { Grid } from "react-bootstrap";
import RotateLoader from "react-spinners/RotateLoader";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
};

export default function HomeSpinner() {
  return (
    <Grid fluid>
      <div style={style}>
        <RotateLoader />
      </div>
    </Grid>
  );
}
