// import SignUp from "views/AccountAdmin/SignUp.jsx";
// import Bordereau from "views/Bordereau/Bordereau.jsx";
// import ItemBordereau from "views/Bordereau/ItemBordereau.jsx";
// import List_Com from "views/Commercants/List_Com.jsx";
// import Contrat from "views/Contrat/Contrat.jsx";

/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// import UserProfile from "views/UserProfile";
// import UserProfile from "views/UserProfile";
import Abonnement from "views/viewsCommercant/Abonnement/Abonnement";
import Agenda from "views/viewsCommercant/Agenda/Agenda";
import CGV from "views/viewsCommercant/CGV";
import DashboardCommercant from "views/viewsCommercant/DashboardCommercant.jsx";
import Paiement from "views/viewsCommercant/GestionPaiement/Paiement";
// import LocationSearchInput from "views/viewsCommercant/LocationSearchInput";
import MaBoutique from "views/viewsCommercant/MaBoutique";
// import AddProduit from "views/viewsCommercant/Produit/AddProduit";
import MesProduits from "views/viewsCommercant/Produit/MesProduits";
import UserComProfil from "views/viewsCommercant/Profil/UserComProfil";
import Stats from "views/viewsCommercant/Stats/Stats";
import Suggestion from "views/viewsCommercant/Suggestion";
// import firebase from './firebase'


const dashboardRoutes = [
    {
        path: "/mydashboard",
        name: "Dashboard",
        icon: "pe-7s-graph",
        component: DashboardCommercant,
        layout: "/admin"
    },
    {
        path: "/agenda",
        name: "Agenda",
        icon: "fas fa-calendar-alt",
        component: Agenda,
        layout: "/admin"
    },
    {
        path: "/maboutique",
        name: "Boutique",
        icon: "fa fa-store",
        component: MaBoutique,
        layout: "/admin"
    },
    {
        path: "/monabonnement",
        name: "Abonnement",
        icon: "fas fa-circle",
        component: Abonnement,
        layout: "/admin"
    },
    {
        path: "/settings-paiement",
        name: "Moyen de paiement",
        icon: "fa fa-credit-card",
        component: Paiement,
        layout: "/admin"
    },
    {
        path: "/stats",
        name: "Stat",
        icon: `fas fa-chart-bar`,
        component: Stats,
        layout: "/admin"
    },
    {
        path: "/mesproduits",
        name: "Produits",
        icon: "pe-7s-menu",
        component: MesProduits,
        layout: "/admin"
    },
    {
        path: "/suggestion",
        name: "Suggestion? Aidez-nous",
        icon: `pe-7s-mail`,
        component: Suggestion,
        layout: "/admin"
    },
    {
        path: "/cgv",
        name: "Mentions légales, CGV",
        icon: `pe-7s-file`,
        component: CGV,
        layout: "/admin"
    },
    {
        path: "/monprofil",
        name: "Profil",
        icon: `pe-7s-user`,
        component: UserComProfil,
        layout: "/admin"
    },

];

export default dashboardRoutes;
