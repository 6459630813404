import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import firebase from "../../firebase.js";
import React from "react";
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import { toast, cssTransition } from "react-toastify";
import "../viewsCommercant/Abonnement/abonnement.css";
import moment from "moment";
toast.configure();
const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
});
class AddAbonnement extends React.Component {
  state = { item: { user: {} }, month: "" };

  componentWillReceiveProps(nextProps, prevProps) {
    let { item } = this.state;
    if (nextProps.item !== this.props.item) {
      item = nextProps.item;
      this.setState({ item });
    }
  }

  notify = (msg, type) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        transition: Zoom,
      });
    }
    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        transition: Zoom,
      });
    }
  };

  handleClose = () => {
    let { onOpen } = this.props;
    if (!!onOpen) {
      onOpen();
    }
  };

  onSelectChange = (e) => {
    this.setState({ month: e.target.value });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ load: true });
    let { item, month } = this.state;
    console.log(item);
    try {
      const db = firebase.firestore();
      const data = {
        month: month,
        createdAt: moment().format("YYYY-MM-DD"),
        add: true,
        userId: item.user?.id,
      };
      console.log(data);
      await db.collection("subscriptions").doc().set(data);
      this.notify("Opération enregister avec succes", "success");
      this.props.refresh();
      if (!!this.props.onOpen) {
        this.props.onOpen();
      }
    } catch (error) {
      console.log("error", error);
      this.notify(
        "Erreur lors du traitement de la requéte, veuillez réessayer",
        "error"
      );
    }
    this.setState({ load: false });
  };

  render() {
    const { statutPayModal } = this.props;
    const { handleClose, onSubmit } = this;
    const { month } = this.state;
    return (
      <Dialog
        maxWidth="sm"
        open={statutPayModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={style.title}>
          Ajouter un abonnement
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ width: "40rem" }}>
            <Row style={{ paddingTop: "2em" }}>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel style={style.label}>
                    Choisissez la date de début
                  </ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="date"
                    value={month}
                    name="month"
                    placeholder="Choisir le mois"
                    onChange={(e) => this.onSelectChange(e)}
                    defaultValue={moment().format("YYYY-MM-DD")}
                  ></FormControl>
                </FormGroup>
                {!!month && (
                  <p>
                    {month} =>{" "}
                    {moment(month).add(1, "months").format("YYYY-MM-DD")}
                  </p>
                )}
              </Col>
            </Row>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={!!!month} onClick={onSubmit} color="primary">
            Enregister
          </Button>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default AddAbonnement;

const style = {
  title: {
    borderBottom: "0.2rem solid",
  },
  label: {
    fontSize: "1.5rem",
  },
  displayLabel: {
    marginTop: "6px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "900",
    color: "black",
    cursor: "pointer",
  },
  aroundDiv: {
    borderColor: "#007bff",
    borderRadius: "35px",
    border: "2px solid rgba(18, 19, 19, 0.75)",
    width: "111px",
    height: "35px",
  },
};
