import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Modal,
  ModalTitle,
  ModalBody,
  ModalHeader,
} from "react-bootstrap";
import defaultImg from "./../../../assets/img/default-image.jpg";
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import UserCardImg from "./UserCardImg";
import LoadSpinner from "views/LoadSpinner";
// import UserCard from "components/UserCard/UserCard";
import firebase from "firebase";
import { toast } from "react-toastify";

toast.configure();
export default class UserComProfil extends Component {
  state = {
    info: {},
    loading: false,
    displayName: "",
    email: "",
    show: false,
    is_submit: false,
    valid: false,
    currentPassword: "",
    wrong: "",
  };

  componentDidMount() {
    this._getInfo();
  }
  _getInfo = async () => {
    this.setState({ loading: !this.state.loading });
    try {
      let db = firebase.firestore();
      //   let info = {};
      let uid = JSON.parse(sessionStorage.getItem("id_user"));
      let doc = await db.collection("users").doc(uid).get();
      //   console.log("doc", doc.data());
      let get = doc.data();
      let { email, displayName } = this.state;
      email = get?.email || "";
      displayName = get?.nom || "";
      // let user = await firebase.auth().currentUser;
      // if (user) console.log("tr", user.displayName);
      this.setState({
        info: doc.data(),
        email,
        displayName,
        loading: !this.state.loading,
      });
    } catch (error) {
      console.log(`error response ${error}`);
    }
  };

  onChange = (e) => {
    e.preventDefault();
    const { info } = this.state;
    const { name, value } = e.target;
    info[name] = value;
    this.setState({ fields: info }, () =>
      console.log(`infos =>`, this.state.info)
    );
  };

  static displayMessage = (code = null) => {
    let message = "Tous les champs sont obligatoires";
    if (code != null) {
      if (code === "auth/invalid-email") {
        message = "email non valid";
      } else if (code === "auth/wrong-password") {
        message = "Mot de passe invalide";
      } else if (code === "auth/user-not-found") {
        message = "email ou mot de passe incorrect";
      } else if (code === "auth/network-request-failed") {
        message = "Erreur réseau";
      } else if (code === "auth/email-already-in-use") {
        message = "cet email existe déja";
      } else if (code === "auth/weak-password") {
        message = "le mot de passe doit contenir au moins 6 caractéres";
      } else if (code === "auth/operation-not-allowed") {
        message = "La configuration du fournisseur d'identité est introuvable.";
      } else if (code === "auth/account-exists-with-different-credential") {
        message =
          "Un compte existe déjà avec la même adresse e-mail mais des informations de connexion différentes. Connectez-vous à l'aide d'un fournisseur associé à cette adresse e-mail.";
      } else {
        message = "Erreur lors de la connexion";
      }
    }
    toast.warn(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };

  //@devatg reauthenticate for change email or password
  reauthenticate = (currentPassword) => {
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return user.reauthenticateWithCredential(cred);
  };

  notify = (msg, type) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  //@devatg change email function
  changeEmail = async (currentPassword, newEmail, valid) => {
    console.log("valid", valid);
    const { info, displayName } = this.state;
    this.setState({ valid: valid });
    this.reauthenticate(currentPassword)
      .then(() => {
        var user = firebase.auth().currentUser;
        user
          .updateEmail(newEmail)
          .then(async () => {
            console.log("Email updated!");
            // console.log("Email updated!", res);
            await firebase
              .firestore()
              .collection("users")
              .doc(user?.uid)
              .update({ email: newEmail })
              .then(() => {
                this.setState({ currentPassword: "", valid: false });
                this.setState((prevState) => {
                  return { show: !prevState.show };
                });
                try {
                  let nom = info?.nom;
                  let profile = displayName !== nom ? { nom } : {};
                  if (Object.keys(profile).length > 0) {
                    console.log("ici");
                    firebase.auth().currentUser.updateProfile({ profile });
                  }
                  let data = {
                    commercant: info?.commercant || true,
                    phone: info?.phone || "",
                    domicile: info?.domicile || "",
                    travail: info?.travail || "",
                    vacances: info?.vacances || "",
                    nom: nom,
                    email: info?.email,
                    // isAcceptNotification:,
                  };
                  firebase
                    .firestore()
                    .collection("users")
                    .doc(firebase.auth().currentUser.uid)
                    .update(data)
                    .then(async () => {
                      this.setState({ is_submit: false });
                      toast.success("Votre profil a été modifié avec succès", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 3000,
                      });
                      this.setState({ valid: false });
                      await this._getInfo();
                    });
                } catch (error) {
                  console.log(error.code);
                  UserComProfil.displayMessage(error.code);
                }
              });
            //test
            //end test
            // this.notify("Votre Email a été modifié avec succès", "success");
          })
          .catch((error) => {
            this.setState({ valid: false });
            console.log(error);
            UserComProfil.displayMessage(error.code);
          });
      })
      .catch((error) => {
        this.setState({ valid: false });
        console.log(error);
        UserComProfil.displayMessage(error.code);
        this.setState({ wrong: error.code });
        console.log("wrong", this.state.wrong);
      });
  };

  //@devatg change password function
  changePassword = (currentPassword, newPassword) => {
    this.reauthenticate(currentPassword)
      .then(() => {
        var user = firebase.auth().currentUser;
        user
          .updatePassword(newPassword)
          .then(() => {
            console.log("Password updated!");
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  openModal = () => {
    this.setState((prevState) => {
      // console.log(prevState.show);
      return { show: !prevState.show };
    });
  };

  validedPassword = async (e) => {
    e.preventDefault();
    const { email, info, currentPassword, displayName } = this.state;
    this.setState({ valid: true });
    if (info?.email !== email) {
      let newUser_mail = info?.email;
      if (this.state.currentPassword.trim().length !== 0) {
        let valid = !this.state.valid;
        await this.changeEmail(currentPassword, newUser_mail, valid);
      } else {
        this.setState({ valid: false });
        this.notify("champs est obligatoire", "error");
      }
    }
  };

  submit = async (e) => {
    e.preventDefault();
    const { email, info, displayName } = this.state;
    let uid = JSON.parse(sessionStorage.getItem("id_user"));
    this.setState({ is_submit: !this.state.is_submit });
    try {
      if (info?.email !== email) {
        // let mail_content = info?.email;
        this.setState({ is_submit: false });
        await this.openModal();
      } else {
        let nom = info?.nom;
        let profile = displayName !== nom ? { nom } : {};
        if (Object.keys(profile).length > 0) {
          console.log("ici");
          await firebase.auth().currentUser.updateProfile({ profile });
        }

        let data = {
          commercant: info?.commercant || true,
          phone: info?.phone || "",
          domicile: info?.domicile || "",
          travail: info?.travail || "",
          vacances: info?.vacances || "",
          nom: nom,
          email: info?.email,
          // isAcceptNotification:,
        };
        await firebase.firestore().collection("users").doc(uid).update(data);
        this.setState({ is_submit: !this.state.is_submit });

        toast.success("Votre profil a été modifié avec succès", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        await this._getInfo();
      }
      // this.setState({is_submit:!this.state.is_submit})
    } catch (error) {
      this.setState({ is_submit: !this.state.is_submit });

      console.log(error.message);
    }
  };

  render() {
    let { loading, info, email, displayName, currentPassword } = this.state;
    // console.log("test", info);
    if (loading) {
      return <LoadSpinner />;
    }
    return (
      <div className="content">
        {this.state.show ? (
          <Modal show={this.state.show} onHide={this.openModal}>
            <Grid fluid>
              <ModalHeader closeButton>
                <ModalTitle>
                  <span>Confirmation</span>
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <div className="alert alert-info" role="alert">
                  Veuilliez saisir votre mot de passe
                </div>
                <form>
                  <FormInputs
                    ncols={["col-md-6"]}
                    properties={[
                      {
                        label: "Mot de passe",
                        type: "password",
                        bsClass: "form-control",
                        placeholder: "Password",
                        name: "currentPassword",
                        value: currentPassword,
                        onChange: (e) => {
                          e.preventDefault();
                          // let { currentPassword } = this.state;
                          let { value } = e.target;
                          this.setState({ currentPassword: value }, () =>
                            console.log(
                              `currentPassword =>`,
                              this.state.currentPassword
                            )
                          );
                        },
                      },
                    ]}
                  />
                  {!this.state.valid ? (
                    <Button
                      bsStyle="danger"
                      pullRight
                      fill
                      onClick={this.validedPassword}
                    >
                      Valider
                    </Button>
                  ) : (
                    <Button bsStyle="danger" pullRight fill>
                      Loading...
                      <i
                        className="fa fa-spin fa-spinner"
                        aria-hidden="true"
                      ></i>
                    </Button>
                  )}
                </form>
                <div className="clearfix" />
              </ModalBody>
            </Grid>
          </Modal>
        ) : null}
        <Grid fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Profile"
                content={
                  <form>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      properties={[
                        {
                          label: "Email",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Email",
                          name: "email",
                          value: info?.email,
                          onChange: this.onChange,
                          //   defaultValue: "Creative Code Inc.",
                          //   disabled: true,
                        },
                        {
                          label: "Nom d'utilisateur",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Nom d'utilisateur",
                          name: "nom",
                          value: info?.nom,
                          onChange: this.onChange,
                          //   defaultValue: "michael23",
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "Numero de telephone",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Numero de telephone",
                          name: "phone",
                          value: info?.phone || "",
                          onChange: this.onChange,
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {
                          label: "Domicile",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Domicile",
                          name: "domicile",
                          value: info?.domicile || "",
                          onChange: this.onChange,
                        },
                        {
                          label: "Travail",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Travail",
                          name: "travail",
                          value: info?.travail || "",
                          onChange: this.onChange,
                        },
                        {
                          label: "Vacances",
                          type: "texte",
                          bsClass: "form-control",
                          name: "vacances",
                          value: info?.vacances || "",
                          onChange: this.onChange,
                          placeholder: "Vacances",
                        },
                      ]}
                    />
                    {!this.state.is_submit ? (
                      <Button
                        bsStyle="danger"
                        pullRight
                        fill
                        onClick={this.submit}
                      >
                        Sauvegarder
                      </Button>
                    ) : (
                      <Button bsStyle="danger" pullRight fill>
                        Loading...
                        <i
                          className="fa fa-spin fa-spinner"
                          aria-hidden="true"
                        ></i>
                      </Button>
                    )}
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
            <Col md={4}>
              <UserCardImg
                bgImage="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
                avatar={info?.photo ? info.photo : defaultImg}
                name={email}
                userName={displayName}
                // description={
                //   <span>
                //     "Lamborghini Mercy
                //     <br />
                //     Your chick she so thirsty
                //     <br />
                //     I'm in that two seat Lambo"
                //   </span>
                // }
                socials={
                  <div>
                    <Button simple>
                      <i className="fa fa-facebook-square" />
                    </Button>
                    <Button simple>
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button simple>
                      <i className="fa fa-google-plus-square" />
                    </Button>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
