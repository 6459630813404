
import moment from "moment";
import "moment/locale/fr"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const str2bool = (value) => {
    if (value && typeof value === 'string') {
        if (value.toLowerCase() === "true") return true;
        if (value.toLowerCase() === "false") return false;
    }
    return value;
}

export const notify = () => {
    toast.success('Ajoutee avec success',
        { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }
    )
}

export const notifyErreur = () => {
    toast.error("Oupps erreur lors de l'envoi, Veuillez reessayer!",
        { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }
    )
}

export const getMonthByDate = (date) => {
    var a = new Date(date);
    // var year = a.getFullYear();
    var months = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
    var month = months[a.getMonth()];
    // var day=
    return month;
}

export const getDayByDate = (date) => {
    var a = new Date(date);
    // var year = a.getFullYear();
    // var months = a.getMonth();
    var day = a.getDate()
    return day;
}

export function getTime(date) {
    var h = new Date(date).getHours();
    var m = new Date(date).getMinutes();

    h = (h < 10) ? '0' + h : h;
    m = (m < 10) ? '0' + m : m;
    return h + ':' + m;
}

export function toDayDateTime() {
    return moment().toDate().getTime();
}

export function getH(date) {
    return parseInt(moment(date).format("k"), 10);
}
export function getM(date) {
    return parseInt(moment(date).format("m"), 10);
}

export function formatTime(time) {
    return moment(time).format("LT");
}
export function getMonth(date = undefined) {
    return moment(date).format("M");
}
export function getYear(date = undefined) {
    return moment(date).format("YYYY");
}
export function getDayNumberFromDate(date) {
    return moment(date).format("D");
}
export function getDayFromDate(date) {
    return moment(date).format("ddd");
}
export function getDayFromAllDate(date) {
    return moment(date).format("dddd");
}

export const displayMessage = (code = null) => {
    let message = "Tous les champs sont obligatoires";
    if (code != null) {
        if (code === "auth/invalid-email") {
            message = "email non valid";
        } else if (code === "auth/wrong-password") {
            message = "Mot de passe invalide";
        } else if (code === "auth/user-not-found") {
            message = "email ou mot de passe incorrect";
        } else if (code === "auth/network-request-failed") {
            message = "Erreur réseau";
        } else if (code === "auth/email-already-in-use") {
            message = "cet email existe déja";
        } else if (code === "auth/weak-password") {
            message = "le mot de passe doit contenir au moins 6 caractéres";
        } else if (code === "auth/operation-not-allowed") {
            message = "La configuration du fournisseur d'identité est introuvable.";
        } else if (code === "auth/account-exists-with-different-credential") {
            message =
                "Un compte existe déjà avec la même adresse e-mail mais des informations de connexion différentes. Connectez-vous à l'aide d'un fournisseur associé à cette adresse e-mail.";
        } else {
            message = "Erreur lors de la connexion";
        }
    }
    toast.warn(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
    });
};


