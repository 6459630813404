import Card from 'components/Card/Card';
import FormInputs from '../../../components/FormInputs/FormInputs.jsx';
import React, { Component } from 'react';
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Row,
} from 'react-bootstrap';
import { CATEGORIES } from 'constants.js';
import { TYPE } from 'constants.js';
import { TYPE_PRODUIT } from 'constants.js';
import Button from 'components/CustomButton/CustomButton.jsx';
import { toDayDateTime } from 'utils.js';
import firebase from '../../../firebase.js';
import LoadSpinner from 'views/LoadSpinner.jsx';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { toast } from 'react-toastify';
// import MesProduits from "./MesProduits.jsx";
import { DURATION } from 'constants.js';
// import AutoComplete from "./AutoComplete/AutoComplete.jsx";
import './AutoComplete/_style.css';
import defaultImg from '../../../assets/img/default-image.jpg';
const storage = firebase.storage();

toast.configure();
export default class AddProduit extends Component {
  state = {
    fields: {
      nom: '',
      description: '',
      stock: 0,
      prix: 0,
      categorie: 'entrée',
      product_type: 'À emporter',
      durration: '',
      type: 'sans temps de préparation',
      boutique_id: JSON.parse(sessionStorage.getItem('id_user')),
    },
    categories: CATEGORIES,
    type: TYPE,
    type_produit: TYPE_PRODUIT,
    uid: JSON.parse(sessionStorage.getItem('id_user')),
    spinner: false,
    load: false,
    show: false,
    duree: DURATION,

    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: 'Plat',
    all_catg: [],
    file: null,
    url: '',
  };

  componentDidMount() {
    this._getCategorie();
  }

  _getCategorie = async () => {
    try {
      let db = firebase.firestore();
      let all_catg = [];
      let docs = await db.collection('categories').get();
      docs.forEach((doc) => all_catg.push({ ...doc.data() }));
      // console.log("all categ=>", all_catg);
      let testTab = [];
      all_catg.forEach((element) => {
        testTab.push(element.nom);
      });
      console.log('test tab=>', testTab);
      this.setState({ all_catg: testTab });
    } catch (error) {
      console.log(error.code);
    }
  };

  onChange = (e) => {
    e.preventDefault();
    let { fields } = this.state;
    const { name, value } = e.target;
    fields[name] = value;
    this.setState({ fields }, () => {
      // console.log("selected", this.state.fields);
      if (fields.type === 'temps préparation non standard') {
        this.setState({ show: true });
      } else {
        fields.durration = '';
        this.setState({ show: false, fields: fields });
        // console.log(this.state.fields);
      }
    });
  };

  displayMessage = (code = null) => {
    let message = 'Tous les champs sont obligatoires';
    if (code != null) {
      if (code === 'auth/invalid-email') {
        message = 'email non valid';
      } else if (code === 'auth/wrong-password') {
        message = 'Mot de passe invalide';
      } else if (code === 'auth/user-not-found') {
        message = 'email ou mot de passe incorrect';
      } else if (code === 'auth/network-request-failed') {
        message = 'Erreur réseau';
      } else if (code === 'auth/email-already-in-use') {
        message = 'cet email existe déja';
      } else if (code === 'auth/weak-password') {
        message = 'le mot de passe doit contenir au moins 6 caractéres';
      } else if (code === 'auth/operation-not-allowed') {
        message = "La configuration du fournisseur d'identité est introuvable.";
      } else if (code === 'auth/account-exists-with-different-credential') {
        message =
          "Un compte existe déjà avec la même adresse e-mail mais des informations de connexion différentes. Connectez-vous à l'aide d'un fournisseur associé à cette adresse e-mail.";
      } else {
        message = 'Erreur lors de la connexion';
      }
    }
    toast.warn(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };

  notify = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  };

  createBoutique = async (data) => {
    let { file } = this.state;
    try {
      const db = firebase.firestore();
      let boutik = await db.collection('products').doc();
      let doc = await boutik.get();
      if (!doc.exists) {
        // console.log("exist pas");
        if (file !== null) {
          await this.uploadImage(data);
        }
        await boutik.set(data);
        this.setState({ load: false });
        this.notify('Produit ajouté avec sucess');
        setTimeout(() => {
          return this.props.history.push('/admin/mesproduits');
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      this.setState({ load: false });
    }
  };

  addNewCategorie = async (data) => {
    let { categorie } = data;
    // console.log("data=>", data);
    try {
      const db = firebase.firestore();
      let category = await db.collection('categories').doc();
      let doc = await category.get();
      if (!doc.exists) {
        // console.log("exist pas");
        await category.set({ nom: categorie }).then(async () => {
          await this.createBoutique(data);
        });
      }
    } catch (error) {
      this.setState({ load: false });
      console.log(error.code);
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ load: true });
    const { fields } = this.state;
    let data = fields;
    if (data.durration.length === 0) {
      data.durration = false;
    }
    data.created_at = toDayDateTime();
    data.prix = parseFloat(data.prix).toFixed(2);
    // console.log("data send", data);
    if (
      fields['nom'].trim().length === 0 ||
      fields['prix'].trim().length === 0 ||
      fields['description'].trim().length === 0 ||
      fields['stock'].trim().length === 0
    ) {
      this.setState({ load: false });
      this.displayMessage();
    } else {
      try {
        let test = this.state.all_catg.includes(data.categorie);
        if (test) {
          await this.createBoutique(data);
        } else {
          await this.addNewCategorie(data);
        }
      } catch (error) {
        this.setState({ load: false });
        console.log(error.code);
        this.displayMessage(error.code);
      }
    }
  };

  onBack = () => {
    return this.props.history.push('/admin/mesproduits');
  };

  onChangeSuggestion = (e) => {
    let suggestions = this.state.all_catg;
    // console.log("suggestions=>", suggestions);
    // const userInput = e.currentTarget.value;
    let { fields } = this.state;
    fields.categorie = e.currentTarget.value;
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(fields.categorie.toLowerCase()) > -1
    );

    this.setState(
      {
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true,
        userInput: e.currentTarget.value,
        fields,
      }
      // () => console.log("sug=>", fields.categorie)
    );
  };

  onClick = (e) => {
    let { fields } = this.state;
    fields.categorie = e.currentTarget.innerText;
    this.setState(
      {
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: e.currentTarget.innerText,
        fields,
      }
      // () => console.log("click choice categorie=>", this.state.fields.categorie)
    );
  };

  onKeyDown = (e) => {
    // console.log("keyDown=>", e.keyCode);
    const { activeSuggestion, filteredSuggestions, fields } = this.state;

    if (e.keyCode === 13) {
      let categorie = filteredSuggestions[activeSuggestion];
      fields.categorie = categorie;
      this.setState(
        {
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: filteredSuggestions[activeSuggestion],
          fields,
        }
        // () => console.log("categorie=>", this.state.fields.categorie)
      );
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  renderInputSuggestion = () => {
    const {
      onChangeSuggestion,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        // userInput,
        fields,
      },
    } = this;
    let suggestionsListComponent;
    if (showSuggestions && fields.categorie) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className='suggestions'>
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active';
              }
              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      }
    }
    return (
      <React.Fragment>
        <input
          type='text'
          className='form-control'
          onChange={onChangeSuggestion}
          onKeyDown={onKeyDown}
          value={this.state.fields.categorie}
        />
        {suggestionsListComponent}
      </React.Fragment>
    );
  };

  onFileChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    console.log('file', file.name);
    // console.log("test");
    this.setState({ file: file });
  };

  async uploadImage(data) {
    // console.log("upload");
    let { file } = this.state;
    if (file !== null) {
      const storageRef = storage.ref(`products/`);
      const fileRef = storageRef.child(file?.name);
      await fileRef.put(file);
      this.setState({ url: await fileRef.getDownloadURL() });
      data.photo = this.state.url;
    }
    return data;
  }

  render() {
    let { fields, type, type_produit, spinner, load } = this.state;

    // const renderCategorie = this.state.categories.map((item, i) => {
    //   return (
    //     <option placeholder="categorie" value={item} key={i}>
    //       {item}
    //     </option>
    //   );
    // });
    const renderType = type.map((item, i) => {
      return (
        <option
          placeholder='Temps de preparation du produit'
          value={item}
          key={i}
        >
          {item}
        </option>
      );
    });
    const renderTypeProduit = type_produit.map((item, i) => {
      return (
        <option
          placeholder='Temps de preparation du produit'
          value={item}
          key={i}
        >
          {item}
        </option>
      );
    });

    const renderDuration = DURATION.map((item, i) => {
      return (
        <option placeholder='durée' value={item} key={i}>
          {item}
        </option>
      );
    });

    if (spinner) {
      return <LoadSpinner />;
    }
    return (
      <div className='content'>
        <Grid fluid>
          <Col md={12}>
            <Card
              title='Nouveau Produit'
              content={
                <React.Fragment>
                  <ArrowBackSharpIcon
                    style={{ cursor: 'pointer' }}
                    fontSize={'large'}
                    onClick={this.onBack}
                  />
                  <FormGroup>
                    <img
                      src={defaultImg}
                      width='200'
                      height='230'
                      alt='description'
                    />
                    <input
                      type='file'
                      onChange={this.onFileChange}
                      name='image'
                      label='Image'
                    />
                  </FormGroup>
                  <Form>
                    <FormInputs
                      ncols={['col-md-12']}
                      properties={[
                        {
                          label: 'Nom du produit',
                          type: 'text',
                          bsClass: 'form-control',
                          placeholder: 'Nom du produit',
                          name: 'nom',
                          value: fields?.nom || '',
                          onChange: this.onChange,
                        },
                      ]}
                    />
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <ControlLabel>Description détaillée</ControlLabel>
                          <textarea
                            className='form-control'
                            name='description'
                            type='text'
                            value={fields?.description || ''}
                            placeholder='Un peu plus de details sur ce produit'
                            onChange={this.onChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormInputs
                      ncols={['col-md-6', 'col-md-6']}
                      properties={[
                        {
                          label: 'Quantite stock',
                          type: 'text',
                          bsClass: 'form-control',
                          placeholder: 'Quantite stock',
                          name: 'stock',
                          value: fields?.stock || '',
                          onChange: this.onChange,
                        },
                        {
                          label: 'Prix en €',
                          type: 'number',
                          bsClass: 'form-control',
                          placeholder: 'Prix en €',
                          name: 'prix',
                          value: fields?.prix || '',
                          onChange: this.onChange,
                        },
                      ]}
                    />
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <ControlLabel>Categorie</ControlLabel>
                          {this.renderInputSuggestion()}
                          {/* <AutoComplete
                            suggestions={[
                              "Oranges",
                              "Apples",
                              "Banana",
                              "Kiwi",
                              "Mango",
                            ]}
                          /> */}
                          {/* <ControlLabel>Categorie</ControlLabel>
                          <FormControl
                            name="categorie"
                            componentClass="select"
                            placeholder="Categorie"
                            onChange={this.onChange}
                          >
                            {renderCategorie}
                          </FormControl> */}
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <ControlLabel>
                            Temps de preparation du produit
                          </ControlLabel>
                          <FormControl
                            componentClass='select'
                            name='type'
                            placeholder='Temps de preparation'
                            onChange={this.onChange}
                          >
                            {renderType}
                          </FormControl>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <ControlLabel>Type de Produit</ControlLabel>
                          <FormControl
                            componentClass='select'
                            name='product_type'
                            placeholder='Type produit'
                            onChange={this.onChange}
                          >
                            {renderTypeProduit}
                          </FormControl>
                        </FormGroup>
                      </Col>
                    </Row>
                    {this.state.show ? (
                      <Row>
                        <Col md={4} style={{ float: 'left' }}>
                          <FormGroup>
                            <ControlLabel>Durée</ControlLabel>
                            <FormControl
                              componentClass='select'
                              name='durration'
                              placeholder='Durée'
                              onChange={this.onChange}
                            >
                              <option defaultValue=''>Choisir la durée</option>
                              {renderDuration}
                            </FormControl>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}
                    {!load ? (
                      <Button
                        style={style.btn}
                        fill
                        pullRight
                        onClick={this.onSubmit}
                      >
                        Enregistrer
                      </Button>
                    ) : (
                      <Button style={style.btn} fill pullRight>
                        Loading...
                        <i
                          className='fa fa-spin fa-spinner'
                          aria-hidden='true'
                        ></i>
                      </Button>
                    )}
                    <div className='clearfix'></div>
                  </Form>
                </React.Fragment>
              }
            />
          </Col>
        </Grid>
      </div>
    );
  }
}

const style = {
  btn: {
    backgroundColor: 'rgba(226, 41, 47, 0.92)',
    float: 'left',
    marginRight: '0.8em',
    borderColor: '#e4393f',
    width: '25%',
  },
};
