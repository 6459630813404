import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class CardZero extends Component {
  render() {
    return (
      <div
        className="card text-center"
        style={{
          width: "100%",
          height: "200px",
          margin: "auto",
          padding: "15px",
          backgroundColor: "whitesmoke"
        }}
      >
        {/* <div class="card-header">Featured</div> */}
        <div className="card-body">
          <p className="card-text" style={{ fontSize: "20px" }}>
            {this.props.text}
          </p>
          <p className="card-text" style={{ fontSize: "20px" }}>
            Pour changer d'abonnement :
          </p>
          <NavLink
            to="/admin/monabonnement"
            className="btn btn-primary"
            style={{ color: "red", border: "none", fontSize: "20px" }}
          >
            C'est ici{" "}
            <i
              className="fas fa-hand-point-left"
              style={{ color: "orange", border: "none" }}
            ></i>
          </NavLink>
        </div>
        {/* <div class="card-footer text-muted">2 days ago</div> */}
      </div>
    );
  }
}
