/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react components used to create a google map
import {
  // withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const API_KEY = "AIzaSyCQ1YjuQ1jWfHKyHE7R5UfA8o6jF6otdqg";

const CustomMap = withGoogleMap((props) => {
  // console.log("propppps", props);
  // console.log("propppps", typeof props?.latitude);
  return (
    <GoogleMap
      defaultZoom={11}
      defaultCenter={{
        lat: props?.latitude,
        lng: props?.longitude,
      }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
      }}
    >
      {props.isMarkerShown && <Marker position={props.location} />}
    </GoogleMap>
  );
});

function Maps({ ...props }) {
  console.log("props=>", props?.latitude.toFixed(6));
  return (
    <CustomMap
      isMarkerShown
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `80vh` }} />}
      mapElement={<div style={{ height: `85%` }} />}
      latitude={props?.latitude}
      longitude={props?.longitude}
      location={{ lat: props.latitude, lng: props.longitude }}
    />
  );
}

export default Maps;
