import Card from "components/Card/Card";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import firebase from "../../firebase.js";
import ReactHtmlParser from "react-html-parser";

export default class CGV extends Component {
  state = {
    data: "",
  };

  componentDidMount() {
    this._getCGV();
  }

  _getCGV = async () => {
    try {
      const db = firebase.firestore();
      await db
        .collection("ucg")
        .doc("ucg")
        .get()
        .then((docRef) => {
          //   console.log("cgv", docRef.data().description);
          let data = docRef.data().description;
          this.setState({ data: data });
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={8}>
              <Card content={ReactHtmlParser(this.state.data)} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
