/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Nav, MenuItem, NavDropdown } from "react-bootstrap";
import firebase from "../../firebase";
import "./navbar.css";
import { Badge, Loader } from "rsuite";
import { cmd_status } from "variables/constants";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { _getAllCommandes } from "reducers/commande/actions/commandeAction";
import { _getAllNotifations } from "reducers/notifications/actions/notificationAction";
import { getFormattedDateTime } from "./timelib";
//import 'rsuite/dist/styles/rsuite-default.css'

class AdminNavbarLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      isloading: false,
    };
  }

  componentDidMount() {
    this.addNotificationListner();
  }
  componentWillUnmount() {
    if (this.listener) {
      this.listener();
    }
  }

  addNotificationListner() {
    const user_id = JSON.parse(sessionStorage.getItem("id_user"));
    this.listener = firebase
      .firestore()
      .collection("notifications")
      .where("target_id", "==", user_id)
      .onSnapshot((qs) => {
        const notifications = [...this.state.notifications];
        qs.docChanges().forEach((change) => {
          if (
            [
              cmd_status.NEW_ORDER,
              cmd_status.ORDER_CANCELED_BY_CLIENT,
            ].includes(change.doc.data().type)
          ) {
            const data = { id: change.doc.id, ...change.doc.data() };
            const index = notifications.findIndex((i) => i.id === data.id);
            if (index === -1) {
              notifications.push(data);
            } else {
              notifications[index] = { ...notifications[index], ...data };
            }
          }
        });
        this.setState({
          notifications: notifications.sort((a, b) => b.date - a.date),
        });
      });
  }

  onSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        let whoLoggin =
          JSON.parse(sessionStorage.getItem("id_user")) ||
          JSON.parse(sessionStorage.getItem("is_admin"));
        if (whoLoggin === "id_user") {
          window.location.pathname = "/";
          sessionStorage.clear();
        } else {
          window.location.pathname = "/signin";
          sessionStorage.clear();
        }
      })
      .catch(function (error) {
        console.log("sign out", error);
      });
  };

  profil = () => {
    window.location.pathname = "/admin/monprofil";
  };

  updateNotify = async (e, notify) => {
    e?.preventDefault();
    try {
      const db = firebase.firestore();
      await db.collection("notifications").doc(notify?.id).update({
        read: true,
        readDate: new Date().getTime(),
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  updateAllNotify = async (e) => {
    e.preventDefault();
    let noRead = this.state.notifications.filter((f) => !f.read);
    for (let item of noRead) {
      this.updateNotify(null, item);
    }
  };
  render() {
    let read = this.state.notifications.filter((f) => f.read);

    let noRead = this.state.notifications.filter((f) => !f.read);
    const profil = (
      <div>
        <i className="fa fa-user fa-5x" />
        <b className="caret" />
        <p className="hidden-lg hidden-md">Profil</p>
      </div>
    );

    const notification = (
      <div>
        {Array.isArray(noRead) && noRead.length !== 0 ? (
          <Badge content={noRead?.length}>
            <i className="fas fa-bell"></i>
          </Badge>
        ) : (
          <i className="fas fa-bell"></i>
        )}

        <b className="caret" />
        {/* <span className="notification">5</span> */}
        <p className="hidden-lg hidden-md">Notification</p>
      </div>
    );
    return (
      <div>
        <Nav pullRight>
          <NavDropdown
            eventKey={1}
            title={notification}
            noCaret
            style={{ maxHeight: "34px" }}
            id="dropdown-size-small"
          >
            <div style={{ overflowY: "scroll", maxHeight: "30em" }}>
              <>
                <button className="notif-btn" onClick={this.updateAllNotify}>
                  Marquer tout comme lu
                </button>
                <p style={{ marginLeft: "1rem", fontWeight: 600 }}>Non lus</p>
                {Array.isArray(noRead) && noRead.length !== 0 ? (
                  noRead?.map((item, index) => {
                    const { body, title } = getMessage(item);
                    return (
                      <React.Fragment key={item.id}>
                        <NavLink
                          key={index}
                          style={{
                            color: "tomato",
                            fontWeight: 900,
                            fontSize: "15px",
                          }}
                          to={{
                            pathname: `/admin/orderDetails/${item.order_id}`,
                          }}
                        >
                          <p
                            style={{
                              whiteSpace: "normal",
                              fontWeight: 900,
                              fontSize: "15px",
                              marginLeft: "1.5rem",
                            }}
                          >
                            {title}
                          </p>
                        </NavLink>
                        <p
                          style={{
                            whiteSpace: "normal",
                            fontWeight: 600,
                            fontSize: "12px",
                            marginLeft: "1.5rem",
                          }}
                        >
                          {body}
                        </p>
                        <p
                          style={{
                            whiteSpace: "normal",
                            fontWeight: 600,
                            fontSize: "12px",
                            marginLeft: "1.5rem",
                          }}
                        >
                          pour le{" "}
                          {getFormattedDateTime(
                            item,
                            "dateOrder",
                            item.emported
                          )}
                        </p>
                        <p
                          style={{
                            textAlign: "end",
                            color: "grey",
                            fontSize: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          <button
                            className="notif-btn"
                            onClick={(e) => this.updateNotify(e, item)}
                          >
                            Marquer comme lu
                          </button>
                        </p>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <strong>Pas de Notifications</strong>
                )}
                <MenuItem divider />
                <p style={{ marginLeft: "1rem", fontWeight: 600 }}>Déja lus</p>
                {Array.isArray(read) && read.length !== 0 ? (
                  read?.map((item, index) => {
                    const { body, title } = getMessage(item);
                    return (
                      <React.Fragment key={item.id}>
                        <NavLink
                          key={index}
                          style={{
                            color: "tomato",
                            fontWeight: 900,
                            fontSize: "15px",
                          }}
                          to={{
                            pathname: `/admin/orderDetails/${item.order_id}`,
                          }}
                        >
                          <p
                            style={{
                              whiteSpace: "normal",
                              fontWeight: 900,
                              fontSize: "15px",
                              marginLeft: "1.5rem",
                            }}
                          >
                            {title}
                          </p>
                        </NavLink>
                        <p
                          style={{
                            whiteSpace: "normal",
                            fontWeight: 600,
                            fontSize: "12px",
                            marginLeft: "1.5rem",
                          }}
                        >
                          {body}
                        </p>
                        <p
                          style={{
                            whiteSpace: "normal",
                            fontWeight: 600,
                            fontSize: "12px",
                            marginLeft: "1.5rem",
                          }}
                        >
                          pour le{" "}
                          {getFormattedDateTime(
                            item,
                            "dateOrder",
                            item.emported
                          )}
                        </p>
                        <p className="divider"></p>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <strong>Pas de Notifications</strong>
                )}
              </>
            </div>
          </NavDropdown>
          <NavDropdown
            eventKey={2}
            title={profil}
            noCaret
            id="basic-nav-dropdown"
          >
            <MenuItem eventKey={2.1} onClick={this.profil}>
              Mon Profil
            </MenuItem>
            <MenuItem eventKey={2.2} onClick={this.onSignOut}>
              Deconnexion
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    _getAllCommandes: () => dispatch(_getAllCommandes()),
    _getAllNotifations: () => dispatch(_getAllNotifations()),
  };
};

const mapStateToProps = (state) => {
  return {
    items: state.commandes.items,
    notifications: state.notifications.items,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbarLinks);

//export default AdminNavbarLinks

const getMessage = (notif) => {
  let message = "Nouvelle commande";
  let montant;
  if (notif.type === cmd_status.NEW_ORDER) {
    message = `nouvelle commande code ${notif.code}`;
    montant = `Boutique : ${notif.boutique_nom}`;
  } else if (notif.type === cmd_status.ORDER_CANCELED_BY_CLIENT) {
    message = `la commande code ${notif.code} a été annulée par le client`;
    montant = `Montant : ${notif.prix} €`;
  } else {
    montant = `Montant : ${notif.prix} €`;
  }
  return {
    title: message,
    body: montant,
  };
};
