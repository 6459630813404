import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import firebase from "../../firebase.js";
import React from "react";
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import { PAYMENT_ACCOUNT_STATUS } from "variables/constants";
import { toast, cssTransition } from "react-toastify";
import "../viewsCommercant/Abonnement/abonnement.css";
toast.configure();
const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
});
class ModalAccountStatus extends React.Component {
  state = { item: { user: {} }, payment_account_status: undefined };

  componentWillReceiveProps(nextProps, prevProps) {
    let { item } = this.state;
    if (nextProps.item !== this.props.item) {
      item = nextProps.item;
      this.setState({ item });
    }
  }

  notify = (msg, type) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        transition: Zoom,
      });
    }
    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        transition: Zoom,
      });
    }
  };

  handleClose = () => {
    let { onOpen } = this.props;
    if (!!onOpen) {
      onOpen();
    }
  };

  onSelectChange = (e) => {
    const { name, value } = e.target;
    let { item, payment_account_status } = this.state;
    if (value !== "undefined") {
      item.user[name] = value;
      payment_account_status = value;
      console.log("value=>", value);
      this.setState({ item, payment_account_status });
    } else {
      this.setState({ payment_account_status: undefined });
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ load: true });
    let { item } = this.state;
    let payment_account_status = item.user?.payment_account_status;
    console.log("payment account status=>", payment_account_status);
    try {
      const db = firebase.firestore();
      let doc = await db.collection("users").doc(item.user?.id);
      let user = await doc.get();
      if (user.exists) {
        if (user.data().payment_account_status) {
          doc.update({ payment_account_status });
          if (!!this.props.onOpen) {
            this.props.onOpen();
          }
          this.props.refresh();
          this.notify("Opération enregister avec succes", "success");
        } else {
          let data = item.user;
          // console.log("data to set=>", data);
          if (!!this.props.onOpen) {
            this.props.onOpen();
          }
          this.notify("Opération enregister avec succes", "success");
          doc.set(data);
        }
      }
    } catch (error) {
      console.log(error.message);
      this.notify(
        "Erreur lors du traitement de la requéte, veuillez réessayer",
        "error"
      );
    }
  };

  render() {
    const { statutPayModal } = this.props;
    const { handleClose, onSubmit } = this;
    let { payment_account_status } = this.state;

    let tab = [];
    let obj = {};
    for (let i = 0; i < Object.keys(PAYMENT_ACCOUNT_STATUS).length; i++) {
      obj = {
        statut: Object.values(PAYMENT_ACCOUNT_STATUS)[i],
      };
      tab = [...tab, obj];
    }

    let displayStatut = (p) => {
      let display = "";
      if (p === undefined) {
        display = "En attente";
      }
      if (p === 1) {
        display = "En cours";
      }
      if (p === 2) {
        display = "Active";
      }
      return display;
    };

    return (
      <Dialog
        maxWidth="sm"
        open={statutPayModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={style.title}>
          Statut Paiement
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ width: "40rem" }}>
            <Row style={{ paddingTop: "2em" }}>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel style={style.label}>
                    Changer le statut
                  </ControlLabel>
                  <FormControl
                    componentClass="select"
                    name="payment_account_status"
                    onChange={(e) => this.onSelectChange(e)}
                    defaultValue={
                      this.props?.item?.user?.payment_account_status ||
                      "En attente"
                    }
                  >
                    {tab.map((p, index) => {
                      return (
                        <option key={index} value={`${p?.statut}`}>
                          {`${displayStatut(p.statut)}`}
                        </option>
                      );
                    })}
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!payment_account_status}
            onClick={onSubmit}
            color="primary"
          >
            Enregister
          </Button>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default ModalAccountStatus;

const style = {
  title: {
    borderBottom: "0.2rem solid",
  },
  label: {
    fontSize: "1.5rem",
  },
  displayLabel: {
    marginTop: "6px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "900",
    color: "black",
    cursor: "pointer",
  },
  aroundDiv: {
    borderColor: "#007bff",
    borderRadius: "35px",
    border: "2px solid rgba(18, 19, 19, 0.75)",
    width: "111px",
    height: "35px",
  },
};
