import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

export class SondageCard extends Component {
    render() {
        return (
            <div className="card card-stats">
                <div className="content">
                    <Row>
                        {/* <Col xs={4}>
                            <div className="icon-big text-center icon-warning">
                                {this.props.bigIcon}
                            </div>
                        </Col> */}
                        <Col xs={12}>
                            <div style={style}>
                                <h5>{this.props.statsText}</h5>
                                {this.props.statsValue}
                            </div>
                        </Col>
                    </Row>
                    <div className="footer">
                        <hr />
                        <div className="stats">
                            {this.props.statsIcon} {this.props.statsIconText}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SondageCard;

const style={
    fontSize:'2em',
    textAlign:'center'
}
