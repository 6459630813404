import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import defaultImg from "../../../assets/img/default-image.jpg";
import { Card } from "components/Card/Card.jsx";
import firebase from "../../../firebase.js";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { getDisplayFormat } from "utils/dates";
export default class Commande extends Component {
  state = {
    data: this.props.location.state,
  };
  componentDidMount() {
    // this._getClient();
  }

  _getClient = async () => {
    const { data } = this.state;
    let { items } = data;
    // console.log("test", data);
    let db = firebase.firestore();
    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      element.user = {};
      if (element?.user_id) {
        try {
          let doc = await db.collection("users").doc(element.user_id).get();
          let store = await doc;
          element.user = store.data();
        } catch (error) {
          console.log(error);
        }
      }
    }
    this.setState({ data: data });
    // console.log("items=>", data);
  };

  getNameCommande(cmd_status) {
    let msg = "";
    console.log("stt", cmd_status);
    if (cmd_status === "commande_arrivées") {
      msg = "Commandes arrivées";
    } else if (cmd_status === "commande_en_cours") {
      msg = "Commandes en cours";
    } else if (cmd_status === "commande_annulee") {
      msg = "Commandes annulées";
    } else if (cmd_status === "commande_terminee") {
      msg = "Commandes terminées";
    } else if (cmd_status === "commande_en_attente") {
      msg = "Commandes en attentes";
    }
    return msg;
  }

  render() {
    console.log(`props=>`, this.props.location.state);
    const { params } = this.props.match;
    let { data } = this.state;
    data?.produit?.items
      .sort(function (a, b) {
        var dateA = new Date(a.date),
          dateB = new Date(b.date);
        return dateA - dateB;
      })
      .reverse();

    //console.log("data test=>", val);
    return (
      <div style={{ minHeight: "80vh" }}>
        <Grid fluid>
          <h3
            className="text-uppercase"
            style={{ justifyContent: "center", display: "flex" }}
          >
            {this.getNameCommande(params.cmd_status)}
          </h3>
        </Grid>
        {data?.produit?.items?.map((item, i) => (
          <CommandeItem
            key={item.id}
            {...item}
            count={data.count}
            total={data.total}
          />
        ))}
      </div>
    );
  }
}

const CommandeItem = (props) => {
  console.log("props commande item=>", props);
  var a = moment(new Date());
  var b = moment(props.date);

  return (
    <React.Fragment>
      <Grid fluid>
        <br />
        <NavLink
          style={{ color: "black" }}
          to={{
            pathname: `/admin/orderDetails/${props.id}`,
          }}
        >
          <Card
            content={
              <Row>
                <Col md={2}>
                  <img
                    alt="img"
                    height="100"
                    width="100"
                    className="img-circle"
                    src={!props.user.photo ? defaultImg : props.user.photo}
                  />
                </Col>
                <Col md={10}>
                  <Row>
                    <Col md={6}>
                      {a.diff(b, "days") > 0 ? (
                        <p className="text-danger">
                          Retard de {a.diff(b, "days")}{" "}
                          {`${a.diff(b, "days") > 1 ? "jours" : "jour"}`}
                        </p>
                      ) : null}

                      <p>Client: {props?.user?.["nom"]}</p>
                      <p>Code: {props?.code}</p>
                      <p>
                        {new Date(props?.date).toLocaleDateString()} |{" "}
                        {getDisplayFormat(props?.interval.fin)}{" "}
                      </p>
                    </Col>
                    <Col md={4}>
                      {/* {props?.produits.map((p, i) =>
                        p?.nom ? (
                          <div key={i}>
                            {`Nom produit: ${p?.nom}`}
                            <p>
                              {' '}
                              {`Prix: ${p?.prix + '€'}`}
                              <p>{`Quantite: ${p?.quantity}`}</p>
                            </p>
                          </div>
                        ) : (
                          ''
                        ),
                      )} */}
                      <p>{`${props?.prix} €`}</p>
                      <label> {props.produits?.length} items</label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          />
        </NavLink>
      </Grid>
    </React.Fragment>
  );
};
