/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  HelpBlock,
} from "react-bootstrap";

function FieldGroup({ label, ...props }) {
  if (props.type === "select") {
    return (
      <FormGroup controlId={props.name}>
        <ControlLabel>{label}</ControlLabel>
        <FormControl {...props} componentClass="select">
          <option value={null}>{label}</option>
          {props?.items?.map((item, index) => {
            return (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </FormControl>
        <HelpBlock className="text-danger">{props.error}</HelpBlock>
      </FormGroup>
    );
  }
  if (props.type === "file") {
    const { value, ...rest } = props;
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl {...rest} />
        <HelpBlock className="text-danger">{props.error}</HelpBlock>
      </FormGroup>
    );
  }
  return (
    <FormGroup>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      <HelpBlock className="text-danger">{props.error}</HelpBlock>
    </FormGroup>
  );
}

export class FormInputs extends Component {
  render() {
    var row = [];
    for (var i = 0; i < this.props.ncols.length; i++) {
      row.push(
        <div key={i} className={this.props.ncols[i]}>
          <FieldGroup {...this.props.properties[i]} />
        </div>
      );
    }
    return <Row>{row}</Row>;
  }
}

export default FormInputs;
