import React, { Component } from "react";
import "./mobiscroll.min.css";

import { cmd_status } from "variables/constants";
// import Calendar from './calendar'
import { Col, Grid, Row } from "react-bootstrap";
import CardOrder from "components/CardOrder/CardOrder";
import moment from "moment";
import LoadSpinner from "views/LoadSpinner";
import firebase from "../../../firebase";
import _ from "lodash";
import Card from "components/Card/Card.jsx";
import Calendar from "react-calendar";
// import 'react-calendar/dist/Calendar.css';
import "./Calendars.css";
import { connect } from "react-redux";
import { fetchRealTimeCmds } from "../../../reducers/commande/actions/commandeAction";

class Agenda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      users: [],
      isloading: false,
      products: [],
      selectedDate: new Date(),
    };
  }

  componentDidMount() {
    this.fetchDataCommandes();
    this.fetchDataUsers();
    this.fetchDataProducts();
    this.props.fetchRealTimeCmds();
  }
  selectedDay = (e) => {
    console.log(e);
    this.setState({ selectedDate: e });
    this.fetchDataCommandes();
    this.fetchDataUsers();
  };

  fetchDataUsers = async () => {
    let db = firebase.firestore();
    let users = [];
    let docs = await db.collection("users").get();
    docs.forEach((doc) => users.push({ ...doc.data(), id: doc.id }));
    this.setState({ users });
  };
  fetchDataProducts = async () => {
    let uid = JSON.parse(sessionStorage.getItem("id_user"));
    let db = firebase.firestore();
    let users = [];
    let docs = await db
      .collection("products")
      .where("boutique_id", "==", uid)
      .get();
    docs.forEach((doc) => users.push({ ...doc.data(), id: doc.id }));
    this.setState({ products: users });
  };

  fetchDataCommandes = async () => {
    this.setState({ isloading: true });
    let db = firebase.firestore();
    let uid = JSON.parse(sessionStorage.getItem("id_user"));
    db.collection("commandes").onSnapshot((qs) => {
      if (!qs.empty) {
        const values = [...this.state.data];
        let commandes = [];
        qs.docChanges().forEach((doc) => {
          const index = values.findIndex((i) => i.id === doc.doc.id);
          if (index === -1) {
            values.push({ ...doc.doc.data(), id: doc.doc.id });
          } else {
            values[index] = { ...values[index], ...doc.doc.data() };
          }
        });
        for (
          let i = 0;
          i < values.filter((cons) => cons.boutique_id === uid).length;
          i++
        ) {
          const element = values.filter((cons) => cons.boutique_id === uid)[i];
          if (
            moment(element.date).isSame(this.state.selectedDate, "year") &&
            moment(element.date).isSame(this.state.selectedDate, "month") &&
            moment(element.date).isSame(this.state.selectedDate, "day")
          ) {
            commandes.push(element);
          }
        }
        this.setState({
          data: _.orderBy(
            commandes.map((item) => {
              item.date2 = item.interval?.debut;
              return item;
            }),
            ["date2", "asc"]
          ),
          isloading: false,
        });
      } else {
        this.setState({
          data: [],
          isloading: false,
        });
      }
    });
  };
  render() {
    return (
      <div className="content" style={{ minHeight: "80vh" }}>
        <Grid
          style={{
            margin: "auto",
            width: "100%",
            padding: "10px",
          }}
        >
          <Row>
            <Col>
              <Card
                content={
                  <Calendar
                    onChange={this.selectedDay}
                    value={this.state.selectedDate}
                    defaultView="month"
                  />
                }
              />
            </Col>
          </Row>
        </Grid>

        <>
          {this.state.isloading ? (
            <Card
              title="Commandes"
              content={
                <div
                  style={{
                    margin: "auto",
                    width: "100%",
                    padding: "10px",
                    justifyContent: "center",
                  }}
                >
                  <LoadSpinner />
                </div>
              }
            />
          ) : (
            <Grid
              fluid
              style={{
                margin: "auto",
                width: "100%",
                padding: "10px",
              }}
            >
              <Row>
                <Col lg={12} sm={12}>
                  <Card
                    content={
                      <CardOrder
                        users={this.state.users}
                        products={this.state.products}
                        commandes={this.state.data.filter(
                          (cmd) => cmd_status.CANCELED !== cmd.status
                        )}
                      />
                    }
                  />
                </Col>
              </Row>
            </Grid>
          )}
        </>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRealTimeCmds: () => dispatch(fetchRealTimeCmds()),
  };
};

const mapStateToProps = (state) => ({
  _getCmds: state.commandes._getCmds,
});

export default connect(mapStateToProps, mapDispatchToProps)(Agenda);
