import Card from "components/Card/Card";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import firebase from "../../firebase.js";
import moment from "moment";
import { Link } from "react-router-dom";
export default class Messages extends Component {
  state = {
    msgs: [],
  };

  componentDidMount() {
    this._getMessages();
  }

  _getMessages = async () => {
    try {
      const db = firebase.firestore();
      let docs = await db.collection("messages").get();
      let msgs = [];
      docs.forEach((doc) => msgs.push({ ...doc.data(), id: doc.id }));
      // console.log("messages", msgs);
      // console.log(
      //   "messages after tried",
      //   msgs.sort((a, b) => b?.date - a?.date)
      // );
      this.setState({
        msgs: msgs.sort((a, b) => b?.date - a?.date),
      });
    } catch (error) {
      console.log(error.message);
    }
  };
  render() {
    const { msgs } = this.state;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={8} mdOffset={2}>
              <Card title="Messages" content={<Message data={msgs} />} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const Message = ({ data }) => {
  console.log("data", data);
  return (
    <React.Fragment>
      {data.map((item) => (
        <div className="card" style={style.card_style} key={item?.id}>
          <div className="card-boby text-dark" style={style.card_style}>
            <h4 className="card-title">
              Objet du message:
              <Link
                to={{
                  pathname: `/admin/message/${item.id}`,
                  state: item,
                }}
              >
                {item?.objet}
              </Link>
            </h4>
            <h5 className="text-muted">Expediteur: {item?.email}</h5>
            <p className="card-text text-secondary">{item?.message}</p>
          </div>
          <div className="card-footer" style={style.card_style}>
            <small className="text-muted">
              {moment(item?.date).format("YYYY/MM/DD hh:mm:ss")}
            </small>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

const style = {
  card_style: {
    marginLeft: "0.3em",
  },
};
