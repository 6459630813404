/* eslint-disable import/no-anonymous-default-export */
import { GET_BOUTIQUE_BY_ID } from "./actions/type"

const initialState = {
    boutiq: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BOUTIQUE_BY_ID:
            console.log("get boutiq=>", action.payload)
            return {
                ...state,
                boutiq: action.payload
            }
        default:
            return state
    }
}