import React from "react";
import "./switch_style.css";

const SwitchToggle = ({ isOn, handleToogle, name, onColor, test }) => {
  console.log("test", test, isOn);
  // console.log(handleToogle);
  return (
    <>
      <input
        checked={isOn}
        name={name}
        onChange={(e) => handleToogle(e)}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        style={{ background: isOn && onColor }}
        className="react-switch-label"
        htmlFor={`react-switch-new`}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default SwitchToggle;
