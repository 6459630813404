import React, { Component } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import PropTypes from "prop-types";
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  // Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "react-bootstrap";
import FormInputs from "components/FormInputs/FormInputs";
import firebase from "../../firebase.js";
import { toast } from "react-toastify";

const RenderPaiement = ({ modePaiement }) => {
  return (
    <React.Fragment>
      {modePaiement?.map((item, index) => (
        <React.Fragment key={index}>{" " + item + " "}</React.Fragment>
      ))}
    </React.Fragment>
  );
};

export const SERVICE = "Services & Loisirs";
export const PF = "P’tite Faim";
export const BE = "Bien être";
export const LS = "Santé";

class DetailsBoutique extends Component {
  state = {
    // fields: {},
    load: false,
    fields: this.props?.detail,
    categorys: [
      { id: 1, nom: SERVICE },
      { id: 2, nom: PF },
      { id: 3, nom: BE },
      { id: 4, nom: LS },
    ],
  };

  componentDidMount() {
    // const { show, detail, onDetail, modePaiement } = this.props;
    // console.log("show=>", this.state.fields);
    // this._getCategorie();
  }

  _getCategorie = async () => {
    try {
      let db = firebase.firestore();
      let all_catg = [];
      let docs = await db.collection("categories").get();
      docs.forEach((doc) => console.log("doc data=>", doc.data()));
      console.log("all categ=>", all_catg);
    } catch (error) {
      console.log(error.code);
    }
  };

  notify = () => {
    toast.success("Produit modifier avec success", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };
  notifyErreur = () => {
    toast.error("Oupps erreur lors de l'envoi, Veuillez reessayer!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };

  editBoutik = async (e) => {
    e.preventDefault();
    let { fields } = this.state;
    this.setState({ load: true });
    console.log("", fields?.id);
    let data = fields;
    try {
      const db = firebase.firestore();
      let boutik = db.collection("boutiques").doc(`${data?.id}`);
      console.log("boutik=>", boutik);
      const doc = await boutik.get();
      if (doc.exists) {
        boutik.update(data);
        this.setState({ load: false });
        this.notify();
        setTimeout(() => {
          if (!!this.props.onDetail) {
            this.props.onDetail();
          }
        }, 3000);
      } else {
        // console.log("je suis dans le else");
        return;
      }
    } catch (error) {
      console.log(error.message);
      this.setState({ load: false });
      this.notifyErreur();
    }
  };

  onChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let { detail } = this.props;
    detail[name] = value;
    this.setState({ fields: detail }, () =>
      console.log(this.state.fields?.categorie)
    );
  };

  render() {
    const { show, detail, onDetail, modePaiement } = this.props;
    const { fields, categorys, load } = this.state;

    let renderCategorys = categorys?.map((item, i) => {
      // const selected = fields?.categorie;
      // // console.log("selected test", selected);
      return (
        <option value={item.nom} key={item.id} id={item.id}>
          {item.nom}
        </option>
      );
    });

    return (
      <React.Fragment>
        <Grid fluid>
          <Modal show={show} onHide={onDetail} backdrop={false}>
            <ModalHeader closeButton>
              <h5>Details Boutique</h5>
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormInputs
                  ncols={["col-md-6", "col-md-6"]}
                  properties={[
                    {
                      name: "nom",
                      label: "Nom Boutique",
                      // readOnly: true,
                      type: "text",
                      bsClass: "form-control",
                      value: fields?.nom || "",
                      onChange: this.onChange,
                    },
                    {
                      label: "Gerant Boutique",
                      // readOnly: true,
                      name: "drigeant",
                      type: "text",
                      bsClass: "form-control",
                      value: fields?.drigeant || "",
                      onChange: this.onChange,
                    },
                  ]}
                />
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Email",
                      readOnly: true,
                      type: "text",
                      bsClass: "form-control",
                      value: fields?.user?.email || "",
                    },
                  ]}
                />
                <FormGroup>
                  <ControlLabel>Category</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    name="categorie"
                    defaultValue={fields?.categorie}
                    onChange={this.onChange}
                  >
                    {/* <option>Choisir une Categorie</option> */}
                    {renderCategorys}
                  </FormControl>
                </FormGroup>
                <FormInputs
                  ncols={["col-md-6", "col-md-6"]}
                  properties={[
                    {
                      label: "Ville",
                      // readOnly: true,
                      type: "text",
                      name: "ville",
                      bsClass: "form-control",
                      value: fields?.ville || "",
                      onChange: this.onChange,
                    },
                    {
                      label: "Telephone",
                      // readOnly: true,
                      type: "text",
                      name: "phone",
                      bsClass: "form-control",
                      value: fields?.phone || "",
                      onChange: this.onChange,
                    },
                  ]}
                />
                <FormInputs
                  ncols={["col-md-6", "col-md-6"]}
                  properties={[
                    {
                      label: "Description",
                      // readOnly: true,
                      type: "text",
                      name: "description",
                      bsClass: "form-control",
                      value: fields?.description || "",
                      onChange: this.onChange,
                    },
                    {
                      label: "Numero Siret",
                      // readOnly: true,
                      type: "text",
                      bsClass: "form-control",
                      name: "numSiret",
                      value: fields?.numSiret,
                      onChange: this.onChange,
                    },
                  ]}
                />
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <span className="control-label">Mode Paiement:</span>
                      <div className="form-control" style={{ height: "auto" }}>
                        {modePaiement?.length !== 0 ? (
                          <RenderPaiement modePaiement={modePaiement} />
                        ) : (
                          "Aucun mode de paiement défini"
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <span className="control-label">Code Postal:</span>
                      <input
                        className="form-control"
                        name="codePostal"
                        // readOnly
                        value={detail?.codePostal}
                        onChange={this.onChange}
                      />
                    </FormGroup>
                  </Col>
                  <div className="row">
                    <Col md={12}>
                      {!load ? (
                        <Button
                          style={style.btn}
                          fill
                          onClick={this.editBoutik}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        <Button style={style.btn} fill>
                          Loading...
                          <i
                            className="fa fa-spin fa-spinner fa-lg"
                            aria-hidden="true"
                          ></i>
                        </Button>
                      )}
                    </Col>
                  </div>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Grid>
      </React.Fragment>
    );
  }
}

export default DetailsBoutique;
DetailsBoutique.propTypes = {
  show: PropTypes.bool.isRequired,
  detail: PropTypes.object,
  onDetail: PropTypes.func.isRequired,
};

const style = {
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    float: "right",
    marginRight: "0.8em",
    borderColor: "#e4393f",
  },
};
