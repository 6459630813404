import Card from "components/Card/Card";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "./cardCss.css";
import fr from "date-fns/locale/el";
import "react-datepicker/dist/react-datepicker.css";
// import getH from "utils";
import { getM, getH } from "utils";
// import CustomToogle from "components/SwitchToogle/CustomToggle";
import Button from "components/CustomButton/CustomButton.jsx";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import firebase from "../../firebase.js";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import LoadSpinner from "views/LoadSpinner";
import Switch from "react-switch";
registerLocale("fr", fr);
toast.configure();
class CardHoraire extends Component {
  state = {
    choiceDate: new Date(),
    horaires: [
      {
        name: "Lundi",
        isClosed: false,
        midi: { debut: 0, fin: 0 },
        soir: { debut: 0, fin: 0 },
      },
      {
        name: "Mardi",
        isClosed: false,
        midi: { debut: 0, fin: 0 },
        soir: { debut: 0, fin: 0 },
      },
      {
        name: "Mercredi",
        isClosed: false,
        midi: { debut: 0, fin: 0 },
        soir: { debut: 0, fin: 0 },
      },
      {
        name: "Jeudi",
        isClosed: false,
        midi: { debut: 0, fin: 0 },
        soir: { debut: 0, fin: 0 },
      },
      {
        name: "Vendredi",
        isClosed: false,
        midi: { debut: 0, fin: 0 },
        soir: { debut: 0, fin: 0 },
      },
      {
        name: "Samedi",
        isClosed: true,
        midi: { debut: 0, fin: 0 },
        soir: { debut: 0, fin: 0 },
      },
      {
        name: "Dimanche",
        isClosed: true,
        midi: { debut: 0, fin: 0 },
        soir: { debut: 0, fin: 0 },
      },
    ],
    showPicker: {
      name: null,
      midi: { debut: false, fin: false },
      soir: { debut: false, fin: false },
    },
    load: false,
    spinner: false,
    ask: false,
  };
  GoBack = () => {
    return this.props.history.push("/admin/maboutique");
  };

  componentDidMount() {
    this._getInfo();
  }

  _getInfo = async () => {
    this.setState({ spinner: true });
    try {
      let db = firebase.firestore();
      let item = {};
      let uid = JSON.parse(sessionStorage.getItem("id_user"));
      let doc = await db.collection("boutiques").doc(uid).get();
      console.log("doc", doc.data());
      this.setState({ spinner: false });
      item = doc.data();
      if (item?.horaires) {
        this.setState({ horaires: item?.horaires });
      }
    } catch (error) {
      console.log(`error response ${error}`);
      this.setState({ spinner: false });
    }
  };

  _onDateChange = (date) => {
    this.setState(
      {
        choiceDate: date,
        // testDate: date
      },
      () => {
        // this._getGrapheByDate(this.state.choiceDate)
      }
    );
  };

  renderPicker = (title, type, dur) => {
    const item = this.state.horaires.find((h) => h.name === title);
    // console.log("dur", dur);
    const value = item[type][dur] || new Date().getTime();

    const ExampleCustomInput = ({ value, onClick, ref }) => {
      // console.log("value", onClick);
      return (
        <button ref={ref} style={style.inputPicker} onClick={onClick}>
          {value}
        </button>
      );
    };
    const ref = React.createRef();
    return (
      <div style={style.datePicker}>
        <DatePicker
          showTimeSelect
          showTimeSelectOnly
          timeCaption="Time"
          timeFormat="HH:mm"
          dateFormat="HH:mm"
          locale="fr"
          selected={value}
          mode="time"
          timeIntervals={15}
          onChange={(selectedDate) =>
            this.dateChange(title, type, dur, selectedDate)
          }
          customInput={<ExampleCustomInput ref={ref} />}
        />
      </div>
    );
  };

  // dayName=jour name
  // dur=duree(debut ou fin)
  // dayMoment=moment de la journee(midi ou soir)
  renderSwitchHours(dayName, dayMoment, dur) {
    // const index = this.state.horaires.findIndex((h) => h.name === dayName);
    // const item = { ...this.state.horaires[index] };
    // console.log("test rek", item[dayMoment]);
    // const showPicker =
    //   this.state.showPicker.name === dayName ? this.state.showPicker : null;
    return (
      <React.Fragment>
        {this.renderPicker(dayName, dayMoment, dur)}
      </React.Fragment>
    );
  }

  dateChange = (title, type, dur, selectedDate) => {
    const time = selectedDate.getTime();
    if (selectedDate) {
      const horaires = [...this.state.horaires];
      const index = horaires.findIndex((h) => h.name === title);
      const item = { ...horaires[index] };
      console.log("item=>", index);
      item[type][dur] = time;
      console.log("item here=>", item[type]);
      const { debut, fin } = item[type];
      if (
        debut &&
        fin &&
        this.getTime(debut) > this.getTime(fin) &&
        dur === "fin"
      ) {
        console.log("je suis la");
        alert(
          "Heure de debut doit étre inferieur à Heure de fin",
          "Heure de debut doit étre inferieur à Heure de fin"
        );
        item[type][dur] = 0;
      }
      horaires[index] = item;
      this.hidePicker(type, dur);
      this.setState({ horaires }, () => {
        console.log("horaires", horaires);
        // if (!!this.props.onChange) {
        //   this.props.onChange(this.state.horaires);
        // }
      });
    }
  };

  getTime(time) {
    return getH(time) * 60 + getM(time);
  }

  hidePicker = (type, dur) => {
    let showP = { ...this.state.showPicker };
    showP[type][dur] = false;
    showP.name = null;
    this.setState({ showPicker: showP });
  };
  showPicker = (title, type, dur) => {
    let showP = { ...this.state.showPicker };
    showP[type][dur] = true;
    showP.name = title;
    this.setState({ showPicker: showP });
  };

  handleToogle = (e, value) => {
    const horaires = [...this.state.horaires];
    const index = horaires.findIndex((f) => f.name === value.name);
    horaires[index].isClosed = e.target.checked;
    this.setState({ horaires });
  };

  notify = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  };

  onSave = async (e) => {
    e.preventDefault();
    this.setState({ load: !this.state.load });
    let uid = JSON.parse(sessionStorage.getItem("id_user"));
    let { horaires } = this.state;
    console.log(horaires);
    try {
      const db = firebase.firestore();
      let boutik = await db.collection("boutiques").doc(uid);
      const doc = await boutik.get();
      if (doc.exists) {
        boutik.update({ horaires });
        this.setState({ load: !this.state.load });
        this.notify("les données ont été ajoutées avec succès");
        setTimeout(() => {
          return this.props.history.push("/admin/maboutique");
        }, 2000);
      }
    } catch (error) {
      console.log(error.message);
      this.setState({ load: !this.state.load });
    }
  };

  renderAsk(name, day) {
    console.log("name ask=>", name);
    return (
      <Col md={4} xs={6} style={{ display: "flex", marginTop: "2.3em" }}>
        <Switch
          value={this.state[name]}
          checked={this.state.ask}
          onChange={(value) => {
            console.log("value=>", value);
            this.ask(value, day);
          }}
        />
      </Col>
    );
  }

  ask(value, day) {
    if (value) {
      const horaires = [...this.state.horaires];
      const h0 = { ...horaires[0] };
      this.setState(
        {
          horaires: horaires.map((h) => ({
            midi: { ...h0.midi },
            soir: { ...h0.soir },
            isClosed: h0.isClosed,
            name: h.name,
          })),
          ask: value,
        }
        // () => {
        //   if (!!this.props.onChange) {
        //     this.props.onChange(this.state.horaires);
        //   }
        // }
      );
    }

    this.setState({ ask: value });
  }

  renderHoraires() {
    const { horaires } = this.state;
    return horaires.map((h, i) => {
      // console.log("format=>", formatTime(1612281600212));
      return (
        <React.Fragment key={i}>
          <Row>
            <p
              style={{
                padding: "2rem 3rem 0.5rem 1rem",
                textAlign: "center",
              }}
            >
              {h.name}
            </p>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <p style={{ padding: "2rem 3rem 0.5rem 1rem" }}>
                    Matin/Midi:
                  </p>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col md={3} xs={6}>
                      {/* {renderCalendar(h?.name, "debut", h?.midi)} */}
                      {this.renderSwitchHours(h.name, "midi", "debut")}
                    </Col>
                    <Col md={3} xs={6}>
                      {this.renderSwitchHours(h.name, "midi", "fin")}
                      {/* {renderCalendar(h?.name, "fin", h?.midi)} */}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p style={{ padding: "2rem 3rem 0.5rem 1rem" }}>
                    Apres-midi/Soir:
                  </p>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col md={3} xs={6}>
                      {this.renderSwitchHours(h?.name, "soir", "debut")}
                    </Col>
                    <Col md={3} xs={6}>
                      {this.renderSwitchHours(h?.name, "soir", "fin")}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={8} xs={6}>
              <p style={{ padding: "2rem 3rem 0.5rem 1rem" }}>Ferme</p>
            </Col>
            <Col md={4} xs={6} style={{ display: "flex", marginTop: "2.3em" }}>
              <Switch
                value={h.isClosed}
                checked={h.isClosed}
                onChange={(e, value) => {
                  const horaires = [...this.state.horaires];
                  horaires[i] = { ...h, isClosed: e };
                  this.setState({ horaires });
                }}
              />
              {/* <CustomToogle
                name={h?.name}
                isOn={h.isClosed}
                handleToogle={(e) => {
                  this.handleToogle(e, h);
                }}
                onColor="rgba(39, 201, 17, 0.788)"
                style={{ padding: "2rem 3rem 0.5rem 1rem" }}
                horaire={h}
              /> */}
            </Col>
          </Row>
          {i === 0 && (
            <Row>
              <Col md={8} xs={6}>
                <p style={{ padding: "2rem 3rem 0.5rem 1rem" }}>
                  alignement des créneaux horaires sur les autres jours ?
                </p>
              </Col>
              {this.renderAsk("ask", h.name)}
            </Row>
          )}
        </React.Fragment>
      );
    });
  }

  render() {
    if (this.state.spinner) {
      return <LoadSpinner />;
    }
    return (
      <div className="content">
        <Grid fluid>
          <Card
            title="Horaires"
            content={
              <>
                <Button style={style.back} fill onClick={this.GoBack}>
                  <ArrowBackIcon />
                </Button>
                {this.renderHoraires()}
                {!this.state.load ? (
                  <Button style={style.btn} fill onClick={this.onSave}>
                    Envoyer
                  </Button>
                ) : (
                  <Button style={style.btn} fill>
                    Chargement...
                    <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>
                  </Button>
                )}
                <div className="clearfix"></div>
              </>
            }
          />
        </Grid>
      </div>
    );
  }
}
export default CardHoraire;

const style = {
  datePicker: {
    backgroundColor: "#FFFFFF",
    boxShadow: " 0px 5px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "100px",
    fontFamily: "Poppins sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "19px",
    lineHeight: "21px",
    letterSpacing: " 0.4px",
    color: "#252733",
    padding: " 0.5rem 3rem 0.5rem 1rem",
    border: "1px solid transparent",
    width: "110px",
    appearance: "none",
    marginTop: "1.1em",
  },
  inputPicker: {
    cursor: "pointer",
    padding: "5px 15px",
    border: 0,
    borderRadius: " 4px",
    backgroundColor: "#fff",
    font: "inherit",
    color: "black",
  },
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    float: "left",
    marginRight: "0.8em",
    borderColor: "#e4393f",
    width: "25%",
  },
  back: {
    backgroundColor: "none",
    float: "left",
    marginRight: "0.8em",
    borderColor: "#888888",
    width: "10%",
  },
};
