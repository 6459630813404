import React, { Component } from 'react'
import Card from 'components/Card/Card'
import firebase from '../../firebase.js'
import { Col, Grid, Row } from 'react-bootstrap'
import { Modal, Button } from 'rsuite';

export default class DetailMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {
        show: false
    }
  }

  close = () => {
    this.setState({ show: false });
  }
  open = () => {
    this.setState({ show: true });
  }

  onDelete = async () => {
      this.setState({ show: false });
      console.log('id', this.props.match.params?.id)
      const db = firebase.firestore();
      await db
        .collection("messages")
        .doc(this.props.match.params?.id)
        .delete().then((res) => {
            window.location.pathname = '/admin/messages'
        }).catch((e) => {
            console.log('error', e)
        })
  }

  render() {
      //console.log('object', window.location)
    return (
      <div className="content" style={{minHeight: '80vh'}} >
        <Grid fluid>
          <Row>
            <Card
              title={this.props.location.state.email}
              content={
                <>
                  <Col md={12} style={{display: "flex", flexDirection: "row", fontWeight: "bold", fontFamily: "monospace", marginBottom: "10px"}} >
                    Objet: {this.props.location.state.objet}
                  </Col>
                  <Col
                    style={{
                      width: '100%',
                      margin: 'auto',
                      padding: '15px',
                      fontFamily: "sans-serif",
                      fontSize: "20px",
                      marginBottom: "10px"
                    }}
                  >
                    {this.props.location.state.message}
                  </Col>
                  <Col md={12} style={{marginTop: "-5px", textAlign: "end"}} >
                      <button className="btn btn-danger justify-content-end mb-auto" onClick={this.open}>Supprimer</button>
                  </Col>
                </>
              }
            />
          </Row>
        </Grid>
        <Modal size='xs' show={this.state.show} onHide={this.close}>
          <Modal.Header>
            <Modal.Title style={{fontSize: '20px', textAlign: 'center', color: 'red'}} >Attention !!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 style={{fontSize: '24px', textAlign: 'center'}}>Êtes-vous sùr de vouloir supprimer ce message ?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onDelete} color="green">
              Oui
            </Button>
            <Button onClick={this.close} color="red">
              Non
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
