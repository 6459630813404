import StatsCard from "components/StatsCard/StatsCard";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LoadSpinner from "views/LoadSpinner.jsx";
import { connect } from "react-redux";
import {
  _getAllCommandes,
  getOrdersProducts,
} from "../../reducers/commande/actions/commandeAction";
class DashboardCommercant extends Component {
  state = {
    spinner: false,
  };

  componentDidMount() {
    this.props._getAllCommandes();
    this.props.getOrdersProducts();
  }

  // componentDidUpdate(prevProps) {
  //   console.log(
  //     "did update arrivals",
  //     this.props.commands.arrival_orders?.enAttentes?.count
  //   );
  //   console.log(
  //     "did update cancel",
  //     this.props.commands.cancel_orders?.cancel?.count
  //   );
  // }

  render() {
    let { spinner } = this.state;
    // console.log(
    //   "commandes arrivees=>",
    //   this.props.commands.arrival_orders?.enAttentes?.count
    // );
    // console.log(
    //   "commandes annulees=>",
    //   this.props.commands.cancel_orders.cancel?.count
    // );
    let {
      arrival_orders,
      in_progress_orders,
      pending_orders,
      finish_orders,
      cancel_orders,
    } = this.props.commands;

    // console.log("arrival_orders=>", arrival_orders);

    if (spinner && Object.keys(this.props.commands).length !== 0) {
      return (
        <div style={{ minHeight: "80vh" }}>
          <LoadSpinner />
        </div>
      );
    }
    return (
      <div className="content" style={{ minHeight: "80vh" }}>
        <Grid fluid>
          <Row>
            <Col lg={4} sm={6}>
              <NavLink
                to={{
                  pathname: `/admin/commande/commande_arrivées`,
                  state: { produit: arrival_orders?.enAttentes },
                }}
                style={{ color: "black" }}
              >
                <StatsCard
                  bigIcon={<i className="pe-7s-cart" />}
                  statsText={
                    arrival_orders?.enAttentes?.count > 1
                      ? "Commande arrivées"
                      : "Commande arrivée"
                  }
                  statsValue={arrival_orders?.enAttentes?.count}
                  statsIcon={<i className="pe-7s-cart" />}
                  statsIconText={
                    "Montant cumulé: " + arrival_orders?.enAttentes?.total + "€"
                  }
                />
              </NavLink>
            </Col>
            <Col lg={4} sm={6}>
              <NavLink
                style={{ color: "black" }}
                to={{
                  pathname: `/admin/commande/commande_en_cours`,
                  state: { produit: in_progress_orders?.enCours },
                }}
              >
                <StatsCard
                  bigIcon={<i className="pe-7s-cart" />}
                  statsText={"Commande en cours"}
                  statsValue={in_progress_orders?.enCours?.count || 0}
                  statsIcon={<i className="fa fa-refresh" />}
                  statsIconText={
                    "Montant cumulé: " + in_progress_orders?.enCours.total + "€"
                  }
                />
              </NavLink>
            </Col>
            <Col lg={4} sm={6}>
              <NavLink
                style={{ color: "black" }}
                to={{
                  pathname: `/admin/commande/commande_en_attente`,
                  state: { produit: pending_orders?.attenteLiv },
                }}
              >
                <StatsCard
                  bigIcon={<i className="pe-7s-cart" />}
                  statsText={
                    pending_orders?.attenteLiv?.count > 1
                      ? "Commande en attentes"
                      : "Commande en attente"
                  }
                  statsValue={pending_orders?.attenteLiv?.count || 0}
                  statsIcon={<i className="pe-7s-cart" />}
                  statsIconText={
                    "Montant cumulé: " +
                      pending_orders?.attenteLiv?.total +
                      "€" || 0 + "€"
                  }
                />
              </NavLink>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <NavLink
                style={{ color: "black" }}
                to={{
                  pathname: `/admin/commande/commande_terminee`,
                  state: { produit: finish_orders?.finish },
                }}
              >
                <StatsCard
                  bigIcon={<i className="pe-7s-cart" />}
                  statsText={
                    finish_orders?.finish.count > 1
                      ? "Commande terminées"
                      : "Commande terminée"
                  }
                  statsValue={finish_orders?.finish?.count || 0}
                  statsIcon={<i className="pe-7s-cart" />}
                  statsIconText={
                    "Montant cumulé: " + finish_orders?.finish.total + "€" ||
                    0 + "€"
                  }
                />
              </NavLink>
            </Col>
            <Col md={6}>
              <NavLink
                style={{ color: "black" }}
                to={{
                  pathname: `/admin/commande/commande_annulee`,
                  state: { produit: cancel_orders?.cancel },
                }}
              >
                <StatsCard
                  bigIcon={<i className="pe-7s-cart" />}
                  statsText={
                    cancel_orders?.cancel.count > 1
                      ? "Commande annulées"
                      : "Commande annulée"
                  }
                  statsValue={cancel_orders?.cancel.count || 0}
                  statsIcon={<i className="pe-7s-cart" />}
                  statsIconText={
                    "Montant cumulé: " + cancel_orders?.cancel.total + "€" ||
                    0 + "€"
                  }
                />
              </NavLink>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12} mdOffset={4} sm={12} lg={12}>
              <button
                style={style.agBtn}
                onClick={() => this.props.history.push("/admin/agenda")}
              >
                MON AGENDA
              </button>
            </Col>
          </Row> */}
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    _getAllCommandes: () => dispatch(_getAllCommandes()),
    getOrdersProducts: () => dispatch(getOrdersProducts()),
  };
};

const mapStateToProps = (state) => ({
  all_cmds: state.commandes.items,
  commands: state.commandes.commands,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardCommercant);
