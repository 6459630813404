import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "./Stats.css";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import CardStats from "components/CardStats/CardStats";
import firebase from "../../../firebase";
import { Col, Grid, Row } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import CardStatsYear from "components/CardStatsYear/CardStatsYear";
import CardStatsMonth from "components/CardStatsMonth/CardStatsMonth";
import moment from "moment";
import LoadSpinner from "views/LoadSpinner";
import CardZero from "components/CardZero/CardZero";
import { cmd_status } from "variables/constants";

registerLocale("fr", fr);
export default class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      startYear: new Date(),
      data: [],
      consultations: [],
      consultationsMonth: [],
      consultationsYear: [],
      commandes: [],
      userAbonnement: {},
      isloading: false,
    };
  }

  componentDidMount() {
    this._getInfo();
    setTimeout(() => {
      this.fetchDataConsultation();
      this.fetchDataCommandes();
    }, 2000);
  }

  _getInfo = async () => {
    this.setState({ isloading: true });
    try {
      let db = firebase.firestore();
      let uid = JSON.parse(sessionStorage.getItem("id_user"));
      await db
        .collection("users")
        .doc(uid)
        .get()
        .then((res) => {
          //console.log('user', res.data())
          this.fetchAbonnement(res.data().abonnement);
        });
    } catch (error) {
      console.log(`error response ${error}`);
    }
  };

  fetchAbonnement = async (id) => {
    try {
      // console.log(id)
      const db = firebase.firestore();
      let abonnements = [];
      await db
        .collection("abonnements")
        .get()
        .then((docs) => {
          docs.forEach((d) => {
            abonnements.push({ ...d.data(), id: d.id });
          });
          // console.log(
          //   `data get =>`,
          //   abonnements,
          //   abonnements.filter((ab) => ab.id === id),
          // )
          this.setState({
            userAbonnement: abonnements.filter((ab) => ab.id === id)[0],
          });
        });
    } catch (error) {
      console.log(`error=>${error.code}`);
      //this.setState({ loading: !this.state.loading });
    }
  };

  fetchDataConsultation = async () => {
    this.setState({ isloading: true });
    let db = firebase.firestore();
    let data = [];
    let consultationsMonth = [];
    let consultationsYear = [];
    let uid = JSON.parse(sessionStorage.getItem("id_user"));
    let docs = await db.collection("consultations").get();
    docs.forEach((doc) => data.push({ ...doc.data() }));
    /* console.log(
      'docs',
      data.filter((cons) => cons.boutique_id === uid),
    ) */
    for (
      let i = 0;
      i < data.filter((cons) => cons.boutique_id === uid).length;
      i++
    ) {
      const element = data.filter((cons) => cons.boutique_id === uid)[i];
      if (moment(element.date).isSame(this.state.startYear, "year")) {
        consultationsYear.push(element);
        if (moment(element.date).isSame(this.state.startDate, "month")) {
          consultationsMonth.push(element);
        }
      }
      //   console.log('year', consultationsYear)
      //   console.log('Month', consultationsMonth)
    }
    this.setState({
      consultations: data.filter((cons) => cons.boutique_id === uid),
      consultationsMonth,
      consultationsYear,
      isloading: false,
    });
  };

  setStartDate = (date) => {
    // console.log('date', date)
    this.setState({
      startDate: date,
    });
    this.fetchDataConsultation();
    this.fetchDataCommandes();
  };

  setStartYear = (date) => {
    console.log("date", date);
    this.setState({
      startYear: date,
    });
    this.fetchDataConsultation();
    this.fetchDataCommandes();
  };

  fetchDataCommandes = async () => {
    let db = firebase.firestore();
    let values = [];
    let commandes = [];
    let uid = JSON.parse(sessionStorage.getItem("id_user"));
    let docs = await db.collection("commandes").get();
    docs.forEach((doc) => values.push({ ...doc.data() }));
    //console.log('values', values )
    for (
      let i = 0;
      i <
      values.filter(
        (cons) =>
          cons.boutique_id === uid && cons.status === cmd_status.TERMINEES
      ).length;
      i++
    ) {
      const element = values.filter(
        (cons) =>
          cons.boutique_id === uid && cons.status === cmd_status.TERMINEES
      )[i];
      console.log("element", element);
      if (
        moment(element?.date).isSame(this.state.startYear, "year") &&
        moment(element?.date).isSame(this.state.startDate, "month")
      ) {
        commandes.push(element);
      }
    }
    console.log("commandes", commandes);
    this.setState({ commandes });
  };

  render() {
    //console.log('userAbonnement', this.state.userAbonnement)

    if (!this.state.isloading) {
      return (
        <div className="content" style={{ minHeight: "80vh" }}>
          {this.state.userAbonnement.amount > 0 ? (
            <>
              <Grid
                style={{
                  maxWidth: "500px",
                  margin: "auto",
                  width: "100%",
                  padding: "10px",
                }}
              >
                <Row>
                  <Col lg={6} sm={6}>
                    <DatePicker
                      className="form-control select-filter-dashboard text-center"
                      selected={this.state.startDate}
                      onChange={(date) => this.setStartDate(date)}
                      dateFormat="MMMM"
                      showMonthYearPicker
                      showFullMonthYearPicker
                      locale={fr}
                    />
                  </Col>
                  <Col lg={6} sm={6}>
                    <DatePicker
                      className="form-control select-filter-dashboard text-center"
                      selected={this.state.startYear}
                      onChange={(date) => this.setStartYear(date)}
                      showYearPicker
                      dateFormat="yyyy"
                    />
                  </Col>
                </Row>
              </Grid>

              <Grid
                fluid
                style={{
                  maxWidth: "500px",
                  margin: "auto",
                  width: "100%",
                  padding: "10px",
                }}
              >
                <Row>
                  <Col lg={12} sm={12}>
                    <CardStatsMonth
                      consultationsMonth={this.state.consultationsMonth}
                    />
                  </Col>
                  <Col lg={12} sm={12}>
                    <CardStatsYear
                      consultationsYear={this.state.consultationsYear}
                    />
                  </Col>
                  <Col lg={12} sm={12}>
                    <CardStats commandes={this.state.commandes} />
                  </Col>
                </Row>
              </Grid>
            </>
          ) : (
            <Grid
              style={{
                maxWidth: "500px",
                margin: "auto",
                width: "100%",
                height: "100%",
                padding: "10px",
                maxHeight: "500px",
              }}
            >
              <Row>
                <Col>
                  <CardZero
                    text={
                      "Fonction statistique non disponible avec cet abonnement."
                    }
                  />
                </Col>
              </Row>
            </Grid>
          )}
        </div>
      );
    } else {
      return (
        <Card
          content={
            <div className="content" style={{ minHeight: "70vh" }}>
              <LoadSpinner />
            </div>
          }
        />
      );
    }
  }
}
