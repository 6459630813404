import React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import CardAbonnement from "../Abonnement/CardAbonnement";
import IconBg from "../../../assets/img/iconBg.png";
import Switch from "react-switch";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { PAYMENT_ACCOUNT_STATUS } from "variables/constants";
import firebase from "../../../firebase.js";
import Button from "../../../components/CustomButton/CustomButton";
import { toast, cssTransition } from "react-toastify";
import "../test.css";
toast.configure();
const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
});

const Paiement = () => {
  const [user, setUser] = React.useState({});
  const [onlinePayment, setOnlinePayment] = React.useState(undefined);
  const [load, setLoad] = React.useState(false);

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let uid = user["uid"];
        console.log("uid=>", uid);
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .get()
          .then((doc) => {
            console.log("user=>", doc.data());
            setUser(doc.data());
            if (doc.data().onlinePayment === true) {
              setOnlinePayment(doc.data().onlinePayment);
              //   console.log("online pay=>", onlinePayment);
            }
          })
          .catch((error) => console.log("error code=>", error.code));
      }
    });
  }, []);

  const notify = (msg, type) => {
    if (type === "success")
      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        transition: Zoom,
      });
    if (type === "error")
      toast.error(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        transition: Zoom,
      });
  };

  const DisplayText = () => {
    return (
      <>
        <Row>
          <Col md={6} mdOffset={4} smOffset={4} xs={12} xsOffset={4}>
            <p style={{ color: " #f83f4b" }}>Gagnez en visibilité!</p>
            <br />
          </Col>
          <Col md={6} mdOffset={4} smOffset={4} xs={6} xsOffset={4}>
            <p style={style.para}>
              {" "}
              Une application locale pour renforcer les liens avec les habitants
              de la commune.
            </p>
          </Col>
        </Row>
      </>
    );
  };
  const DisplayPara = ({ user }) => {
    console.log("test content user=>", user);
    const getStatut = (user) => {
      //   console.log("Display statut=>", PAYMENT_ACCOUNT_STATUS.EN_COURS);
      let statut = "";
      if (user.payment_account_status === PAYMENT_ACCOUNT_STATUS.DEFAULT) {
        statut = "NC";
      }
      if (
        user.payment_account_status === String(PAYMENT_ACCOUNT_STATUS.EN_COURS)
      ) {
        statut = "En cours";
      }
      if (
        user.payment_account_status === String(PAYMENT_ACCOUNT_STATUS.ACTIVE)
      ) {
        statut = "Active";
      }
      return statut;
    };

    const onSubmit = async (e) => {
      e.preventDefault();
      console.log("online paiement", onlinePayment);
      setLoad(!load);
      try {
        const db = firebase.firestore();
        let user = await db
          .collection("users")
          .doc(firebase.auth().currentUser.uid);
        let doc = await user.get();
        if (doc.exists) {
          user.update({ onlinePayment });
          notify("Opération enregister avec succes", "success");
          setLoad(false);
        }
      } catch (error) {
        console.log("error", error.code);
        setLoad(false);
        notify(
          "Erreur lors de l'execution de la requete, veuillez réessayer",
          "error"
        );
      }
    };

    return (
      <div style={{ paddingTop: "78px" }}>
        <Row>
          <Col md={6} mdOffset={4} smOffset={4} xs={12} xsOffset={4}>
            <p style={style.para}>
              <CreditCardIcon style={{ fontSize: "32px" }} /> Paiement intégré à
              l'application?
            </p>
            <Row>
              <Col md={6}>
                <p style={style.para}>Activation</p>
              </Col>
              <Col
                md={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Switch
                  checked={onlinePayment}
                  onChange={() => {
                    setOnlinePayment(!onlinePayment);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={6}>
                <p style={style.para}>Statut</p>
              </Col>
              <Col md={6}>
                <div
                  style={style.aroundDiv}
                  className={`${
                    user.payment_account_status ===
                    PAYMENT_ACCOUNT_STATUS.DEFAULT
                      ? "bg_white"
                      : user.payment_account_status ===
                        PAYMENT_ACCOUNT_STATUS.EN_COURS
                      ? "bg_jaune"
                      : "bg_green"
                  }`}
                >
                  <label style={style.displayLabel}>{getStatut(user)}</label>
                </div>
              </Col>
            </Row>
            <br />
          </Col>
          <Col md={6} mdOffset={4} smOffset={4} xs={6} xsOffset={4}>
            <p style={style.para}>
              {" "}
              En activant le paiement intégré, l’utilisateur pourra payer de
              manière sécurisé directement sur ÔbouduCLIC. Pour vous, une
              garantie supplémentaire d’une commande confirmée et payée. Des
              frais (y compris frais bancaires) de 6% seront prélevés sur chaque
              transaction payée sur Ôbouduclic. Le règlement de la commande vous
              sera versé sous 3j maximum
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} mdOffset={5} smOffset={4} xs={6} xsOffset={4}>
            {!load ? (
              <Button
                disabled={onlinePayment === undefined ? false : null}
                style={style.btn}
                fill
                onClick={onSubmit}
              >
                Valider mon choix
              </Button>
            ) : (
              <Button style={style.btn} fill>
                Chargement...
                <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col mdOffset={1}>
            <CardAbonnement
              title="Réglage paiement"
              content={
                <React.Fragment>
                  <Row>
                    <img
                      alt=""
                      src={IconBg}
                      style={{ width: "90%", marginLeft: "1em" }}
                    />
                    <DisplayText />
                    {/* {!this.state.loading ? <DisplayText /> : null} */}
                  </Row>
                  <DisplayPara user={user} />
                </React.Fragment>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Paiement;

const style = {
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    color: "#ffffff",
    marginRight: "0.8em",
    borderColor: "#e4393f",
    margin: "1em 0em auto -3em",
  },
  para: {
    color: "#000000",
    textAlign: "justify",
    fontSize: "large",
  },
  aroundDiv: {
    borderColor: "#007bff",
    borderRadius: "35px",
    border: "2px solid rgba(18, 19, 19, 0.75)",
    width: "111px",
    height: "35px",
  },
  label: {
    fontSize: "1.5rem",
  },
  displayLabel: {
    marginTop: "6px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "900",
    color: "black",
    cursor: "pointer",
  },
};
