import Card from "components/Card/Card";
import React, { Component } from "react";
import { Col, Grid, Row, Table } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import firebase from "firebase";
// import ItemBordereau from './ItemBordereau'
export default class Bordereau extends Component {
  state = { data: [], show: false, bool: false };
  async componentDidMount() {
    try {
      let db = firebase.firestore();
      let data = [];
      let ville = [];
      let docs = await db.collection("boutiques").get();
      docs.forEach((doc) => data.push({ ...doc.data(), id: doc.id }));
      for (const v of data) {
        if (!ville.includes(v.ville)) {
          ville.push(v.ville);
        }
      }
      ville = ville.filter((v) => v !== undefined || v != null);
      this.setState({ data: ville });
      // console.log(ville)
    } catch (error) {
      console.log(error.message);
    }
  }

  onRedirect = (v) => {
    this.props.history.push("/admin/details-bordereau/" + v);
  };

  render() {
    let { data } = this.state;
    // let bool = false;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Bordereau Ville"
                content={
                  <Table>
                    <thead>
                      <tr>
                        <th>Ville</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((v) => {
                        return (
                          <tr key={v}>
                            <td>{v}</td>
                            <td>
                              <Button
                                fill
                                style={style.btn}
                                onClick={(e) => this.onRedirect(v)}
                              >
                                Lien contenu ville
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const style = {
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    // float: 'right',
    marginRight: "0.8em",
    borderColor: "#e4393f",
  },
};
