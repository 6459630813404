import Card from "components/Card/Card";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import defaultImg from "../../../assets/img/default-image.jpg";
export default class FinalCommande extends Component {
  render() {
    console.log("props from commdande=>", this.props.location.state);
    const btn = [
      {
        text: "Terminer la commande et la donner au client",
        style: {
          color: "black",
          backgroundColor: "#0b610b",
          borderColor: "#0b610b",
          width: "340px",
          fontWeight: "bold",
        },
      },
      {
        text: "Terminer la commande et la mettre en attente",
        style: {
          color: "black",
          backgroundColor: "#F0A400",
          borderColor: "#F0A400",
          width: "340px",
          fontWeight: "bold",
        },
      },
      {
        text: "Annuler et envoyer une notification",
        style: {
          color: "black",
          backgroundColor: "#f83d48",
          borderColor: "#f83d48",
          width: "340px",
          fontWeight: "bold",
        },
      },
    ];
    return (
      <div className="content">
        <Grid>
          <Card
            content={
              <React.Fragment>
                <InfoComande />
                {btn.map((item, i) => {
                  return (
                    <ComponentButton
                      key={i}
                      text={item.text}
                      css={item.style}
                    />
                  );
                })}
                <br />
                <Row>
                  <Col md={6}>
                    <h4>Informations du client</h4>
                    <p>Nom utilisateur:</p>
                    <p>Telephone:</p>
                  </Col>
                </Row>
              </React.Fragment>
            }
          />
        </Grid>
      </div>
    );
  }
}

const ComponentButton = (props) => {
  //   console.log("props=>", props);
  return (
    <Row>
      <br />
      <Col md={4} mdOffset={3} xs={4}>
        <Button style={props.css}>{props.text}</Button>
      </Col>
    </Row>
  );
};

const InfoComande = () => {
  return (
    <React.Fragment>
      <Row>
        <Col md={6} mdOffset={3}>
          <div className="col-md-3">
            <img
              alt="img"
              height="100"
              width="100"
              className="img-circle"
              src={defaultImg}
            />
          </div>
          <div
            className="col-md-6"
            style={{ display: "flex", paddingTop: "4em" }}
          >
            <div>
              <p style={{ color: "red" }}>Nom produit ({"prix"})</p>
              <p>Description Produit</p>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const style = {
  button: {
    color: "black",
    backgroundColor: "#0b610b",
    borderColor: "#0b610b",
  },
};
