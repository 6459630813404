import React, { Component } from "react";
// import defaultImg from "./../../../assets/img/default-image.jpg";
import $ from "jquery";
import firebase from "../../../firebase.js";
import { toast } from "react-toastify";

toast.configure();
const storage = firebase.storage();
export default class UserCardImg extends Component {
  state = { photoUrl: this.props.avatar, load: false };
  componentDidMount() {
    // this.uploadProfilPic();
  }

  uploadProfilPic = () => {
    $(function () {
      var fileupload = $("#FileUpload");
      var image = $("#profilImg");
      image.click(function () {
        fileupload.click();
      });
    });
  };

  notify = (msg, type) => {
    if (type === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  onImageChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file !== null) {
      this.setState(
        {
          photo: file,
          disabledBtn: true,
        },
        () => console.log("photo", this.state?.photo, typeof file)
      );
      await this.sendImage(file);
    }
  };

  sendImage = async (file) => {
    this.setState({ load: !this.state.load });
    try {
      const storageRef = storage.ref(`users/`);
      const fileRef = storageRef.child(file.name);
      await fileRef.put(file);
      await fileRef.getDownloadURL().then(async () => {
        console.log(this.state.photoUrl);
        let photoUrl = await fileRef.getDownloadURL();
        this.setState({ photoUrl: photoUrl });
        firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser.uid)
          .update({ photo: photoUrl })
          .then(() => {
            this.setState({ load: !this.state.load });
            this.notify("Operation avec succes", "success");
          })
          .catch((error) => {
            console.log(error.code);
            this.notify(error.code, "error");
            this.setState({ load: !this.state.load });
          });
      });
    } catch (error) {
      console.log(error.code);
      this.notify(error.code, "error");
      this.setState({ load: !this.state.load });
    }
  };

  render() {
    this.uploadProfilPic();
    // console.log("props avatar", this.props?.avatar);
    // console.log("state avatar", this.state.photoUrl);
    return (
      <div className="card card-user">
        <div className="image" style={{ height: "126px" }}>
          {/* <img src={this.props.bgImage} alt="..." /> */}
        </div>
        <div className="content">
          <div className="author">
            <a href="#pablo">
              <img
                style={{
                  border: "2px solid #FFF",
                  height: "150px",
                  width: "150px",
                }}
                className="avatar border-gray"
                src={this.state.photoUrl}
                alt="..."
                id="profilImg"
                title="Changer de photo"
              />
              {/* <div className="col-md"> */}
              <input
                onChange={this.onImageChange}
                name="photo"
                style={{ display: "none" }}
                type="file"
                id="FileUpload"
              />
              {/* </div> */}
              <h4 className="title">
                {this.props.name}
                <br />
                <small>{this.props.userName}</small>
              </h4>
            </a>
          </div>
          <p className="description text-center">{this.props.description}</p>
        </div>
        <hr />
        <div className="text-center">{this.props.socials}</div>
      </div>
    );
  }
}
