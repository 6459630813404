import { TextField } from "@material-ui/core";
import Card from "components/Card/Card";
// import FormInputs from "components/FormInputs/FormInputs.jsx";
import TextAreaInput from "components/TextAreaInput/TextAreaInput.jsx";
import React, { Component } from "react";
import { Col, Form, FormGroup, Grid, Row } from "react-bootstrap";
import { toDayDateTime } from "utils.js";
import Button from "../../components/CustomButton/CustomButton.jsx";
import firebase from "../../firebase.js";
import { toast, cssTransition } from "react-toastify";
import "./test.css";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
});
export default class Suggestion extends Component {
  state = {
    objet: "",
    message: "",
    email: "",
    errors: {},
    load: false,
  };

  async componentDidMount() {
    this.isConnected();
  }

  isConnected = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // console.log(`user`, user);
        this.setState({ email: user["email"] });
      }
    });
  };

  sendMessage(objet, message, email) {
    return firebase.firestore().collection("messages").add({
      objet,
      email,
      message,
      date: toDayDateTime(),
    });
  }

  notifyErreur = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
      transition: Zoom,
    });
  };

  notifySuccess = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
      transition: Zoom,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ load: true, errors: {} });
    const { message, objet, email } = this.state;
    const userMail = email;
    // console.log(userMail);
    const errorEmail = this.validateEmail(userMail);
    const errorMessage = this.validateMessage(message);
    const errorObjet = this.validateObjet(objet);
    if (!errorEmail && !errorMessage && !errorObjet) {
      try {
        await this.sendMessage(objet, message, userMail);
        this.notifySuccess("votre message a été envoyé avec succès");
        this.setState({ message: "", objet: "" });
      } catch (error) {
        console.log(`error=>`, error);
        this.setState({ load: true });
        this.notifyErreur(error);
      }
    } else {
      console.log("je suis ici ok");
      this.setState({
        errors: {
          email: errorEmail,
          objet: errorObjet,
          message: errorMessage,
        },
      });
    }
    this.setState({ load: false });
  };

  onChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  validateEmail(email) {
    console.log("email=>", email);
    if (
      // eslint-disable-next-line no-useless-escape
      !email.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)
    ) {
      return "Veuillez saisir un email valide";
    }
    return null;
  }
  validateMessage(message) {
    const trimed = message.trim();
    if (trimed.length < 25 || trimed.length > 255) {
      return "ce champ doit contenir entre 25 et 255 caractères";
    }
    return null;
  }
  validateObjet(objet) {
    const trimed = objet.trim();
    if (trimed.length < 5 || trimed.length > 255) {
      return "ce champ doit contenir entre 5 et 255 caractères";
    }
    return null;
  }

  renderErrorFor(field) {
    return <p style={style.invalidFeedback}>{this.state.errors[field]}</p>;
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Card
            title="Suggestion"
            content={
              <Col smOffset={3} mdOffset={3}>
                <Form>
                  <Row>
                    <Col md={6} sm={6}>
                      <FormGroup>
                        <TextField
                          type="text"
                          label="Objet"
                          value={this.state.objet || ""}
                          error={this.state.errors["objet"] ? true : false}
                          name="objet"
                          variant="outlined"
                          fullWidth
                          onChange={this.onChange}
                          id="outlined-error-helper-text"
                          helperText={
                            this.state.errors["objet"]
                              ? this.state.errors?.objet
                              : null
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <TextAreaInput
                    ncols={["col-md-6"]}
                    properties={[
                      {
                        label: "Message",
                        type: "text",
                        value: this.state.message || "",
                        bsClass: "form-control",
                        placeholder: "Votre message",
                        name: "message",
                        onChange: this.onChange,
                      },
                    ]}
                  />
                  {this.state.errors["message"]
                    ? this.renderErrorFor("message")
                    : ""}
                  <Row>
                    <Col md={6} sm={6}>
                      {!this.state.load ? (
                        <Button
                          style={style.btn}
                          fill
                          block
                          onClick={this.onSubmit}
                        >
                          Envoyer
                        </Button>
                      ) : (
                        <Button style={style.btn} fill block>
                          Loading...
                          <i
                            className="fa fa-spin fa-spinner fa-lg"
                            aria-hidden="true"
                          ></i>
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Col>
            }
          />
        </Grid>
      </div>
    );
  }
}

const style = {
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    borderColor: "#e4393f",
  },
  invalidFeedback: {
    color: "#f44336",
    marginLeft: "14px",
    marginRight: "14px",
    fontSize: "0.75rem",
    marginTop: "3px",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
  },
};

// const TexArea = () => {
//   const containerStyle = {
//     width: 325,
//     height: 150,
//   };
//   const textFieldStyle = {
//     marginTop: 8,
//     width: "100%",
//     height: "calc(100% - 8px)",
//   };
//   const inputStyle = {
//     width: "100%",
//     height: "100%",
//   };
//   const props = {
//     style: textFieldStyle,
//     // label: "derive rows from height",
//     label: "Message",
//     multiline: true,
//     variant: "outlined",
//     rows: containerStyle.height / 21,
//     // InputProps: { style: inputStyle },
//   };
//   return (
//     <div style={containerStyle}>
//       <FormGroup bsSize="large" componentClass="textarea">
//         {" "}
//         <TextField rowsMax="4" {...props} />
//       </FormGroup>
//     </div>
//   );
// };
