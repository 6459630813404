import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
export default function CGVDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const style = {
    borderRadius: "20px",
    border: "1px solid #ff4b2b",
    background: "#ff4b2b",
    color: "#fff",
    fontSize: "9px",
    fontWeight: "bold",
    padding: "12px 45px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    transition: "transform 80ms ease-in",
  };

  const handleClose = () => {
    if (!!props.openModal) {
      props.openModal();
    }
  };
  return (
    <div>
      <Dialog fullScreen={fullScreen} open={props.open} onClose={handleClose}>
        <DialogTitle id="responsive-dialog-title">
          {"Mention légales,CGV"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{ReactHtmlParser(props.cgv)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={style}>
            Ok, j'accepte
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
