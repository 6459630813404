import Card from "components/Card/Card";
import FormInputs from "components/FormInputs/FormInputs";
import React, { Component } from "react";
import { Col, Form, Grid } from "react-bootstrap";
import "../Boutiques/boutik_style.css";
import Button from "components/CustomButton/CustomButton.jsx";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import { create_seller, get_marketplace } from "http/api";
import firebase from "firebase";
import HomeSpinner from "views/HomeSpinner";
toast.configure();

const formConfig = [
  {
    title: "",
    inputs: [
      {
        name: "data.description",
        label: "Référence technique de l’enrôlement",
      },

      {
        name: "data.name",
        label: "Nom du vendeur",
      },
      {
        name: "data.trading_name",
        label: "Nom commercial",
      },
      {
        name: "data.nature",
        label: "Raison sociale",
      },
      {
        name: "data.iban",
        label: "IBAN de paiement",
      },
      {
        name: "data.vat_number",
        label:
          "Numéro de TVA. Lorsque le marchand n’est pas soumis à la TVA, indiquer le numéro de TVA de la Marketplace.",
      },
      {
        name: "data.turnover",
        type: "number",
        label: "Chiffre d’affaire estimé (en centimes)",
      },
    ],
  },
  //addresse
  {
    title: "Adresse",
    inputs: [
      {
        name: "address.zipcode",
        label: "Code postal",
      },
      {
        name: "address.street_number",
        label: "Numéro de voie",
      },
      {
        name: "address.country",
        label: "Code pays sur 2 lettres",
      },
      {
        name: "address.street",
        label: "Type de voie",
      },
      {
        name: "address.street",
        label: "Type de voie",
      },
      {
        name: "address.city",
        label: "Ville",
      },
    ],
  },
  //board

  {
    title: "Représentant légal",
    inputs: [
      {
        name: "board.first_name",
        label: "Prénom",
      },
      {
        name: "board.last_name",
        label: "Nom",
      },
      {
        name: "board.birth_date",
        label: "Date de naissance",
        type: "date",
      },
      {
        name: "board.title",
        type: "select",
        label: "Civilité",
        items: [
          { label: "MS", value: "MS" },
          { label: "MRS", value: "MRS" },
          { label: "MR", value: "MR" },
        ],
      },
      {
        name: "board.birth_city",
        label: "Ville de naissance",
      },
      {
        name: "board.nationality",
        label: "Nationalité",
      },
      {
        name: "board.phone_number",
        label: "Numéro de téléphone",
        type: "tel",
      },
      {
        name: "board.email",
        label: "Email",
        type: "email",
      },
      {
        title: "Adresse",
        inputs: [
          {
            name: "board.address.zipcode",
            label: "Code postal",
          },
          {
            name: "board.address.street_number",
            label: "Numéro de voie",
          },
          {
            name: "board.address.country",
            label: "Code pays sur 2 lettres",
          },
          {
            name: "board.address.street",
            label: "Type de voie",
          },
          {
            name: "board.address.street",
            label: "Type de voie",
          },
          {
            name: "board.address.city",
            label: "Ville",
          },
        ],
      },
    ],
  },
  {
    title: "activités du vendeur",
    inputs: [
      {
        name: "activities.name",
        label: "Enseigne de la boutique",
      },
      {
        name: "activities.url",
        label: "Adresse Internet de la boutique",
        type: "url",
      },
      {
        name: "activities.registration_date",
        label: "Date d’immatriculation ",
        type: "date",
      },
      {
        name: "activities.siret",
        label: "Identifiant SIRET",
      },
      {
        name: "activities.mcc",
        type: "number",
        label: "Code MCC Exemple : 7997 pour les clubs de loisirs",
      },
      {
        name: "activities.naf",
        label: "Code NAF",
      },
      {
        name: "activities.legal_identifier",
        label: "Identifiant légal",
      },
    ],
  },
];
export default class AddSeller extends Component {
  constructor(props) {
    const id = props.match.params.id;
    super(props);
    this.state = {
      errors: {},
      load: false,
      loading: true,
      id,
      data: {
        marketplace: "",
        reference: id,
        description: "",
        person_type: "PM",
        name: "",
        trading_name: "",
        nature: "",
        iban: "",
        vat_number: "",
        legal_form: "5710",
        economic_agent: "ISO_303",
        external_ref: id,
        turnover: "",
      },
      address: {
        zipcode: "",
        street_number: "",
        country: "",
        street: "",
        city: "",
      },
      board: {
        role: "LEGAL_REP",
        title: "",
        first_name: "",
        last_name: "",
        birth_date: "",
        birth_city: "",
        nationality: "",
        phone_number: "",
        email: "",
        address: {
          zipcode: "",
          street_number: "",
          country: "",
          street: "",
          city: "",
        },
      },
      activities: {
        name: "",
        url: "",
        registration_date: "",
        siret: "",
        mcc: "",
        naf: "",
        legal_identifier: "",
        is_principal: true,
      },
    };
  }

  getError = (path) => {
    const errors = this.state.errors;
    if (path.indexOf("data.") === 0) {
      return _.get(errors, path.replace("data.", ""));
    }

    return _.get(errors, path);
  };
  async componentDidMount() {
    let marcketplace;
    try {
      const mp = await get_marketplace();
      marcketplace = mp.uuid;
    } catch (ex) {
      console.log("error mp", ex);
      return this.props.history.replace("/admin/on-boarding");
    }
    firebase
      .firestore()
      .collection("boutiques")
      .doc(this.state.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = {
            ["data.marketplace"]: marcketplace,
            ["data.name"]: doc.data().nom,
            ["activities.siret"]: doc.data().numSiret,
            ["address.street"]: doc.data().adresse,
            ["address.city"]: doc.data().ville,
            ["address.zipcode"]: doc.data().codePostal,
            ["board.address.street"]: doc.data().adresse,
            ["board.address.city"]: doc.data().ville,
            ["board.address.zipcode"]: doc.data().codePostal,
          };
          const state = { ...this.state };
          Object.keys(data).map((d) => {
            _.set(state, d, data[d]);
          });
          this.setState(state);
        } else {
          this.props.history.replace("/admin/on-boarding");
        }
        this.setState({ loading: false });
      });
  }
  getErrorsInput(inputs, data, errors) {
    const message = "This field may not be blank.";
    for (let i of inputs) {
      if (i.inputs) {
        this.getErrorsInput(i.inputs, data, errors);
      } else {
        const val = _.get(data, i.name);
        if (!!!val) {
          if (i.name.indexOf("data.") === 0) {
            _.set(errors, i.name.replace("data.", ""), message);
          } else {
            _.set(errors, i.name, message);
          }
        }
      }
    }
  }
  validate = (data) => {
    const errors = {};
    for (let field of formConfig) {
      this.getErrorsInput(field.inputs, data, errors);
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return false;
    }
    return true;
  };

  addSeller = async (e) => {
    e.preventDefault();

    this.setState({ load: true });
    try {
      const { data, activities, board, address } = this.state;
      if (
        this.validate({
          data,
          address,
          board,
          activities,
        })
      ) {
        const finalData = {
          ...data,
          address: address,
          board: [board],
          activities: [activities],
        };
        const { results, error } = await create_seller({
          userId: this.state.id,
          data: finalData,
        });
        if (error) {
          this.notifyError();
        } else {
          if (results.uuid) {
            this.notify();
            this.onBack();
          } else {
            const errors = this.getErrors(results);
            console.log("errors", errors);
            this.setState({ errors: errors });
            this.notifyError();
          }
        }
        console.log("results", results);
      }
    } catch (ex) {
      console.log("Error Response", ex.response);
      console.log("Error", ex);
    }
    this.setState({ load: false });
  };

  getErrors = (errors) => {
    const keys = Object.keys(errors);
    for (let k of keys) {
      if (Array.isArray(errors[k])) {
        errors[k] = this.getErrors(errors[k][0]);
      }
    }
    return errors;
  };

  onBack = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    this.props.history.goBack();
  };

  onChange = (name, e) => {
    const state = { ...this.state };
    _.set(state, name, e.target.value);
    this.setState(state);
  };

  notify = () => {
    toast.success("Vendeur ajouté avec success", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };
  notifyError = () => {
    toast.error("Vendeur non ajouté", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };

  render() {
    const { loading } = this.state;
    if (loading) return <HomeSpinner />;
    return (
      <React.Fragment>
        <div className="content">
          <Grid fluid>
            <Col md={12}>
              <Card
                title="Ajouter un vendeur"
                content={
                  <React.Fragment>
                    <ArrowBackSharpIcon
                      style={{ cursor: "pointer" }}
                      fontSize={"large"}
                      onClick={this.onBack}
                    />
                    <Form>
                      {formConfig.map((f) => (
                        <Input
                          getValue={(name) => _.get(this.state, name)}
                          onChange={this.onChange}
                          getError={this.getError}
                          form={f}
                        />
                      ))}
                      <div className="row">
                        <Col md={12}>
                          {!this.state.load ? (
                            <Button
                              style={style.btn}
                              fill
                              type="submit"
                              onClick={this.addSeller}
                            >
                              Ajouter
                            </Button>
                          ) : (
                            <Button type="submit" style={style.btn} fill>
                              Chargement...
                              <i
                                className="fa fa-spin fa-spinner"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          )}{" "}
                          <Button
                            onClick={this.onBack}
                            style={style.cancel}
                            fill
                          >
                            Annuler
                          </Button>
                        </Col>
                      </div>
                    </Form>
                  </React.Fragment>
                }
              />
            </Col>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
const style = {
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    float: "right",
    marginRight: "0.8em",
    borderColor: "#e4393f",
    width: "25%",
  },
  cancel: {
    backgroundColor: "rgb(205, 205, 205)",
    float: "right",
    marginRight: "0.8em",
    borderColor: "rgb(205, 205, 205)",
    width: "25%",
  },
};

export function Input({ form, onChange, getValue, getError }) {
  if (form.title !== undefined) {
    const noneInputs = form.inputs.filter((f) => f.title);
    const row = _.chunk(
      form.inputs.filter((f) => !f.title),
      2
    );
    return (
      <Card
        title={form.title}
        content={
          <>
            <div>
              {row.map((r, index) => (
                <FormInputs
                  key={index}
                  ncols={r.map((_) => "col-md-6")}
                  properties={r.map((i) => ({
                    label: i.label,
                    type: i.type || "text",
                    value: getValue(i.name),
                    required: true,
                    error: getError(i.name),
                    bsClass: "form-control",
                    items: i.items,
                    placeholder: i.label,
                    name: i.name,
                    onChange: (e) => {
                      onChange(i.name, e);
                    },
                  }))}
                />
              ))}
            </div>
            <div>
              {noneInputs.map((i, index) => {
                return (
                  <Input
                    getValue={getValue}
                    key={index}
                    getError={getError}
                    form={i}
                    onChange={onChange}
                  />
                );
              })}
            </div>
          </>
        }
      />
    );
  }
  return (
    <FormInputs
      ncols={["col-md-6"]}
      properties={[
        {
          label: form.label,
          type: form.type || "text",
          bsClass: "form-control",
          value: getValue(form.name),
          required: true,
          error: getError(form.name),
          items: form.items,
          placeholder: form.label,
          name: form.name,
          onChange: (e) => onChange(form.name, e),
        },
      ]}
    />
  );
}
