import Card from "components/Card/Card";
import FormInputs from "components/FormInputs/FormInputs";
import React, { Component } from "react";
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
} from "react-bootstrap";
import "./boutik_style.css";
import CheckBoxGroup from "components/CheckBoxGroup/CheckBoxGroup";
import firebase from "../../firebase.js";
import Button from "components/CustomButton/CustomButton.jsx";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import { boutique_status } from "../../variables/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const SERVICE = "Services & Loisirs";
export const PF = "P’tite Faim";
export const BE = "Bien être";
export const LS = "Santé";

export default class BoutiqueForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        categorie: "",
      },
      data: [],
      pay_modes: [
        { id: 1, value: "Chéques", isChecked: false },
        { id: 2, value: "Ticket resto", isChecked: false },
        { id: 3, value: "Carte Bancaire", isChecked: false },
        { id: 4, value: "Espéces", isChecked: false },
      ],
      load: false,
      modePaiement: [],
      categorys: [
        { id: 1, nom: SERVICE },
        { id: 2, nom: PF },
        { id: 3, nom: BE },
        { id: 4, nom: LS },
      ],
      boutique_status: [
        { id: 1, status: boutique_status.online },
        { id: 2, status: boutique_status.offline },
        { id: 3, status: boutique_status.pending },
      ],
    };
  }

  componentDidMount() {
    // this.fetchCategorys()
    // console.log("boutique_status", this.state.boutique_status);
    // console.log("consts status", boutique_status);
  }

  onBack = (e) => {
    e.preventDefault();
    if (!!this.props.back) {
      this.props.back();
    }
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields: fields });
  };

  handleAllChecked = (event) => {
    let pay_modes = this.state.pay_modes;
    pay_modes.forEach((mode_p) => (mode_p.isChecked = event.target.checked));
    this.setState({ pay_modes: pay_modes }, () => console.log(pay_modes));
  };

  handleCheckChieldElement = (event) => {
    let pay_modes = this.state.pay_modes;
    pay_modes.forEach((mode_p) => {
      if (mode_p.value === event.target.value) {
        mode_p.isChecked = event.target.checked;
      }
      this.setState({ pay_modes }, () => console.log(mode_p));
    });
  };

  fetchCategorys = async () => {
    try {
      let db = firebase.firestore();
      let data = [];
      let docs = await db.collection("categories").get();
      docs.forEach((doc) => data.push({ ...doc.data(), id: doc.id }));
      console.log(data);
      // let filter=data.filter(f=>f.name==='')
      this.setState({ data });
      // console.log(this.state.data)
    } catch (error) {
      console.log(error.message);
    }
  };

  notify = () => {
    toast.success("Boutique ajoutee avec success", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };

  onAddBoutik = async (e) => {
    e.preventDefault();
    this.setState({ load: true });
    let { fields } = this.state;

    this.state.pay_modes.forEach((item) => {
      let { modePaiement } = this.state;
      if (item.isChecked === true) {
        modePaiement = [...modePaiement, item.value];
        fields.modePaiement = modePaiement;
      }
      this.setState({ fields: fields });
    });
    // console.log(fields.modePaiement)
    let data = fields;
    console.log(data);
    let uid = JSON.parse(sessionStorage.getItem("id_user"));
    try {
      const db = firebase.firestore();
      let boutik = await db.collection("boutiques").doc(uid);
      const doc = await boutik.get();
      if (doc.exists) {
        boutik.update(data);
        this.setState({ load: !this.state.load });
        this.notify();
        console.log("update");
      } else {
        boutik.set(data);
        this.setState({ load: !this.state.load });
        this.notify();
        console.log("set");
      }
      // .add(data)
      // .then((docRef) => {
      //   // console.log(docRef.id)
      //   db.collection("boutiques")
      //     .doc(docRef.id)
      //     .get()
      //     .then((snap) => {
      //       console.log(snap.data());
      //       this.setState({ load: !this.state.load });
      //       this.notify();
      //     });
      // })
      // .catch((err) => {
      //   console.log(err.message);
      //   this.setState({ load: !this.state.load });
      // });
      // this.setState({ load: !this.state.load })
      // console.log(res.doc.data());
      // console.log(res);
    } catch (error) {
      this.setState({ load: !this.state.load });
      console.log(error.message);
      toast.error("Erreur lors de l'ajout, pas de connexion internet", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    }
  };

  render() {
    let { load, categorys } = this.state;
    // const{load}=this.state
    categorys = categorys?.map(
      (item, i) => (
        <option value={item.nom} key={item.id} id={item.id}>
          {item.nom}
        </option>
      )
      // }
    );
    return (
      <React.Fragment>
        <div className="content">
          <Grid fluid>
            <Col md={12}>
              <Card
                title="Nouvelle Boutique"
                content={
                  <React.Fragment>
                    <ArrowBackSharpIcon
                      style={{ cursor: "pointer" }}
                      fontSize={"large"}
                      onClick={this.onBack}
                    />
                    <Form>
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Nom de ma boutique",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Nom de ma boutique",
                            name: "nom",
                            onChange: this.handleChange,
                          },
                          {
                            label: "Nom du dirigeant",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Nom du dirigeant",
                            name: "drigeant",
                            onChange: this.handleChange,
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Numero telephone",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Numero telephone",
                            name: "phone",
                            onChange: this.handleChange,
                          },
                          {
                            label: "Numéro siret",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Numéro siret (RNA pour association)",
                            name: "numSiret",
                            onChange: this.handleChange,
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Description courte",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Description courte (3 mots cles)",
                            name: "descriptionCourte",
                            onChange: this.handleChange,
                          },
                          {
                            label: "Adresse de votre boutique",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Adresse de votre boutique",
                            name: "adresse",
                            onChange: this.handleChange,
                          },
                          // {
                          //     label: "Adresse complétee",
                          //     type: "text",
                          //     bsClass: "form-control",
                          //     placeholder: "Adresse complétee",
                          //     name: "complementAdresse",
                          //     onChange: this.handleChange
                          // }
                        ]}
                      />
                      <FormGroup>
                        <ControlLabel>Description détaillée</ControlLabel>
                        <textarea
                          className="form-control"
                          name="description"
                          type="text"
                          placeholder="Description détaillée"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                      <FormInputs
                        ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                        properties={[
                          {
                            label: "Ville",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Ville",
                            name: "ville",
                            onChange: this.handleChange,
                          },
                          {
                            label: "Code Postal",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Code Postal",
                            name: "codePostal",
                            onChange: this.handleChange,
                          },
                          {
                            label: "Latitude",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "Latitude",
                            name: "latitude",
                            onChange: this.handleChange,
                          },
                          {
                            label: "Longitude",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "Longitude",
                            name: "longitude",
                            onChange: this.handleChange,
                          },
                        ]}
                      />
                      <FormGroup>
                        <ControlLabel>Category</ControlLabel>
                        <FormControl
                          componentClass="select"
                          placeholder="select"
                          onChange={(e) => {
                            let { fields } = this.state;
                            console.log("selected", e.target.value);
                            fields.categorie = e.target.value;
                            this.setState({ fields }, console.log(fields));
                          }}
                        >
                          <option>Choisir une Categorie</option>
                          {categorys}
                        </FormControl>
                      </FormGroup>
                      <FormGroup>
                        <input
                          type="checkbox"
                          onClick={this.handleAllChecked}
                          value="checkedall"
                        />{" "}
                        Tous cocher / Tous décocher
                        <ul style={{ listStyle: "none" }}>
                          {this.state.pay_modes.map((mode_p, i) => {
                            return (
                              <CheckBoxGroup
                                key={i}
                                handleCheckChieldElement={
                                  this.handleCheckChieldElement
                                }
                                {...mode_p}
                              />
                            );
                          })}
                        </ul>
                      </FormGroup>
                      <div className="row">
                        <Col md={12}>
                          {!load ? (
                            <Button
                              style={style.btn}
                              fill
                              onClick={this.onAddBoutik}
                            >
                              Ajouter
                            </Button>
                          ) : (
                            <Button style={style.btn} fill>
                              Chargement...
                              <i
                                className="fa fa-spin fa-spinner"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          )}{" "}
                          <Button
                            onClick={this.onBack}
                            style={style.cancel}
                            fill
                          >
                            Annuler
                          </Button>
                        </Col>
                      </div>
                    </Form>
                  </React.Fragment>
                }
              />
            </Col>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
const style = {
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    float: "right",
    marginRight: "0.8em",
    borderColor: "#e4393f",
    width: "25%",
  },
  cancel: {
    backgroundColor: "rgb(205, 205, 205)",
    float: "right",
    marginRight: "0.8em",
    borderColor: "rgb(205, 205, 205)",
    width: "25%",
  },
};
