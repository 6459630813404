export const boutique_status = {
  online: "online",
  offline: "offline",
  pending: "pending",
};

export const cmd_status = {
  EN_COURS: 1,
  EN_ATTENTES: undefined,
  EN_ATTENTES_LIVRAISON: 28,
  TERMINEES: 2,
  CANCELED: 7,
  ORDER_CANCELED_BY_CLIENT: 600,
  NEW_ORDER: 29,
};

export const PAYMENT_ACCOUNT_STATUS = {
  DEFAULT: undefined,
  EN_COURS: 1,
  ACTIVE: 2,
};
