import React, { Component } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

export default class CardStatsYear extends Component {
  render() {
    return (
      <div className="card card-stats" style={{backgroundColor: "whitesmoke"}} >
        <div className="content">
          <Row>
            <Col xs={4}>
              <div className="icon-big text-center icon-warning">
                <i className="far fa-calendar-alt" style={{color: "tomato"}}></i>
              </div>
            </Col>
            <Col xs={8}>
              <div className="numbers">
                  {this.props.consultationsYear.length}
                <h5>Consultations depuis le debut de l'année</h5>
                
              </div>
            </Col>
          </Row>
          {/* <div className="footer">
            <hr />
            <div className="stats">footer</div>
          </div> */}
        </div>
      </div>
    )
  }
}
