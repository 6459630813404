/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// import NotificationSystem from "react-notification-system";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

// import { style } from "variables/Variables.jsx";

import routes, { getRoutes as GR } from "routes.js";

import routes_commercant from "routes_commercant.js";
import image from "assets/img/sidebar-3.jpg";
import ItemBordereau from "views/Bordereau/ItemBordereau";
import CardHoraire from "components/CardHoraire/CardHoraire";
import Commande from "views/viewsCommercant/Commande/Commande.jsx";
import UserComProfil from "views/viewsCommercant/Profil/UserComProfil";
import AddProduit from "views/viewsCommercant/Produit/AddProduit";
import DetailCommande from "views/viewsCommercant/DetailCommande/DetailCommande";
import FinalCommande from "views/viewsCommercant/Commande/FinalCommande";
import Parametres from "views/viewsCommercant/Parametres";
import EditBoutique from "views/viewsCommercant/EditBoutique";
import CreateBoutique from "views/viewsCommercant/CreateBoutique";
import DetailMessage from "views/Messages/DetailMessage";
import AddSeller from "views/onboarding/addSeller";
import Vendeur from "views/onboarding/Vendeur";
// import CreateBoutique from "views/viewsCommercant/CreateBoutique";

function getRoutes() {
  let is_admin = "";
  var get_rt = [];
  let id_user = sessionStorage.getItem("id_user") || "";
  if (id_user !== undefined || id_user !== "") {
    is_admin = sessionStorage.getItem("is_admin") || "";
    if (is_admin) {
      get_rt = GR();
      console.log("routes js ==> ", get_rt);
    } else {
      get_rt = routes_commercant;
    }
  }
  return get_rt;
}

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      image: image,
      color: "red",
      hasImage: true,
      fixedClasses: "dropdown show-dropdown open",
      route: getRoutes(),
    };
  }
  handleNotificationClick = (position) => {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Bienvenue <b>ObuduClic Dashboard</b>
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15,
    });
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => (
              <prop.component
                {...props}
                handleClick={this.handleNotificationClick}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  handleImageClick = (image) => {
    this.setState({ image: image });
  };
  handleColorClick = (color) => {
    this.setState({ color: color });
  };
  handleHasImage = (hasImage) => {
    this.setState({ hasImage: hasImage });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    // var _notificationSystem = this.refs.notificationSystem;
    // var color = Math.floor(Math.random() * 4 + 1);
    // var level;
    // switch (color) {
    //   case 1:
    //     level = "success";
    //     break;
    //   case 2:
    //     level = "warning";
    //     break;
    //   case 3:
    //     level = "error";
    //     break;
    //   case 4:
    //     level = "info";
    //     break;
    //   default:
    //     break;
    // }
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      // console.log("update listener1")
    }
    if (e.history.action === "PUSH") {
      console.log("update listener2");
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  render() {
    const isAuth = sessionStorage.getItem("id_user");

    return isAuth ? (
      <div className="wrapper">
        {/* <NotificationSystem ref="notificationSystem" style={style} /> */}
        <Sidebar
          {...this.props}
          routes={this.state.route}
          image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
        />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            // brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            <Route
              exact
              path="/admin/details-bordereau/:ville"
              render={(props) => <ItemBordereau {...props} />}
            />
            <Route
              exact
              path="/admin/maboutique/horaires"
              render={(props) => <CardHoraire {...props} />}
            />
            <Route
              exact
              path="/admin/monprofil"
              render={(props) => <UserComProfil {...props} />}
            />
            <Route
              exact
              path="/admin/message/:id"
              render={(props) => <DetailMessage {...props} />}
            />
            <Route
              exact
              path="/admin/addproduit"
              render={(props) => <AddProduit {...props} />}
            />
            <Route
              exact
              path="/admin/commande/:cmd_status"
              render={(props) => <Commande {...props} />}
            />
            <Route
              exact
              path="/admin/orderDetails/:id"
              render={(props) => <DetailCommande {...props} />}
            />
            <Route
              exact
              path="/admin/details_commande/:id"
              render={(props) => <FinalCommande {...props} />}
            />
            <Route
              exact
              path="/admin/maboutique/parametres"
              render={(props) => <Parametres {...props} />}
            />
            <Route
              exact
              path="/admin/createboutique"
              render={(props) => <CreateBoutique {...props} />}
            />
            <Route
              exact
              path="/admin/edit/:btq"
              render={(props) => <EditBoutique {...props} />}
            />
            <Route exact path="/admin/seller/:id" component={AddSeller} />
            <Route exact path="/admin/vendeur/:id" component={Vendeur} />
            {this.getRoutes(this.state.route)}
          </Switch>
          <Footer />
          {/* <FixedPlugin
            handleImageClick={this.handleImageClick}
            handleColorClick={this.handleColorClick}
            handleHasImage={this.handleHasImage}
            bgColor={this.state["color"]}
            bgImage={this.state["image"]}
            mini={this.state["mini"]}
            handleFixedClick={this.handleFixedClick}
            fixedClasses={this.state.fixedClasses}
          /> */}
        </div>
      </div>
    ) : (
      <Redirect to={{ pathname: "/signin" }} />
    );
  }
}

export default Admin;
