import * as firebase from 'firebase'
import "firebase/auth"
import "firebase/functions"
// Your web app's Firebase configuration


const config_app = {
    apiKey: "AIzaSyCQ1YjuQ1jWfHKyHE7R5UfA8o6jF6otdqg",
    authDomain: "obouduclic-298615.firebaseapp.com",
    // databaseURL: "https://sevenshopy.firebaseio.com",
    projectId: "obouduclic-298615",
    storageBucket: "obouduclic-298615.appspot.com",
    messagingSenderId: "934263802486",
    appId: "1:934263802486:web:bea1baa320c8951a182d57",
    measurementId: "G-18Z0XH33VQ"
};
// Initialize Firebase
firebase.initializeApp(config_app);
// firebase.analytics();

// const config_app = firebase.initializeApp({

//     apiKey: process.env.REACT_APP_FIREBASE_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
//     databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
// })

export default firebase;