import * as firebase from "firebase";
import axios from "axios";

export function create_seller(data) {
  const cb = firebase.functions().httpsCallable("addSeller");
  return cb(data).then(({ data }) => data);
}
export function get_marketplace() {
  const cb = firebase.functions().httpsCallable("getMarketPlace");
  return cb().then(({ data }) => data);
}
export function get_seller(sellerId) {
  const cb = firebase.functions().httpsCallable("getSeller");
  return cb({ sellerId }).then(({ data }) => data);
}
export function get_registrations() {
  const cb = firebase.functions().httpsCallable("getRegistrations");
  return cb().then(({ data }) => data);
}
export function getFormToken(data) {
  const cb = firebase.functions().httpsCallable("createAbonnementFormToken");
  return cb(data).then(({ data }) => data);
}
export function get_documents(sellerId) {
  const cb = firebase.functions().httpsCallable("getDocuments");
  return cb({ sellerId }).then(({ data }) => data);
}

// export function add_documents(data) {
//   return axios.post(
//     `https://us-central1-obouduclic-298615.cloudfunctions.net/addDocuments`,
//     data,
//     {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     }
//   );
// }

// export function add_documents(data) {
//   const cb = firebase.functions().httpsCallable("addDocuments");
//   return cb(data).then(({ data }) => data);
// }
// http://cors-anywhere.herokuapp.com/
// export async function add_documents(registrationId, data) {
//   return axios.post(
//     "https://secure.lyra.com/marketplace-test/registrations/" +
//       registrationId +
//       "/documents",
//     data,
//     {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Basic c3JlZGFudDplNjZhOTMwNGUz`,
//       },
//     }
//   );
// }
// export async function add_documents(registrationId, data) {
//   return fetch(
//     "https://secure.lyra.com/marketplace-test/registrations/" +
//       registrationId +
//       "/documents",
//     {
//       body: data,
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Basic c3JlZGFudDplNjZhOTMwNGUz`,
//       },
//       credentials: "omit",
//       method: "post",
//     }
//   );
// }

export function add_documents(sellerId, data) {
  return axios.post(
    `https://us-central1-obouduclic-298615.cloudfunctions.net/widgets?sellerId=${sellerId}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}
