import Card from "components/Card/Card";
import SondageCard from "components/SondageCard/SondageCard";
// import StatsCard from "components/StatsCard/StatsCard";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import firebase from "../../firebase.js";

export default class Sondage extends Component {
  state = {
    ville: [],
    data: [],
  };

  componentDidMount() {
    this._getCommune();
  }
  _getCommune = async () => {
    try {
      let db = firebase.firestore();
      let data = [];
      let docs = await db.collection("users").get();
      docs.forEach((doc) => data.push({ ...doc.data(), id: doc.id }));
      let ville = [];
      let newObj = {};
      let tab = [];
      console.log("users=>", data);
      this.setState({ data: data });
      // eslint-disable-next-line array-callback-return
      data
        .filter(
          (f) =>
            f?.travail !== undefined ||
            f?.domicile !== undefined ||
            f?.travail !== undefined
        )
        // eslint-disable-next-line array-callback-return
        .map((m) => {
          let index = ville.findIndex(
            (f) => f?.domicile?.trim() === m?.domicile?.trim()
          );
          let index2 = ville.findIndex(
            (f) => f.travail?.trim() === m?.travail?.trim()
          );
          let index3 = ville.findIndex(
            (f) => f?.vacances?.trim() === m?.vacances?.trim()
          );
          if (index === -1) {
            let obj = {
              domicile: m?.domicile,
              count: 1,
            };
            ville.push(obj);
          } else {
            ville[index].count++;
          }

          if (index2 === -1) {
            let obj = {
              travail: m?.travail,
              count: 1,
            };
            ville.push(obj);
          } else {
            ville[index2].count++;
          }

          if (index3 === -1) {
            let obj = {
              vacances: m?.vacances,
              count: 1,
            };
            ville.push(obj);
          } else {
            ville[index3].count++;
            console.log("test", ville);
          }
          // let obj = {
          //   travail: m?.travail,
          //   domicile: m?.domicile,
          //   vacances: m?.vacances,
          // };
          // console.log("m=>", m);
          var tempObj = {
            // eslint-disable-next-line no-use-before-define
            ...tempObj,
            ...{
              travail: m?.travail,
              domicile: m?.domicile,
              vacances: m?.vacances,
            },
            // ...{
            //   // travail: m?.travail,
            //   domicile: m?.domicile,
            // },
            // ...{
            //   // travail: m?.travail,
            //   // domicile: m?.domicile,
            //   vacances: m?.vacances,
            // },
          };
          newObj = tempObj;
          tab.push(newObj);
          // ville.push(obj);
          // ville.push(newObj);
        });
      // console.log("ville tab=>", ville);
      // console.log("tab test=>", tab);
      // console.log(
      //   "commune ville=>",
      //   ville.filter(
      //     (f) =>
      //       f?.domicile !== undefined ||
      //       f?.travail !== undefined ||
      //       f?.vacances !== undefined
      //   )
      // );
      // console.log("ville again=>", ville);
      this.setState({
        ville: ville.filter(
          (f) =>
            f?.domicile !== undefined ||
            f?.travail !== undefined ||
            f?.vacances !== undefined
        ),
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  render() {
    // console.log(
    //   "commune travail",
    //   this.state.ville.filter((f) => f.travail)
    // );
    // let commune = this.state.ville.filter((f) => f.travail);
    // let newTab = [];
    // for (let index = 0; index < commune.length; index++) {
    //   const element = commune[index];
    //   console.log("element", element);
    //   // let idx = travail.indexOf(element?.travail);
    //   let idx = newTab.findIndex(
    //     (f) => f.travail.trim() === element?.travail.trim()
    //   );
    //   console.log("idx", idx);
    //   if (idx === -1) {
    //     newTab = [...newTab, { ...element }];
    //   } else {
    //     // console.log("je suis dans le else");
    //     newTab[idx].count++;
    //   }
    // }
    // console.log("newTab commune", newTab);
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Sondage"
                content={
                  <Row>
                    <Col md={4}>
                      <SondageCard
                        statsText={"Commune Domicile:"}
                        statsIconText={this.state.ville
                          .filter((f) => f.domicile)
                          .map((s, index) => (
                            <React.Fragment key={index}>
                              {s?.domicile !== "" ? (
                                <p style={cssStyle.para}>
                                  {s?.domicile}
                                  <span style={cssStyle.span} className="badge">
                                    {s?.count}
                                  </span>
                                </p>
                              ) : null}
                            </React.Fragment>
                          ))}
                      />
                    </Col>
                    <Col md={4}>
                      <SondageCard
                        statsText={"Commune Vacances:"}
                        statsIconText={this.state.ville
                          .filter((f) => f.vacances)
                          .map((v, index) => (
                            <React.Fragment key={index}>
                              {v.vacances !== "" ? (
                                <p style={cssStyle.para}>
                                  {v.vacances}
                                  <span style={cssStyle.span} className="badge">
                                    {v.count}
                                  </span>
                                </p>
                              ) : null}
                            </React.Fragment>
                          ))}
                      />
                    </Col>
                    <Col md={4}>
                      <SondageCard
                        statsText={"Commune Travail:"}
                        statsIconText={this.state.ville
                          .filter((f) => f.travail)
                          .map((t, index) => (
                            <React.Fragment key={index}>
                              {t.travail !== "" ? (
                                <p style={cssStyle.para}>
                                  {t.travail}
                                  <span style={cssStyle.span} className="badge">
                                    {t.count}
                                  </span>
                                </p>
                              ) : null}
                            </React.Fragment>
                          ))}
                      />
                    </Col>
                  </Row>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const cssStyle = {
  para: {
    textAlign: "center",
    width: `${250}px`,
    fontFamily: "sans-serif",
    color: "black",
  },
  span: {
    float: "right",
  },
};
