import { TextField } from "@material-ui/core";
import Card from "components/Card/Card";
import { Col, Form, FormGroup, Grid } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import React, { Component } from "react";
import { toast } from "react-toastify";
import firebase from "../../firebase";

export default class SignUp extends Component {
  state = {
    fields: {},
    load: false,
    errors: [],
  };

  handleChange = (e) => {
    e.preventDefault();
    const { fields } = this.state;
    const { name, value } = e.target;
    fields[name] = value;
    this.setState({ fields: fields });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    let { fields } = this.state;
    this.setState({ load: true });
    console.log(fields);
    if (
      this.state.fields.password === undefined ||
      this.state.fields.confirmPassword === undefined ||
      this.state.fields.email === undefined ||
      this.state.fields.displayName === undefined
    ) {
      let msg = "Tous les champs sont requis";
      toast.warn(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
      this.setState({ load: false });
    } else if (fields.password !== fields.confirmPassword) {
      this.setState({ load: false });
      toast.warn("Les mots de passe ne sont pas identiques", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    } else {
      try {
        const req = firebase
          .auth()
          .createUserWithEmailAndPassword(fields?.email, fields?.password);
        console.log(req);
        if (req?.i?.message === "The email address is badly formatted.") {
          let msg = "The email address is badly formatted.";
          toast.warn(msg, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        } else if (req) {
          let uid = (await req).user.uid;
          console.log(uid);
          const db = firebase.firestore();
          let user = db.collection("users").doc(uid);
          const doc = await user.get();
          let data = {
            email: fields?.email,
            nom: fields?.displayName,
            is_admin: true,
          };
          if (doc.exists) {
            user.update(data);
            this.setState({ load: !this.state.load });
          } else {
            user.set(data);
            toast.success("Compte ajouté avec success", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 4000,
            });
            this.setState({ load: !this.state.load });
            console.log("je suis dans le set");
          }
        }
      } catch (error) {
        console.log(error.message);
        if (error.message) {
          let msg = error.message;
          toast.warn(msg, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        }
        this.setState({ load: false });
      }
    }
  };

  render() {
    const { load } = this.state;
    return (
      <div className="content">
        <Grid fluid>
          <Col md={12}>
            <Card
              title="Nouveau Compte admin"
              content={
                <Form>
                  <FormGroup>
                    <TextField
                      label="Nom d'utilisateur"
                      name="displayName"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      label="Email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      label="Mot de passe"
                      name="password"
                      type="password"
                      variant="outlined"
                      required
                      fullWidth
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      label="Confirmer mot de passe"
                      name="confirmPassword"
                      type="password"
                      variant="outlined"
                      required
                      fullWidth
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <div className="row">
                    <Col md={12}>
                      {!load ? (
                        <Button style={style.btn} fill onClick={this.onSubmit}>
                          Enregistrer
                        </Button>
                      ) : (
                        <Button style={style.btn} fill>
                          Loading...
                          <i
                            className="fa fa-spin fa-spinner fa-lg"
                            aria-hidden="true"
                          ></i>
                        </Button>
                      )}
                    </Col>
                  </div>
                </Form>
              }
            />
          </Col>
        </Grid>
      </div>
    );
  }
}
const style = {
  btn: {
    backgroundColor: "rgba(226, 41, 47, 0.92)",
    float: "right",
    marginRight: "0.8em",
    borderColor: "#e4393f",
  },
};
