import React from "react";
const CheckBoxGroup = (props) => {
  // console.log("checked", props.check);
  return (
    <li>
      <input
        key={props.id}
        onChange={(e) => props.handleCheckChieldElement(e, props.value)}
        type="checkbox"
        checked={props.check}
        value={props.value}
      />{" "}
      {props.value}
    </li>
  );
};

export default CheckBoxGroup;
