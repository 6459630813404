/* eslint-disable array-callback-return */
import OrderItem from "components/OrderItem/OrderItem.jsx";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getOrderById } from "reducers/commande/actions/commandeAction.js";
import { cmd_status } from "variables/constants";
import LoadSpinner from "views/LoadSpinner.jsx";
import firebase from "../../../firebase.js";
// import { displayMessage } from "utils";
import { toast } from "react-toastify";
// import { Redirect } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import { _getAllProducts } from "reducers/products/actions/productAction.js";
// import { useReducer } from "react";

class DetailCommande extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {},
      isloading: true,
      products: [],
      user: {},
      data: [],
      payment: {},
    };
  }

  getData = async (boutique_id, user_id) => {
    const products = [];
    const docs = await firebase
      .firestore()
      .collection("products")
      .where("boutique_id", "==", boutique_id)
      .get();
    docs.forEach((doc) => {
      products.push({ id: doc.id, ...doc.data() });
    });

    const docUser = await firebase
      .firestore()
      .collection("users")
      .doc(user_id)
      .get();
    return {
      products,
      user: { id: docUser.id, ...docUser.data() },
    };
  };

  componentDidMount() {
    firebase
      .firestore()
      .collection("commandes")
      .doc(this.props.match.params.id)
      .onSnapshot(async (qs) => {
        if (qs.exists) {
          const order = { id: qs.id, ...qs.data() };
          const { products, user } = await this.getData(
            order.boutique_id,
            order.user_id
          );
          const getPayment = firebase.functions().httpsCallable("getPayment");
          const result = await getPayment({ orderId: order.id });
          this.setState({
            products,
            user,
            order,
            isloading: false,
            payment: result.data,
          });
        } else {
          this.props.history.replace("/admin/mydashboard");
        }
      });
  }

  notify = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  };

  notifyErreur = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  };

  getProd = () => {
    return this.state.order?.produits?.map((p) => {
      const prod = this.state.products.find((p2) => p2.id === p.id);
      if (prod) {
        return {
          ...prod,
          ...p,
        };
      }
      return {
        ...p,
      };
    });
  };

  updateOrderStatusDone = async (e, status) => {
    e.preventDefault();
    this.setState({ isloading: true });
    let data = {
      status: status,
      date_finished: new Date().getTime(),
    };
    try {
      const db = firebase.firestore();
      await db.collection("commandes").doc(this.state.order.id).update(data);
    } catch (error) {
      console.log(error);
    }
    this.setState({ isloading: false });
  };

  updateOrderStatusCancel = async (e) => {
    e.preventDefault();
    this.setState({ isloading: true });
    if (this.state.order.order) {
      const data = {
        order: this.state.order.order,
        amount: this.state.order.prix,
        status: cmd_status.CANCELED,
        orderId: this.state.order.id,
        userId: this.state.order.user_id,
      };
      const getPayment = firebase.functions().httpsCallable("cancelOrder");
      const result = await getPayment(data);
      if (result.data.status) {
        this.notify(result.data.message);
        window.location.pathname = "/admin/mydashboard";
      } else {
        this.notifyErreur(result.data.message);
        this.setState({ isloading: false });
      }
    } else {
      this.updateOrderStatusDone(e, cmd_status.CANCELED);
    }
  };
  handleUpdate = async (e, product) => {
    const produits = this.state.order.produits.map((p) => {
      if (p.id === product.id) {
        p.status = !!e.target.checked;
      }
      return p;
    });
    let data = {
      produits: produits,
      status: this.state.order.status || cmd_status.EN_COURS,
    };
    this.setState({ isloading: true });
    try {
      const db = firebase.firestore();
      await db.collection("commandes").doc(this.state.order.id).update(data);
    } catch (error) {
      console.log(error);
    }
    this.setState({ isloading: false });
  };

  render() {
    const products = this.getProd();
    const { isloading, order, user } = this.state;
    let checker = (arr) => arr.every((v) => v.status === true);
    return (
      <Card
        title="Commandes"
        content={
          <div
            className="row mx-auto"
            style={{
              maxWidth: "700px",
              margin: "auto",
              width: "100%",
              padding: "10px",
            }}
          >
            {!isloading ? (
              <div className="col-md-8 text-secondary table-responsive my-3">
                <h4 className="text-uppercase">
                  Commande N°:
                  <strong>{order?.code}</strong>
                </h4>
                <h2 className="mt-2 mb-2">{this.state.payment.message}</h2>

                <table className="table my-3">
                  <tbody>
                    {products?.map((item) => (
                      <OrderItem
                        key={item.id}
                        item={item}
                        order={order}
                        handleUpdate={(e) =>
                          this.handleUpdate(
                            e,
                            order.produits.find((f) => f.id === item.id)
                          )
                        }
                      />
                    ))}
                    <tr>
                      <td style={{ whiteSpace: "nowrap" }}>
                        Nom du client: <strong>{user.nom}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ whiteSpace: "nowrap" }}>
                        Téléphone du client: <strong>{user?.phone}</strong>
                      </td>
                    </tr>
                    {this.state.order.address && (
                      <tr>
                        <td style={{ whiteSpace: "nowrap" }}>
                          Adresse : <strong>{this.state.order.address}</strong>
                        </td>
                      </tr>
                    )}
                    {this.state.order.additionalData && (
                      <tr>
                        <td style={{ whiteSpace: "nowrap" }}>
                          Information complémentaire :{" "}
                          <strong>{order.additionalData}</strong>
                        </td>
                      </tr>
                    )}
                    {cmd_status.TERMINEES === order.status ||
                    cmd_status.CANCELED === order.status ? null : (
                      <tr>
                        <td>
                          <button
                            disabled={
                              order.produits.reduce((acc) => acc?.status) ===
                                false ||
                              cmd_status.EN_ATTENTES === order.status ||
                              checker(order.produits) !== true
                            }
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              borderColor: "green",
                              backgroundColor: "green",
                              color: "#fff",
                            }}
                            className="btn btn-success"
                            onClick={(e) =>
                              this.updateOrderStatusDone(
                                e,
                                cmd_status.TERMINEES
                              )
                            }
                          >
                            Terminer
                          </button>
                        </td>
                        <td>
                          {cmd_status.EN_ATTENTES_LIVRAISON ===
                          order.status ? null : (
                            <button
                              disabled={
                                order.produits.reduce((acc) => acc?.status) ===
                                  false ||
                                cmd_status.EN_ATTENTES === order.status ||
                                checker(order.produits) !== true
                              }
                              style={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                borderColor: "orange",
                                backgroundColor: "orange",
                                color: "#fff",
                              }}
                              className="btn btn-warning"
                              onClick={(e) =>
                                this.updateOrderStatusDone(
                                  e,
                                  cmd_status.EN_ATTENTES_LIVRAISON
                                )
                              }
                            >
                              En attente
                            </button>
                          )}
                        </td>
                        <td>
                          <button
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              borderColor: "#ff0000",
                              backgroundColor: "#ff0000",
                              color: "#fff",
                            }}
                            className="btn btn-danger"
                            onClick={this.updateOrderStatusCancel}
                          >
                            Annuler
                          </button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="col-md-8 text-secondary table-responsive my-3">
                <LoadSpinner />
              </div>
            )}
          </div>
        }
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderById: (id) => dispatch(getOrderById(id)),
    getProducts: () => dispatch(_getAllProducts()),
  };
};

const mapStateToProps = (state) => {
  //console.log(state.products)
  return {
    item: state.commandes.item,
    products: state.products.items,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailCommande);
