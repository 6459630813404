import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  BootstrapTable,
  TableHeaderColumn,
  // ClearSearchButton,
} from "react-bootstrap-table";
// import Card from 'components/Card/Card.jsx'
import { getDisplayFormat } from "utils/dates";
import { cmd_status } from "variables/constants";
import { Tag } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import { isEmported } from "components/Navbars/timelib";

// const fontSize = 12
export default class CardOrder extends Component {
  getIterval = (cell, row) => {
    const { products } = this.props;
    const prods = products.filter(
      (f) => row.produits.findIndex((r) => r.id === f.id) !== -1
    );
    if (isEmported(prods)) {
      return <span>{getDisplayFormat(row.interval?.fin)}</span>;
    }
    return (
      <span>
        {getDisplayFormat(row.interval?.debut)} -{" "}
        {getDisplayFormat(row.interval?.fin)}{" "}
      </span>
    );
  };

  getProduit = (cell, row) => {
    const { products } = this.props;
    const prods = products.filter(
      (f) => row.produits.findIndex((r) => r.id === f.id) !== -1
    );
    const isE = isEmported(prods);
    return (
      <div>
        <Link
          to={{
            pathname: `/admin/orderDetails/${row.id}`,
          }}
        >
          produit{row.produits?.length > 0 ? "s" : ""}(
          {row.produits.reduce((acc, cur) => acc + cur.quantity, 0)})
        </Link>
        {isE && (
          <p>
            <strong className="mt-2 d-block">À emporter</strong>
          </p>
        )}
      </div>
    );
  };

  getStatus = (cell, row) => {
    console.log(row.status);
    // let checker = (arr) => arr.every((v) => v.status === true);
    if (cmd_status.EN_COURS === row?.status) {
      return <Tag color="yellow">En cours </Tag>;
    } else if (cmd_status.EN_ATTENTES === row?.status) {
      return <Tag color="red">Arrivée </Tag>;
    } else if (cmd_status.EN_ATTENTES_LIVRAISON === row?.status) {
      return <Tag color="orange">En attente</Tag>;
    } else if (cmd_status.TERMINEES === row?.status) {
      return <Tag color="green">terminée </Tag>;
    } else {
      return <Tag color="red">annulée </Tag>;
    }
  };

  getUser = (cell, row) => {
    return this.props.users?.filter((u) => u.id === row.user_id)[0]?.nom;
  };

  getPrice = (cell, row) => {
    return `${row.prix} €`;
  };

  render() {
    const options = {
      noDataText: (
        <span style={{ fontSize: "15px", fontWeight: 500 }}>
          Aucune commande trouvée pour cette date
        </span>
      ),
    };
    return (
      <BootstrapTable
        data={this.props.commandes}
        ref="table"
        options={options}
        keyField={"boutique_id"}
        pagination
        // search={true}
      >
        <TableHeaderColumn
          thStyle={{
            fontWeight: 600,
            fontSize: 15,
            color: "tomato",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
          width="150"
          dataField="interval"
          dataFormat={this.getIterval}
          dataSort
        >
          Interval
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{
            fontWeight: 600,
            fontSize: 15,
            color: "tomato",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
          width="150"
          dataField="code"
          dataSort
        >
          Code
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{
            fontWeight: 600,
            fontSize: 15,
            color: "tomato",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
          width="150"
          dataField="produit"
          dataFormat={this.getProduit}
          dataSort
        >
          Produits
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{
            fontWeight: 600,
            fontSize: 15,
            color: "tomato",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
          width="150"
          dataField="status"
          // dataFormat={(cell, row) => (
          //   <p style={{ whiteSpace: "normal" }}>{row?.description}</p>
          // )}
          dataFormat={this.getStatus}
          dataSort
        >
          Status
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{
            fontWeight: 600,
            fontSize: 15,
            color: "tomato",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
          width="150"
          dataField="user_id"
          dataFormat={this.getUser}
          dataSort
        >
          Client
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{
            fontWeight: 600,
            fontSize: 15,
            color: "tomato",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
          width="150"
          dataField="prix"
          dataFormat={this.getPrice}
          dataSort
        >
          Prix
        </TableHeaderColumn>
        {/* <TableHeaderColumn width="150" dataField="product_type" dataSort>
            Type de produit
          </TableHeaderColumn>
          <TableHeaderColumn
            width="150"
            dataFormat={(row, cell) => this.formatAction(row, cell)}
          ></TableHeaderColumn> */}
      </BootstrapTable>
    );
    /* } else {
      return (
        <Card
          title="Commandes"
          content={
        <div
          className="card"
          style={{
            width: '100%',
            height: '200px',
            margin: 'auto',
            padding: '15px',
          }}
        >
          <div
            className="card-body"
            style={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <div>
              <label
                style={{
                  fontSize: '15px',
                  fontWeight: 'normal',
                  color: 'black',
                }}
              >
                aucune commande trouvée pour cette date
              </label>
            </div>
          </div>
        </div>} />
      )
    } */
  }
}
